import React, { useState } from 'react';
import { CollapseHead } from './CollapseHead';

export function CollapseMenu(props) {
  const {
    startCollapsed, hstyle, hlabel, children,
  } = props;
  const [collapsed, setCollapsed] = useState(startCollapsed);

  return (
    <>
      <CollapseHead
        collapsed={collapsed}
        style={hstyle}
        label={hlabel}
        onClick={() => setCollapsed(!collapsed)}
      />
      {!collapsed && (children
      )}
    </>
  );
}

export default CollapseMenu;
