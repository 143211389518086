import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import VectorLayer from 'ol/layer/Vector';
import {
  Fill, Stroke, Style, Circle as CircleStyle,
} from 'ol/style';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import { createXYZ } from 'ol/tilegrid';

const dateObj = new Date(new Date().setDate(new Date().getDate() - 1));
const serviceURL = 'https://services9.arcgis.com/RHVPKKiFTONKtxq3/ArcGIS/rest/services/Satellite_VIIRS_Thermal_Hotspots_and_Fire_Activity/FeatureServer/';

const style = new Style({
  image: new CircleStyle({
    radius: 4,
    stroke: new Stroke({
      color: 'rgba(11, 9, 159, 1)',
      width: 1,
    }),
    fill: new Fill({
      color: 'rgba(90, 20, 255, 0.9)',
    }),
  }),
});

const vectorSource = new VectorSource({
  loader: async (extent) => {
    const url = `${`${`${`${serviceURL
    }0`
    }/query?`
    + 'returnGeometry=true&spatialRel=esriSpatialRelIntersects&outFields=*&geometry='}${
      encodeURIComponent(
        `{"xmin":${
          extent[0]
        },"ymin":${
          extent[1]
        },"xmax":${
          extent[2]
        },"ymax":${
          extent[3]
        },"spatialReference":{"wkid":3857}}`,
      )}`
      + '&geometryType=esriGeometryEnvelope&inSR=3857&outSR=3857&f=geojson'
      + '&where='}${
      encodeURIComponent(
        `acq_date = '${`${dateObj.getUTCFullYear()}/${(dateObj.getUTCMonth() + 1).toString().padStart(2, '0')}/${dateObj.getUTCDate().toString().padStart(2, '0')}`}' AND confidence <>'low' AND satellite = '1'`, // AND frp > '2'`,
      )}`;
    await fetch(url, {
      method: 'GET',
    }).then((response) => response.json()).then((json) => {
      // This can overload API quota, not sure how to handle this right now
      // just ignoring for now.
      const features = new GeoJSON().readFeatures(json);
      if (features.length > 0) {
        // console.log(json)
        // console.log(features.length)
        // for (const el of json.features) {
        //   if (el?.properties?.satellite != "N") {
        //     console.log(el)
        //   }
        // }
        vectorSource.addFeatures(features);
      }
    }).catch(() => {});
  },
  strategy: tileStrategy(
    createXYZ({
      tileSize: 256,
    }),
  ),
});

const layer = new VectorLayer({
  source: vectorSource,
  minZoom: 7,
  style,
});

export default layer;
