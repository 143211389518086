// /* eslint-disable */
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import themes from '../../../styles/themes';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const NavContainer = styled.div`
  width: 100%;
  // height: 51px;
  padding-top: 1rem;
  padding-bottom: .5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: ${themes.mainDark}
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  };
`;

const NavGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  height: calc(100% - 80px - 84px - 96px - 15px);
  overflow-y: auto;
  font-family: ${themes.fontFamily};
  // width: 100%;

  // display: flex;
  // justify-content: center;
  // align-items: center;

`;

const OfText = styled.span`
  font-family: ${themes.fontFamily};
  padding-left: 1rem;
  padding-right: 1rem;
  color: ${themes.white}
`;

const StyledButton = withStyles({
  root: {
    padding: '.5rem',
    // minWidth: '0px',
    color: themes.white,
    width: '28px',
    '&:hover': {
      backgroundColor: themes.hoverMainDark,
    },
  },
})(Button);

const ButtonContainer = styled.div`
  // padding-right: 1rem;
`;

const CloseContainer = styled.div`
  // padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: ${themes.mainDark}
`;

const BottomContainer = styled.div`
  background-color: ${themes.mainDark};
  box-shadow: ${themes.boxShadow};
  border-radius: 0px 0px 3px 3px;
  // display: flex;

`;

export default function PDF(props) {
  const { information, setShowModal } = props;

  const {
    startPage,
    pDFNumPages,
    startScale,
    dataURL,
  } = information;

  if (!startPage || !pDFNumPages) return null;

  const [numPages, setNumPages] = useState(pDFNumPages);
  const [pageNumber, setPageNumber] = useState(startPage);
  const [scale, setScale] = useState(startScale || 2.0);

  function onDocumentLoadSuccess() {
    setNumPages(numPages);
  }

  const handlePageChange = (dir) => {
    if (dir === 'forward') {
      if (pageNumber < numPages) {
        setPageNumber(pageNumber + 1);
      } else {
        setPageNumber(1);
      }
    } else if (dir === 'back') {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
      } else {
        setPageNumber(numPages);
      }
    }
  };

  const handleScaleChange = (v) => {
    if (v > 0) {
      setScale(v);
    }
  };

  return (
    <>
      <TextContainer>
        <Document
          file={dataURL}
          onLoadSuccess={() => onDocumentLoadSuccess()}
        >
          <Page
            scale={scale}
            pageNumber={pageNumber}
            style={{ flexDirection: 'column' }}
          />
        </Document>
      </TextContainer>
      <BottomContainer>
        <NavContainer>
          <NavGroup>
            <StyledButton onClick={() => handlePageChange('back')}>
              <ArrowBackIosIcon
                style={{ color: themes.white }}
              />
            </StyledButton>
            <OfText>
              Page
              {' '}
              {pageNumber}
              {' '}
              of
              {' '}
              {numPages}
            </OfText>
            <ButtonContainer>
              <StyledButton onClick={() => handlePageChange('forward')}>
                <ArrowForwardIosIcon
                  style={{ color: themes.white }}
                />
              </StyledButton>
            </ButtonContainer>
          </NavGroup>
          <NavGroup>
            <ButtonContainer>
              <StyledButton onClick={() => handleScaleChange(scale - 0.5)}>
                <ZoomOutIcon
                  style={{ color: themes.white }}
                />
              </StyledButton>
            </ButtonContainer>
            <ButtonContainer>
              <StyledButton onClick={() => handleScaleChange(scale + 0.5)}>
                <ZoomInIcon
                  style={{ color: themes.white }}
                />
              </StyledButton>
            </ButtonContainer>
            <ButtonContainer>
              <StyledButton>
                <a href={dataURL} download target="_blank" rel="noreferrer">
                  <CloudDownloadIcon style={{ color: themes.white }} />
                </a>
              </StyledButton>
            </ButtonContainer>
          </NavGroup>
        </NavContainer>
        <CloseContainer>
          <StyledButton
            onClick={() => setShowModal(false)}
          >
            Close
          </StyledButton>
        </CloseContainer>
      </BottomContainer>
    </>
  );
}
