const mtBPPercentiles = [
  {
    x: 7,
    y: 0,
  },
  {
    x: 8,
    y: 5.53889613365756e-05,
  },
  {
    x: 9,
    y: 9.99999974737875e-05,
  },
  {
    x: 10,
    y: 0.000121965409198,
  },
  {
    x: 11,
    y: 0.000155,
  },
  {
    x: 12,
    y: 0.000189,
  },
  {
    x: 13,
    y: 0.000218,
  },
  {
    x: 14,
    y: 0.00025,
  },
  {
    x: 15,
    y: 0.000281,
  },
  {
    x: 16,
    y: 0.00031,
  },
  {
    x: 17,
    y: 0.00034,
  },
  {
    x: 18,
    y: 0.00037,
  },
  {
    x: 19,
    y: 0.000398611096898,
  },
  {
    x: 20,
    y: 0.000427130231401,
  },
  {
    x: 21,
    y: 0.00045650076936,
  },
  {
    x: 22,
    y: 0.000485841039335,
  },
  {
    x: 23,
    y: 0.000514510029461,
  },
  {
    x: 24,
    y: 0.000543737667613,
  },
  {
    x: 25,
    y: 0.000573212426389,
  },
  {
    x: 26,
    y: 0.000602999003604,
  },
  {
    x: 27,
    y: 0.000633319024928,
  },
  {
    x: 28,
    y: 0.000664175313432,
  },
  {
    x: 29,
    y: 0.000695429975167,
  },
  {
    x: 30,
    y: 0.000727021077182,
  },
  {
    x: 31,
    y: 0.000759363604011,
  },
  {
    x: 32,
    y: 0.000792368431576,
  },
  {
    x: 33,
    y: 0.000826050178148,
  },
  {
    x: 34,
    y: 0.000860679196194,
  },
  {
    x: 35,
    y: 0.000896463345271,
  },
  {
    x: 36,
    y: 0.00093361135805,
  },
  {
    x: 37,
    y: 0.000972118636128,
  },
  {
    x: 38,
    y: 0.001011715969071,
  },
  {
    x: 39,
    y: 0.001052462612279,
  },
  {
    x: 40,
    y: 0.001094458508305,
  },
  {
    x: 41,
    y: 0.001138033578172,
  },
  {
    x: 42,
    y: 0.001183201326057,
  },
  {
    x: 43,
    y: 0.001229869916569,
  },
  {
    x: 44,
    y: 0.001278315787204,
  },
  {
    x: 45,
    y: 0.001328261685558,
  },
  {
    x: 46,
    y: 0.001379681751132,
  },
  {
    x: 47,
    y: 0.001432814169675,
  },
  {
    x: 48,
    y: 0.001487466855906,
  },
  {
    x: 49,
    y: 0.001544132479467,
  },
  {
    x: 50,
    y: 0.001603373209946,
  },
  {
    x: 51,
    y: 0.00166480964981,
  },
  {
    x: 52,
    y: 0.001728457282297,
  },
  {
    x: 53,
    y: 0.001794269657694,
  },
  {
    x: 54,
    y: 0.001862625358626,
  },
  {
    x: 55,
    y: 0.001933248585556,
  },
  {
    x: 56,
    y: 0.002006479073316,
  },
  {
    x: 57,
    y: 0.002082588616759,
  },
  {
    x: 58,
    y: 0.002162198033184,
  },
  {
    x: 59,
    y: 0.002245303010568,
  },
  {
    x: 60,
    y: 0.002331854309887,
  },
  {
    x: 61,
    y: 0.002421819372103,
  },
  {
    x: 62,
    y: 0.002514916472137,
  },
  {
    x: 63,
    y: 0.002611119998619,
  },
  {
    x: 64,
    y: 0.002710876055062,
  },
  {
    x: 65,
    y: 0.002814391395077,
  },
  {
    x: 66,
    y: 0.002921956125647,
  },
  {
    x: 67,
    y: 0.003033520188183,
  },
  {
    x: 68,
    y: 0.003148957155645,
  },
  {
    x: 69,
    y: 0.003267629537731,
  },
  {
    x: 70,
    y: 0.003389636287466,
  },
  {
    x: 71,
    y: 0.003515246692114,
  },
  {
    x: 72,
    y: 0.003643837990239,
  },
  {
    x: 73,
    y: 0.003776377765462,
  },
  {
    x: 74,
    y: 0.003914876841009,
  },
  {
    x: 75,
    y: 0.004059501457959,
  },
  {
    x: 76,
    y: 0.004210584331304,
  },
  {
    x: 77,
    y: 0.004370698239654,
  },
  {
    x: 78,
    y: 0.004540492780507,
  },
  {
    x: 79,
    y: 0.004720382392406,
  },
  {
    x: 80,
    y: 0.004910346120596,
  },
  {
    x: 81,
    y: 0.005110869649798,
  },
  {
    x: 82,
    y: 0.005323307123035,
  },
  {
    x: 83,
    y: 0.005548094864935,
  },
  {
    x: 84,
    y: 0.005786783993244,
  },
  {
    x: 85,
    y: 0.006042363122106,
  },
  {
    x: 86,
    y: 0.006319595500827,
  },
  {
    x: 87,
    y: 0.006622278597206,
  },
  {
    x: 88,
    y: 0.006955406274647,
  },
  {
    x: 89,
    y: 0.007317489013076,
  },
  {
    x: 90,
    y: 0.007719085551798,
  },
  {
    x: 91,
    y: 0.008188910782337,
  },
  {
    x: 92,
    y: 0.00873492192477,
  },
  {
    x: 93,
    y: 0.009344402700663,
  },
  {
    x: 94,
    y: 0.01003676187247,
  },
  {
    x: 95,
    y: 0.010848001670092,
  },
  {
    x: 96,
    y: 0.011846431531012,
  },
  {
    x: 97,
    y: 0.013239367920905,
  },
  {
    x: 98,
    y: 0.015543246641755,
  },
  {
    x: 99,
    y: 0.021682728081942,
  },
  {
    x: 100,
    y: 0.079172097146511,
  },
];

export default mtBPPercentiles;
