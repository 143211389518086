import WFHazAPI from '.';

/**
 * @typedef Pin
 * @type {object}
 * @property {string} id
 * @property {string} user_id
 * @property {string} project_id
 * @property {string} geom
 * @property {string} created_at
 * @property {number} x
 * @property {number} y
 */

export default class WFHazPins extends WFHazAPI {
  constructor() {
    super();
    // this.endpoint = 'annotations';
    this.endpoint = 'pins';
  }

  async get(params = {}) {
    if (params.id) {
      return super.get(
        `${this.endpoint}/${params.id}`,
      );
    }

    const query = WFHazAPI.objToQueryString(params);
    return super.get(`${this.endpoint}${query}`);
  }

  /**
   * Create a pin
   * @param {Object} params
   * @param {Number} params.project_id - currently required
   * @param {Array<Number>} params.coords - currently required: e.g.: [-12314214, 430149021]
   * @returns {Promise<Pin>}
   *
   * @example
   * const project_id = 1
   * const coords = [-13372704.509216059, 4618265.295343985]
   * const pin = await Api.Pins.post({ project_id, coords })
   * {
   *    id: "15",
   *    user_id: "7",
   *    project_id: "1",
   *    geom: "0101000020110F00007A7F4B10A48169C175EAE652069E5141",
   *    created_at: "2022-12-03T00:49:25.786Z",
   *    x: -13372704.509216059,
   *    y: 4618265.295343985
   * }
   */
  async post(params = {}) {
    return super.post(`${this.endpoint}`, params);
  }

  async delete(id) {
    return super.delete(`${this.endpoint}/${id}`);
  }
}
