import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import themes from '../../styles/themes';
import Api from '../../api';
import AppCtx from '../../context/AppContext';

const ModalBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 30em;
  height: 30em;
  transform: translate(-50%, -50%);
  background-color: ${themes.white};
  z-index: 1006;
  box-shadow: ${themes.boxShadow};
  border-radius: 10px;

`;

const DarkContainer = styled.div`
  height: 48px;
  padding: 1rem;
  display: flex;
  background-color: ${themes.mainDark};
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
`;

const HeaderContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-left: 1rem;
  justify-content: center;
  display: inline-flex;
  align-items: center;
`;

const Header = styled.span`
  font-family: ${themes.fontFamily};
  font-size: 1.5rem;
  color: ${themes.white};
  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
`;

const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${themes.white};
  position: absolute;
  z-index: 1005;
  opacity: 0.5;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
`;

const ButtonContainer = styled.div`
  padding: 1.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export default function Modal(props) {
  const { setShowLogin } = props;

  const { state, dispatch, clearUserState } = useContext(AppCtx.Context);
  const { user } = state;

  const [email, setEmail] = useState('');
  const [pw, setPw] = useState('');
  const [loginErr, setLoginErr] = useState(false);

  const login = async () => {
    try {
      const loginUser = await Api.Auth.login({ email, password: pw });
      if (loginUser.error && loginUser.status === 401) {
        setLoginErr(true);
        return;
      }

      dispatch({ type: AppCtx.ACTIONS.SET_USER, data: loginUser });
      setShowLogin(false);
      setLoginErr(false);

      // NOTE: temporary - upon logout reload the app
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const logout = async () => {
    try {
      await Api.Auth.logout();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('something went wrong signing out');
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      clearUserState();
      setShowLogin(false);

      // NOTE: temporary - upon logout reload the app
      // eslint-disable-next-line no-self-assign
      window.location.href = window.location.href;
    }
  };

  return (
    <>
      <ModalBackground />
      <ModalBox>
        <DarkContainer>
          <HeaderContainer>
            <Header>
              {user && (`${user.email}`)}
              {!user && ('Sign in')}
            </Header>
          </HeaderContainer>
        </DarkContainer>
        {!user && (
          <>
            <InputsContainer>
              <TextField onChange={(e) => setEmail(e.target.value)} id="email" label="Email" variant="outlined" type="email" />
              <TextField onChange={(e) => setPw(e.target.value)} style={{ marginTop: '1rem' }} id="pw" label="Password" variant="outlined" type="password" />
            </InputsContainer>
            <ButtonContainer>
              <Button
                style={{
                  backgroundColor: themes.secondaryDark,
                  color: themes.white,
                  fontFamily: themes.fontFamily,
                  marginRight: '1rem',
                }}
                onClick={() => login()}
              >
                Submit
              </Button>
              <Button
                style={{
                  backgroundColor: themes.secondaryDark,
                  color: themes.white,
                  fontFamily: themes.fontFamily,
                }}
                onClick={() => setShowLogin(false)}
              >
                Close
              </Button>
            </ButtonContainer>
            {loginErr && (
            <div style={{ textAlign: 'center', fontSize: 15, color: 'red' }}>
              <div>Something&apos;s not quite right.</div>
              <div>Please double check your information.</div>
            </div>
            )}
          </>
        )}
        {user && (
          <ButtonContainer>
            <Button
              style={{
                backgroundColor: themes.secondaryDark,
                color: themes.white,
                fontFamily: themes.fontFamily,
                marginRight: '1rem',
              }}
              onClick={() => logout()}
            >
              Sign out
            </Button>
            <Button
              style={{
                backgroundColor: themes.secondaryDark,
                color: themes.white,
                fontFamily: themes.fontFamily,
              }}
              onClick={() => setShowLogin(false)}
            >
              Close
            </Button>
          </ButtonContainer>

        )}
      </ModalBox>
    </>
  );
}
