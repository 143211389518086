import React from 'react';
import ReactDOM from 'react-dom';
import AppCtx from './context/AppContext';
// import { Provider } from 'react-redux';
// import store from './store';
import Base from './components/base';
// import ErrorBoundary from './components/base/ErrorBoundary';
import './styles.css';
import 'leaflet/dist/leaflet.css';
import 'ol/ol.css';
import './styles/olOverrides.css';

require('dotenv').config();

function App() {
  return (
    // <Provider store={store}>
    //   {/* <ErrorBoundary> */}
    //   <Base />
    //   {/* </ErrorBoundary> */}
    // </Provider>
    <AppCtx.Provder>
      <Base />
    </AppCtx.Provder>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
