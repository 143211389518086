import { UnknownString } from '../constants';
import { round } from './number';

export const formatBp = (val, code) => {
  if (val === false || val === undefined) return UnknownString;
  if (code !== undefined) {
    if (val >= 0 && val <= 1) {
      return `${round(val, 5)}`;
    }
  } else if (val >= 0 && val <= 1) {
    return `${round(val, 5)} (${(val * 100).toFixed(2)}%)`;
  }

  return UnknownString;
};

export default {
  formatBp,
};
