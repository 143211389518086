const caBPPercentiles = [
  // {
  //   x: 10,
  //   y: 0,
  // },
  {
    x: 11,
    y: 9.99999974737875e-06,
  },
  {
    x: 12,
    y: 9.99999974737875e-06,
  },
  {
    x: 13,
    y: 9.99999974737875e-06,
  },
  {
    x: 14,
    y: 2.27288889800548e-05,
  },
  {
    x: 15,
    y: 4.39095905676367e-05,
  },
  {
    x: 16,
    y: 6.42872182652354e-05,
  },
  {
    x: 17,
    y: 8.15662860986777e-05,
  },
  {
    x: 18,
    y: 9.24810010474175e-05,
  },
  {
    x: 19,
    y: 0.000103291669802275,
  },
  {
    x: 20,
    y: 0.000114963309897576,
  },
  {
    x: 21,
    y: 0.000129160296637565,
  },
  {
    x: 22,
    y: 0.000146696518640965,
  },
  {
    x: 23,
    y: 0.000167446011182619,
  },
  {
    x: 24,
    y: 0.000191214345395565,
  },
  {
    x: 25,
    y: 0.000218277305975789,
  },
  {
    x: 26,
    y: 0.000249565986450762,
  },
  {
    x: 27,
    y: 0.000285706744762138,
  },
  {
    x: 28,
    y: 0.000327615990536287,
  },
  {
    x: 29,
    y: 0.000375597213860601,
  },
  {
    x: 30,
    y: 0.000427950406447053,
  },
  {
    x: 31,
    y: 0.000485097407363355,
  },
  {
    x: 32,
    y: 0.000547722331248224,
  },
  {
    x: 33,
    y: 0.000615971453953534,
  },
  {
    x: 34,
    y: 0.000689084699843079,
  },
  {
    x: 35,
    y: 0.000767212535720319,
  },
  {
    x: 36,
    y: 0.000850829682312905,
  },
  {
    x: 37,
    y: 0.000938784389290959,
  },
  {
    x: 38,
    y: 0.00103180413134396,
  },
  {
    x: 39,
    y: 0.00113108369987458,
  },
  {
    x: 40,
    y: 0.00123893097043037,
  },
  {
    x: 41,
    y: 0.00135511731379665,
  },
  {
    x: 42,
    y: 0.00147919356822968,
  },
  {
    x: 43,
    y: 0.00161201926763169,
  },
  {
    x: 44,
    y: 0.00175480067264289,
  },
  {
    x: 45,
    y: 0.00190975773148239,
  },
  {
    x: 46,
    y: 0.00207648857962341,
  },
  {
    x: 47,
    y: 0.00225471123121679,
  },
  {
    x: 48,
    y: 0.00244145095348358,
  },
  {
    x: 49,
    y: 0.00263538490980864,
  },
  {
    x: 50,
    y: 0.00284231267869473,
  },
  {
    x: 51,
    y: 0.00306024076417089,
  },
  {
    x: 52,
    y: 0.00328935321420432,
  },
  {
    x: 53,
    y: 0.00353022920200601,
  },
  {
    x: 54,
    y: 0.00378066906705499,
  },
  {
    x: 55,
    y: 0.00404340401291847,
  },
  {
    x: 56,
    y: 0.00432211812585592,
  },
  {
    x: 57,
    y: 0.00461650034412742,
  },
  {
    x: 58,
    y: 0.00493433718569575,
  },
  {
    x: 59,
    y: 0.00526976394932716,
  },
  {
    x: 60,
    y: 0.00562139879912138,
  },
  {
    x: 61,
    y: 0.00598955014720559,
  },
  {
    x: 62,
    y: 0.00637878821231425,
  },
  {
    x: 63,
    y: 0.00678250420372933,
  },
  {
    x: 64,
    y: 0.00720745464786887,
  },
  {
    x: 65,
    y: 0.00766649749130011,
  },
  {
    x: 66,
    y: 0.00815481817349795,
  },
  {
    x: 67,
    y: 0.00867003761231899,
  },
  {
    x: 68,
    y: 0.009203827008605,
  },
  {
    x: 69,
    y: 0.00975599884986877,
  },
  {
    x: 70,
    y: 0.0103377190418542,
  },
  {
    x: 71,
    y: 0.0109494298603385,
  },
  {
    x: 72,
    y: 0.0115956459194422,
  },
  {
    x: 73,
    y: 0.0122892679646611,
  },
  {
    x: 74,
    y: 0.0130374161526561,
  },
  {
    x: 75,
    y: 0.013820574618876,
  },
  {
    x: 76,
    y: 0.0146256778389215,
  },
  {
    x: 77,
    y: 0.015462489798665,
  },
  {
    x: 78,
    y: 0.0163088031113148,
  },
  {
    x: 79,
    y: 0.0171690490096807,
  },
  {
    x: 80,
    y: 0.0180639065802097,
  },
  {
    x: 81,
    y: 0.0190116446465254,
  },
  {
    x: 82,
    y: 0.0200031362473965,
  },
  {
    x: 83,
    y: 0.0210446212440729,
  },
  {
    x: 84,
    y: 0.0221079792827368,
  },
  {
    x: 85,
    y: 0.0232208035886288,
  },
  {
    x: 86,
    y: 0.0243806447833776,
  },
  {
    x: 87,
    y: 0.0256440695375204,
  },
  {
    x: 88,
    y: 0.0270205996930599,
  },
  {
    x: 89,
    y: 0.0285239741206169,
  },
  {
    x: 90,
    y: 0.0302221588790417,
  },
  {
    x: 91,
    y: 0.0321110337972641,
  },
  {
    x: 92,
    y: 0.0342894643545151,
  },
  {
    x: 93,
    y: 0.0368791020289065,
  },
  {
    x: 94,
    y: 0.04006427526474,
  },
  {
    x: 95,
    y: 0.0437664203345776,
  },
  {
    x: 96,
    y: 0.0481716878712177,
  },
  {
    x: 97,
    y: 0.0538629760220646,
  },
  {
    x: 98,
    y: 0.060744624659419,
  },
  {
    x: 99,
    y: 0.07004045329988,
  },
  {
    x: 100,
    y: 0.130340874195099,
  },
];

export default caBPPercentiles;
