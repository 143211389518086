const caWHPPercentiles = [
  {
    x: 23,
    y: 1,
  },
  {
    x: 24,
    y: 2,
  },
  {
    x: 25,
    y: 3,
  },
  {
    x: 26,
    y: 4,
  },
  {
    x: 27,
    y: 5,
  },
  {
    x: 28,
    y: 6,
  },
  {
    x: 29,
    y: 8,
  },
  {
    x: 30,
    y: 10,
  },
  {
    x: 31,
    y: 12,
  },
  {
    x: 32,
    y: 15,
  },
  {
    x: 33,
    y: 19,
  },
  {
    x: 34,
    y: 22,
  },
  {
    x: 35,
    y: 26,
  },
  {
    x: 36,
    y: 31,
  },
  {
    x: 37,
    y: 36,
  },
  {
    x: 38,
    y: 41,
  },
  {
    x: 39,
    y: 47,
  },
  {
    x: 40,
    y: 53,
  },
  {
    x: 41,
    y: 59,
  },
  {
    x: 42,
    y: 66,
  },
  {
    x: 43,
    y: 72,
  },
  {
    x: 44,
    y: 79,
  },
  {
    x: 45,
    y: 87,
  },
  {
    x: 46,
    y: 95,
  },
  {
    x: 47,
    y: 103,
  },
  {
    x: 48,
    y: 111,
  },
  {
    x: 49,
    y: 119,
  },
  {
    x: 50,
    y: 128,
  },
  {
    x: 51,
    y: 138,
  },
  {
    x: 52,
    y: 148,
  },
  {
    x: 53,
    y: 159,
  },
  {
    x: 54,
    y: 170,
  },
  {
    x: 55,
    y: 180,
  },
  {
    x: 56,
    y: 191,
  },
  {
    x: 57,
    y: 202,
  },
  {
    x: 58,
    y: 212,
  },
  {
    x: 59,
    y: 224,
  },
  {
    x: 60,
    y: 236,
  },
  {
    x: 61,
    y: 249,
  },
  {
    x: 62,
    y: 262,
  },
  {
    x: 63,
    y: 277,
  },
  {
    x: 64,
    y: 293,
  },
  {
    x: 65,
    y: 310,
  },
  {
    x: 66,
    y: 328,
  },
  {
    x: 67,
    y: 346,
  },
  {
    x: 68,
    y: 366,
  },
  {
    x: 69,
    y: 388,
  },
  {
    x: 70,
    y: 414,
  },
  {
    x: 71,
    y: 441,
  },
  {
    x: 72,
    y: 471,
  },
  {
    x: 73,
    y: 505,
  },
  {
    x: 74,
    y: 544,
  },
  {
    x: 75,
    y: 591,
  },
  {
    x: 76,
    y: 645,
  },
  {
    x: 77,
    y: 710,
  },
  {
    x: 78,
    y: 785,
  },
  {
    x: 79,
    y: 878,
  },
  {
    x: 80,
    y: 995,
  },
  {
    x: 81,
    y: 1139,
  },
  {
    x: 82,
    y: 1310,
  },
  {
    x: 83,
    y: 1520,
  },
  {
    x: 84,
    y: 1774,
  },
  {
    x: 85,
    y: 2088,
  },
  {
    x: 86,
    y: 2474,
  },
  {
    x: 87,
    y: 2952,
  },
  {
    x: 88,
    y: 3546,
  },
  {
    x: 89,
    y: 4264,
  },
  {
    x: 90,
    y: 5106,
  },
  {
    x: 91,
    y: 6089,
  },
  {
    x: 92,
    y: 7241,
  },
  {
    x: 93,
    y: 8586,
  },
  {
    x: 94,
    y: 10166,
  },
  {
    x: 95,
    y: 12028,
  },
  {
    x: 96,
    y: 14265,
  },
  {
    x: 97,
    y: 17078,
  },
  {
    x: 98,
    y: 20957,
  },
  {
    x: 99,
    y: 26243,
  },
  {
    x: 100,
    y: 98158,
  },
];

export default caWHPPercentiles;
