import React from 'react';
import styled from 'styled-components';

const List = styled.div``;

const Key = styled.span`
  font-size: 10pt;
  padding: 2px;
  font-weight: 300;
  letter-spacing: 0.5px;
  flex-shrink: 0;
`;
const Value = styled.span`
  font-size: 10pt;
  padding: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`;

const Pair = styled.div`
  display: flex;
  :hover {
    background-color: #f5f6f7;
  }
`;

export function KVPair(props) {
  const { label, value } = props;
  return (
    <Pair>
      <Key>{label}</Key>
      <Value>{value}</Value>
    </Pair>
  );
}

export function KVList(props) {
  const { data, style, keyWidth, valWidth } = props;
  const kW = keyWidth || '45%';
  const vW = valWidth || '50%';

  return (
    <List style={style}>
      {data.map((d) => (
        <Pair key={`${d.key}-${d.value}`} style={d.pairStyle}>
          <Key style={{ flexBasis: kW, ...d?.keyStyle }}>{d.key}</Key>
          <Value title={d.value} style={{ flexBasis: vW, ...d?.valStyle }}>
            {d.value}
          </Value>
        </Pair>
      ))}
    </List>
  );
}

export default {
  List: KVList,
};
