import React from 'react';
import IconMap from './IconMap';

export function Icon(props) {
  const { style, icon, onClick } = props;

  const Component = IconMap[icon];

  return (
    <Component onClick={() => onClick?.()} style={style} />
  );
}

export default Icon;
