export default class WFHazAPI {
  constructor() {
    this.baseURL = WFHazAPI.defineBaseURL();
  }

  async get(str) {
    const url = `${this.baseURL}/${str}`;
    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }).then((response) => response.json())
      .catch(() => {});
    return resp;
  }

  async post(urlComponent, body = {}) {
    const url = `${this.baseURL}/${urlComponent}`;
    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }).then((response) => response.json())
      .catch(() => {});
    return resp;
  }

  async delete(urlComponent) {
    const url = `${this.baseURL}/${urlComponent}`;
    const resp = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    }).then((response) => response.json())
      .catch(() => {});
    return resp;
  }

  async put(urlComponent, body = {}) {
    const url = `${this.baseURL}/${urlComponent}`;
    const resp = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(body),
    }).then((response) => response.json())
      .catch(() => {});
    return resp;
  }

  static defineBaseURL() {
    return `${process.env.REACT_APP_WFHAZ_API}`;
  }

  static objToQueryString(obj) {
    const numKeys = Object.keys(obj).length;
    if (numKeys === 0) return '';
    let str = '?';

    Object.keys(obj).forEach((k, idx) => {
      str += `${k}=${obj[k]}`;

      if (idx + 1 < numKeys) {
        str += '&';
      }
    });

    return str;
  }
}
