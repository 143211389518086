import React from 'react';
import Button from '@material-ui/core/Button';
import Styled from 'styled-components';
import Slider from '@material-ui/core/Slider';
import OpacityIcon from '@material-ui/icons/Opacity';
import { v4 as uuidv4 } from 'uuid';
import themes from '../../../styles/themes';
import ListItem from './ListItem';
import ListItemCheck from './listItemCheck';

// Menu group for sub menu

const HeaderItem = Styled.li`
  list-style-type: none;
  width: 100%;
  &:hover {
    background-color: ${themes.hoverMainDark}
  }
  // padding-top: 6px;
  // padding-bottom: 6px;
`;

const UnorderedList = Styled.ul`
  margin-block-start: 0px;
  margin-block-end: 0px;
  padding-inline-start: 0px;
  overflow-y: auto;
  height: 82vh;
  overflow-x: hidden;
`;

const ListItemNoHover = Styled.li`
  list-style-type: none;
  width: 300px;
  padding-left: 30px;
  display: inline-block;
  // padding-top: 6px;
  // padding-bottom: 6px;
`;

const buttonStyles = {
  paddingLeft: '20px',
  paddingRight: '0px',
  maxWidth: '100%',
  minWidth: '100%',
  justifyContent: 'left',
  color: themes.white,
  fontFamily: themes.fontFamilyBold,
};

export default function List(props) {
  const {
    attributes,
    setAttributes,
    baseAttributes,
    setBaseAttributes,
    selectedPixel,
    map,
    // setBaseLayerIsShowing,
    // baseLayerIsShowing,
    setLayersAreShowing,
    layersAreShowing,
    referenceLayersShowing,
    setReferenceLayersShowing,
    referenceAttributes,
    setReferenceAttributes,
  } = props;

  if (!map) return null;

  const {
    project,
  } = map;

  const {
    // baseLayers,
    datasets,
    referenceLayers,
  } = project;

  const handleCheck = (attribute) => {
    const { iName } = attribute;
    const newAttribute = { ...attribute };

    newAttribute.selected = !newAttribute.selected;

    setReferenceAttributes({
      ...referenceAttributes,
      [iName]: newAttribute,
    });

    map.toggleVisibility(iName);
  };

  const handleGroupSlider = (_e, v, isReference = true) => {
    if (isReference) {
      referenceLayers.forEach((l) => {
        const layer = map.findLayerByIName(l.internalName);
        layer.setOpacity(v / 100);
      });
    } else {
      datasets.forEach((l) => {
        const layer = map.findLayerByIName(l.internalName);
        layer.setOpacity(v / 100);
      });
    }
  };

  const getDefaultReferenceOpacity = () => {
    if (referenceLayers.length > 0) {
      return map.findLayerByIName(referenceLayers[0].internalName).getOpacity() * 100;
    }
    return 100;
  };

  const getDefaultHazardOpacity = () => {
    if (datasets.length > 0) {
      return map.findLayerByIName(datasets[0].internalName).getOpacity() * 100;
    }
    return 100;
  };

  const handleSwitch = (base, attribute) => {
    const { iName } = attribute;
    const newAttribute = { ...attribute };
    // Change selected status of attribute
    newAttribute.selected = !newAttribute.selected;
    map.toggleVisibility(iName);
    // Update state
    if (base) {
      setBaseAttributes({
        ...baseAttributes,
        [iName]: newAttribute,
      });
    } else {
      setAttributes({
        ...attributes,
        [iName]: newAttribute,
      });
    }
  };

  const handleToolsVisible = (base, attribute) => {
    const { iName } = attribute;
    const newAttribute = { ...attribute };
    // Change selected status of attribute
    newAttribute.toolsVisible = !newAttribute.toolsVisible;
    // Hack to show layer when hazard layer is clicked
    newAttribute.selected = !newAttribute.selected;

    // Update state
    if (base) {
      const newAttributes = {};

      Object.keys(baseAttributes).forEach((a) => {
        const newAttr = { ...baseAttributes[a] };
        if (newAttr.iName === iName) {
          newAttr.toolsVisible = !newAttr.toolsVisible;
          newAttr.selected = !newAttr.selected;
          map.toggleVisibility(iName);
        } else {
          if (newAttr.selected === true) {
            map.toggleVisibility(newAttr.iName);
          }
          newAttr.toolsVisible = false;
          newAttr.selected = false;
        }
        newAttributes[newAttr.iName] = newAttr;
      });
      setBaseAttributes({
        ...newAttributes,
        [iName]: newAttribute,
      });
    } else {
      const newAttributes = {};
      Object.keys(attributes).forEach((a) => {
        const newAttr = { ...attributes[a] };
        if (newAttr.iName === iName) {
          newAttr.toolsVisible = !newAttr.toolsVisible;
          newAttr.selected = !newAttr.selected;
          map.toggleVisibility(iName);
        } else {
          if (newAttr.selected === true) {
            map.toggleVisibility(newAttr.iName);
          }
          newAttr.toolsVisible = false;
          newAttr.selected = false;
        }
        newAttributes[newAttr.iName] = newAttr;
      });

      setAttributes({
        ...newAttributes,
        [iName]: newAttribute,
      });
    }
  };

  return (
    <>
      <UnorderedList
        key="unordered_menu_list"
      >
        { (referenceLayers.length > 0) && (
          <HeaderItem>
            <Button
              style={buttonStyles}
              onClick={() => setReferenceLayersShowing(!referenceLayersShowing)}
            >
              Reference Layers
            </Button>
          </HeaderItem>
        )}
        { referenceLayersShowing && (referenceLayers.length > 0) && (
          <div style={{ paddingLeft: '9px' }}>
            <ListItemNoHover>
              <OpacityIcon style={{ color: themes.white }} />
              <Slider
                // disabled={!attribute.selected}
                key={uuidv4()}
                defaultValue={getDefaultReferenceOpacity()}
                style={{ marginLeft: '15px', width: '40%', color: themes.white }}
                onChange={(e, v) => { handleGroupSlider(e, v); }}
              />
            </ListItemNoHover>
          </div>
        )}
        {
          referenceLayersShowing && referenceLayers.map((r) => (
            <ListItemCheck
              key={`list_item_check_${r.internalName}`}
              layer={r}
              mapLayer={map.getLayers(true)[r.internalName]}
              attribute={referenceAttributes[r.internalName]}
              handleCheck={(attribute) => handleCheck(attribute)}
            />
          ))
        }
        <HeaderItem>
          <Button
            style={buttonStyles}
            onClick={() => setLayersAreShowing(!layersAreShowing)}
          >
            {project.hazardLayerHeaderName || 'Hazard Layers'}
          </Button>
        </HeaderItem>
        { layersAreShowing && (datasets.length > 0) && (
          <div style={{ paddingLeft: '9px' }}>
            <ListItemNoHover>
              <OpacityIcon style={{ color: themes.white }} />
              <Slider
                // disabled={!attribute.selected}
                key={uuidv4()}
                defaultValue={getDefaultHazardOpacity()}
                style={{ marginLeft: '15px', width: '40%', color: themes.white }}
                onChange={(e, v) => { handleGroupSlider(e, v, false); }}
              />
            </ListItemNoHover>
          </div>
        )}
        {
        layersAreShowing && datasets.map((d) => (
          (!d.onlyQueryable
            && (
            <ListItem
              key={`list_item_${d.internalName}`}
              layer={d}
              mapLayer={map.getLayers(true)[d.internalName]}
              attribute={attributes[d.internalName]}
              setAttributes={setAttributes}
              handleToolsVisible={handleToolsVisible}
              handleSwitch={handleSwitch}
              selectedPixel={selectedPixel}
            />
            )
          )
        ))
      }
        {/* <HeaderItem>
          <Button
            key="button_base_layers"
            style={buttonStyles}
            onClick={() => setBaseLayerIsShowing(!baseLayerIsShowing)}
          >
            Base Layers
          </Button>
        </HeaderItem>
        {
          baseLayerIsShowing && baseLayers.map((b) => (
            <ListItem
              key={`list_item_${b.internalName}`}
              layer={b}
              mapLayer={map.getLayers(true)[b.internalName]}
              attribute={baseAttributes[b.internalName]}
              setAttributes={setAttributes}
              handleToolsVisible={handleToolsVisible}
              handleSwitch={handleSwitch}
              selectedPixel={selectedPixel}
              isBase
            />
          ))
        } */}
      </UnorderedList>
    </>
  );
}
