import WFHazAPI from '.';

export default class WFHazAlerts extends WFHazAPI {
  constructor() {
    super();
    this.endpoint = 'alerts';
  }

  async get() {
    return super.get(`${this.endpoint}`);
  }

  async put(params = {}) {
    const { id, ...rest } = params;
    if (!id) throw new Error('Missing required param: id');

    return super.put(`${this.endpoint}/${id}`, { ...rest });
  }
}
