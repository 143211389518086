import { UnknownString } from '../constants';
import { degToSubCardinal } from './degToSubCardinal';

export const formatAsp = (val) => {
  if (val === false || val === undefined) return UnknownString;
  if (val >= -1 && val <= 359) {
    if (val === -1) {
      return 'Flat Ground';
    }
    return `${val}\u00B0 ${degToSubCardinal(val)}`;
  }

  return UnknownString;
};

export default {
  formatAsp,
};
