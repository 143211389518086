export default class Dataset {
  constructor(props) {
    this.internalName = props.internalName;
    this.percentiles = props.percentiles || [];
    this.labels = props.labels || {};
    this.selected = props.selected || false;
    this.toolsVisible = props.toolsVisible || false;
    this.name = props.name;
    this.legendType = props.legendType || false;
    this.chartScale = props.chartScale;
    this.chartPadding = props.chartPadding;
    this.yTickValues = props.yTickValues;
    this.domain = props.domain;
    this.titleFunction = props.titleFunction || ((v) => v);
    this.wmsParams = props.wmsParams || {};
    this.classBreaks = props.classBreaks || [];
    this.pixelConversion = props.pixelConversion || ((v) => v);
    this.hasHauling = props.hasHauling;
    this.hauling = props.hauling;
    this.hasAdjectiveLabel = props.hasAdjectiveLabel;
    this.isBase = props.isBase;
    this.onlyQueryable = props.onlyQueryable || false;
  }
}
