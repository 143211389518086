const line = [
  { x: 1990, y: 2200262.57617003 },
  { x: 1991, y: 2307977.39771534 },
  { x: 1992, y: 2420095.75261977 },
  { x: 1993, y: 2551648.23025029 },
  { x: 1994, y: 2694641.10540624 },
  { x: 1995, y: 2871010.48088129 },
  { x: 1996, y: 3050057.42353505 },
  { x: 1997, y: 3229104.3688474 },
  { x: 1998, y: 3408151.31254726 },
  { x: 1999, y: 3587198.25695883 },
  { x: 2000, y: 3766245.20085768 },
  { x: 2001, y: 3945292.14330772 },
  { x: 2002, y: 4124339.08753031 },
  { x: 2003, y: 4303386.03161782 },
  { x: 2004, y: 4482432.97282439 },
  { x: 2005, y: 4661479.9184232 },
  { x: 2006, y: 4840526.86322576 },
  { x: 2007, y: 5019573.80536089 },
  { x: 2008, y: 5198620.75193615 },
  { x: 2009, y: 5377667.6960003 },
  { x: 2010, y: 5556714.64355908 },
  { x: 2011, y: 5735761.57995319 },
  { x: 2012, y: 5914808.52803972 },
  { x: 2013, y: 6093855.46714965 },
  { x: 2014, y: 6272902.41577451 },
  { x: 2015, y: 6451949.35791367 },
  { x: 2016, y: 6630996.30345893 },
  { x: 2017, y: 6810043.2440562 },
  { x: 2018, y: 6989090.18778671 },
  { x: 2019, y: 7168137.13078405 },
  { x: 2020, y: 7347184.07782068 },
  { x: 2021, y: 7526231.02180186 },
  { x: 2022, y: 7705277.96358452 },
  { x: 2023, y: 7884324.90808614 },
  { x: 2024, y: 8063371.85355702 },
  { x: 2025, y: 8242418.79064534 },
  { x: 2026, y: 8421465.74465409 },
  { x: 2027, y: 8600512.68370735 },
  { x: 2028, y: 8779559.6253694 },
  { x: 2029, y: 8958606.5793937 },
  { x: 2030, y: 9137653.52576747 },
];

const points = [
  { x: 1992, y: 2199922.58 },
  { x: 1993, y: 2191587.35 },
  { x: 1994, y: 4116560.5 },
  { x: 1995, y: 2049553.62 },
  { x: 1996, y: 6005133.25 },
  { x: 1997, y: 3231570.01 },
  { x: 1998, y: 2015942.3 },
  { x: 1999, y: 6136947.4053 },
  { x: 2000, y: 7777189.644 },
  { x: 2001, y: 3836462.233 },
  { x: 2002, y: 6824164.438759 },
  { x: 2003, y: 4511462.42158 },
  { x: 2004, y: 8248571.07999 },
  { x: 2005, y: 9710849.702 },
  { x: 2006, y: 10084477.9946 },
  { x: 2007, y: 9281713.487 },
  { x: 2008, y: 5427576.8593 },
  { x: 2009, y: 6095123.6013 },
  { x: 2010, y: 3526503.16299 },
  { x: 2011, y: 9685335.7305 },
  { x: 2012, y: 9448556.394 },
  { x: 2013, y: 4494430.39901 },
  { x: 2014, y: 3675748.5832 },
  { x: 2015, y: 10222615.5641 },
  { x: 2016, y: 5343377.0161 },
  { x: 2017, y: 10238705.7996 },
  { x: 2018, y: 8243903.6847 },
];

export { line, points };
