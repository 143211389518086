import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import themes from '../../styles/themes';

const LegendContainer = styled.div`
max-width: 250px;
`;

const DataContainer = styled.div`
  font-family: ${themes.fontFamily};
  color: ${themes.white};
  min-height: 17px;
`;
const LabelContainer = styled.div`
  width: 80%;
  padding: 6px 16px;
  display: flex;
  justify-content: space-between;
  align-content: center;
`;

const Groups = styled.div`
`;

const Group = styled.div`
  width: 100%;
  display: flex;
`;

const Label = styled.div`
  padding-left: 16px;
  color: ${themes.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 13px;
  
`;

const Palette = styled.div`
  width: 25px;
  min-width: 25px;
  height: 21px;
`;

const Gradient = styled.div`
  width: 25px;
  height: 125px;
`;

export default function Legend(props) {
  const {
    mapLayer,
  } = props;
  const {
    classBreaks,
  } = mapLayer;

  const makeGradientStr = (breaks) => [...breaks].reverse().map((val) => `${val.color}`).join(', ');

  if (!classBreaks) return null;

  return (
    <LegendContainer key={uuidv4()}>
      <DataContainer key={uuidv4()}>
        <LabelContainer>
          <Groups key={uuidv4()}>
            { classBreaks && (
              classBreaks.map((label, i) => {
                if (label.type === 'gradient') {
                  return (
                  // Ternary because I don't know how to make styled components w/ psuedo classes
                    <Group key={uuidv4()} style={(i === classBreaks.length - 1) ? { paddingBottom: '0px' } : { paddingBottom: '8px' }}>
                      <Gradient style={{ background: `linear-gradient(0deg, ${makeGradientStr(label.breaks)})` }} />
                      <Label>
                        {
                          label.breaks.map((val) => (
                            <span key={uuidv4()}>
                              {val.label}
                            </span>
                          ))
                        }
                      </Label>
                    </Group>
                  );
                }
                return (
                  // Ternary because I don't know how to make styled components w/ psuedo classes
                  <Group key={uuidv4()} style={(i === classBreaks.length - 1) ? { paddingBottom: '0px', alignItems: 'center' } : { paddingBottom: '8px', alignItems: 'center' }}>
                    {
                      label.color && (
                      <Palette style={{ background: label.color }} />
                      )
                    }
                    <Label>
                      <span>
                        {label.label}
                      </span>
                    </Label>
                  </Group>
                );
              })
            )}
          </Groups>
        </LabelContainer>
      </DataContainer>
    </LegendContainer>
  );
}
