/* eslint-disable */
import React, {useContext} from 'react';
import styled from 'styled-components';
import ClearIcon from '@material-ui/icons/Clear';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Toolbar';
import LinkIcon from '@material-ui/icons/Link';
import themes from '../../../styles/themes';
import Topo from './Topo_alpha_bg.png';
import Sat from './Satellite_alpha_bg.png';
import { copyTextToClipboard } from '../helpers'
import AppCtx from '../../../context/AppContext'
// import Tooltip from '../../global/tooltip';

const Container = styled.div`
  z-index: 999;
  position: absolute;
  // width: 20%;
  // height: 51.141px;
  // background-color: pink;
  bottom: 45px;
  // right:  78px;
  // margin: 50%;
  // left: 40%;
  right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 600px) {
    bottom: 98px;
  }
  // background-color: pink;
  pointer-events: none;
`;

const ButtonContainer = styled.div`
  // pointer-events: all;
`;

const ButtonImage = styled.img`
  width: 56px;
  height: 56px;
  // padding-right: 14px;
`;

const RoundButton = withStyles({
  root: {
    pointerEvents: 'all',
    backgroundColor: themes.mainDark,
    '&:hover': {
      backgroundColor: themes.hoverMainDark,
    },
    borderRadius: '50%',
    minWidth: '0px',
    width: '36px',
    height: '36px',
    boxShadow: themes.boxShadow,
  },
})(Button);

const RoundButtonClear = withStyles({
  root: {
    pointerEvents: 'all',
    borderRadius: '50%',
    minWidth: '0px',
    width: '36px',
    height: '36px',
    boxShadow: themes.boxShadow,
  },
})(Button);


export default function ButtonBar(props) {
  const {
    clearMarkIsShowing,
    handleClearMark,
    handleGetLink,
    setShowModal,
    setBaseAttributes,
    baseAttributes,
    map
  } = props;

  const { showBanner } = useContext(AppCtx.Context);

  const handleLinkClick = () => {
    const url = `${window.origin}${window.location.pathname}${handleGetLink()}`;
    copyTextToClipboard(url, setShowModal)
    showBanner({text: 'Link to location copied!'})
  };

  const handleLayerClick = (name) => {
    if (name === 'Sat') {
      map.toggleVisibility('baseSatellite');
      map.toggleVisibility('baseFootprints');
    } else if (name === 'Topo') {
      map.toggleVisibility('baseSatellite');
      map.toggleVisibility('baseFootprints');
    }

    const newSat = { ...baseAttributes.baseSatellite };
    const newTop = { ...baseAttributes.baseFootprints };

    newSat.selected = !newSat.selected;
    newTop.selected = !newTop.selected;

    setBaseAttributes({
      baseFootprints: newTop,
      baseSatellite: newSat,
    });
  };

  return (
    <Container>
      {
        clearMarkIsShowing && (
        <ButtonContainer style={{paddingBottom: '66px'}}>
          <Tooltip title="Clear click marker">
            <RoundButton onClick={() => handleClearMark()}>
              <ClearIcon style={{ color: themes.white }} />
            </RoundButton>
          </Tooltip>
        </ButtonContainer>
        )
      }
      <ButtonContainer>
        <Tooltip title="Link to location and layers">
          <RoundButton onClick={() => handleLinkClick()}>
            <LinkIcon style={{ color: themes.white }} />
          </RoundButton>
        </Tooltip>
      </ButtonContainer>
      { !baseAttributes?.baseFootprints?.selected && (
        <ButtonContainer>
          <Tooltip title="Topographical Layer">
            <RoundButtonClear onClick={(() => handleLayerClick('Topo'))}>
              <ButtonImage src={Topo} alt="" />
            </RoundButtonClear>
          </Tooltip>
        </ButtonContainer>
      )}
      { baseAttributes?.baseFootprints?.selected && (
        <ButtonContainer>
          <Tooltip title="Satellite Layer">
            <RoundButtonClear onClick={(() => handleLayerClick('Sat'))}>
              <ButtonImage src={Sat} alt="" />
            </RoundButtonClear>
          </Tooltip>
        </ButtonContainer>
      )}
    </Container>
  );
}
