import React from 'react';
import styled from 'styled-components';
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
} from 'victory';
import { v4 as uuidv4 } from 'uuid';
import themes from '../../styles/themes';
// import ordinalSuffixOf from '../../helpers/ordinal';
import CAHauling from '../../classBreaks/CA_hauling';
// import CAHauling from '../../classBreaks';
// import API from '../../api';

const LegendContainer = styled.div`
  // right: 15px;
  // top: 80px;
  // position: absolute;
  // background-color: ${themes.secondaryDark};
  // z-index: 1000;
  max-width: 250px;
`;

export default function Legend(props) {
  const {
    selectedPixel,
    mapLayer,
  } = props;

  const {
    labels,
  } = mapLayer;

  const {
    layers,
  } = selectedPixel;

  let yIntercept = false;
  let xIntercept = false;

  // console.log(selectedPixel);

  if (layers) {
    const bP = layers.find((l) => l.iName === 'BP');
    const dP = layers.find((l) => l.iName === 'DP');
    if (bP && dP) {
      yIntercept = dP.value;
      xIntercept = bP.value;
      // xIntercept = 854 / (Math.log(bP.value) + 12.5129254649702);
    }
  }

  // console.log('yIntercept', yIntercept);
  // console.log('xIntercept', xIntercept);

  if (!mapLayer) return null;

  return (
    <LegendContainer key={uuidv4()}>
      <VictoryChart
        key={uuidv4()}
        theme={VictoryTheme.material}
        // scale={mapLayer.chartScale}
        padding={mapLayer.hauling.chartPadding}
        scale={mapLayer.hauling.scale}
        width={350}
        height={300}
        domain={mapLayer.hauling.domain}
      >
        <VictoryAxis
          label={labels.haulingLegendXLabel}
          key={uuidv4()}
          style={{
            axisLabel: {
              padding: 35,
              fill: themes.white,
              fontFamily: themes.fontFamily,
            },
            grid: {
              stroke: 'none',
            },
            tickLabels: {
              fill: themes.white,
            },
          }}
          tickValues={mapLayer.hauling.xTickValues}
        />
        <VictoryAxis
          dependentAxis
          key={uuidv4()}
          label={labels.haulingLegendYLabel}
          style={{
            axisLabel: {
              padding: 50,
              fill: themes.white,
              fontFamily: themes.fontFamily,
            },
            grid: {
              stroke: 'none',
            },
            tickLabels: {
              fill: themes.white,
            },
          }}
          tickValues={mapLayer.hauling.yTickValues}
        />
        {
          CAHauling.map((c) => {
            const index = CAHauling.indexOf(c);
            return (
              <VictoryLine
                key={uuidv4()}
                data={c}
                style={{
                  data: {
                    stroke: mapLayer.classBreaks[8 - index].color,
                  },
                }}
              />
            );
          })
        }
        { xIntercept && yIntercept && (
        <VictoryLine
          key={uuidv4()}
          domain={mapLayer.domain}
          style={{
            data: {
              strokeDasharray: [1, 2],
              stroke: themes.white,
            },
          }}
          data={[
            { x: 0, y: yIntercept },
            { x: xIntercept, y: yIntercept },
          ]}
        />
        )}
        {xIntercept && yIntercept && (
        <VictoryLine
          domain={mapLayer.domain}
          key={uuidv4()}
          data={[
            { x: xIntercept, y: 0 },
            { x: xIntercept, y: yIntercept },
          ]}
          style={{
            data: {
              strokeDasharray: [1, 2],
              stroke: themes.white,
            },
          }}
        />
        )}
        {xIntercept && yIntercept && (
        <VictoryScatter
          domain={mapLayer.domain}
          key={uuidv4()}
          data={[
            { x: xIntercept, y: yIntercept },
          ]}
          style={{ data: { fill: themes.white } }}
        />
        )}
      </VictoryChart>
    </LegendContainer>
  );
}
