const themes = {
  hoverMainDark: '#434343',
  mainDark: '#111111',
  secondaryDark: '#191919',
  mainAndAhalfDark: '#151515',
  reddish: '#974135',
  blueish: '#4CC1C9',
  bluesteelish: '#305164',
  white: '#FFFAFA',
  whiteRGBA: 'rgba(255, 250, 250, .1)',
  greenish: 'rgba(56, 133, 42, 1)',
  greenishOpactityHalf: 'rgba(56, 133, 42, .5)',
  // fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
  fontFamily: '"Lato"',
  fontFamilyBold: '"Lato-Bold"',
  fontFamilyBebas: '"BebasNeue-Regular"',
  fontSizeBebas: '2rem',
  boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
  borderRadius: '3px',
};

export default themes;
