import React, { useContext } from 'react';
import styled from 'styled-components';
import { copyTextToClipboard } from '../base/helpers';
import AppCtx from '../../context/AppContext';

const Box = styled.div`
    display: flex;
    position: relative;
    font-size: 8pt;
    align-items: center;
    border-radius: 4px;
    padding: 6px 5px;
    border: 1.5px solid #e5e5e5;
    cursor: pointer;
`;

export function CopyText(props) {
  const { text, style } = props;
  const { showBanner } = useContext(AppCtx.Context);

  const handleCopy = () => {
    copyTextToClipboard(text, () => {});
    showBanner({ text: 'Copied to clipboard!' });
  };

  return (
    <Box onClick={handleCopy} style={style}>
      {text}
    </Box>
  );
}

export default CopyText;
