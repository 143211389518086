import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import themes from '../../styles/themes';

const LegendContainer = styled.div`
// right: 15px;
// top: 80px;
// position: absolute;
// background-color: ${themes.secondaryDark};
// z-index: 1000;
max-width: 250px;
`;

const DataContainer = styled.div`
  font-family: ${themes.fontFamily};
  color: ${themes.white};
  // margin-left: 1rem;
  // margin-right: 1rem;
  // margin-top: 1rem;
  min-height: 17px;
`;

const LabelContainer = styled.div`
  width: 80%;
  height: 21px;
  padding: 4px 16px;
  display: flex;
  justify-content: left;
  align-content: center;
`;

const LabelText = styled.div`
  color: ${themes.white};
  // width: 50%;
  height: 100%;
  padding-left: 16px;
  font-size: 13px;
`;

const LabelColor = styled.div`
  width: 25px;
  height: 100%;
`;

export default function Legend(props) {
  const {
    mapLayer,
  } = props;
  const {
    classBreaks,
  } = mapLayer;

  if (!classBreaks) return null;

  return (
    <LegendContainer key={uuidv4()}>
      <DataContainer key={uuidv4()}>
        {
          classBreaks.map((label) => {
            if (!label.color) {
              return (
                <LabelContainer key={uuidv4()}>
                  <LabelText style={{ paddingLeft: '0px' }}>
                    {label.label}
                  </LabelText>
                </LabelContainer>
              );
            }
            return (
              <LabelContainer key={uuidv4()}>
                <LabelColor style={{ backgroundColor: label.color }} />
                <LabelText>
                  {label.label}
                </LabelText>
              </LabelContainer>
            );
          })
        }
      </DataContainer>
    </LegendContainer>
  );
}
