import WFHazAPI from '.';

export default class WFHazFbSums extends WFHazAPI {
  constructor() {
    super();
    this.endpoint = 'fb_sums';
  }

  async get(params = {}) {
    if (params.id === undefined || params.id === null || params.id === false) throw Error('Missing required param: id');

    return super.get(
      `${this.endpoint}/${params.id}`,
    );
  }
}
