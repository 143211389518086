export default class Reference {
  constructor(props) {
    this.internalName = props.internalName;
    this.selected = props.selected || false;
    this.toolsVisible = props.toolsVisible || false;
    this.name = props.name;
    this.wmsParams = props.wmsParams || {};
    this.nonInteractive = props.nonInteractive;
    this.externalLayer = props.externalLayer;
  }
}
