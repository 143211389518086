const toQueryString = (obj) => {
  // console.log('toQueryString obj', obj);

  // if (Object.keys(obj).length === 1) {
  //   return ``
  // }

  const combinedPairs = Object.keys(obj)
    .map((k) => `${k}=${obj[k]}`)
    .join('&');

  const encoded = encodeURIComponent(combinedPairs);

  const questionMarkAdded = `?${encoded}`;
  // console.log('string', questionMarkAdded);
  return questionMarkAdded;
  // return string;
};

export default toQueryString;
