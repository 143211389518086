import React from 'react';
import styled from 'styled-components';
import {
  VictoryChart,
  VictoryArea,
  VictoryAxis,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
} from 'victory';
import { v4 as uuidv4 } from 'uuid';
import themes from '../../styles/themes';
import ordinalSuffixOf from '../../helpers/ordinal';

const LegendContainer = styled.div`
  // right: 15px;
  // top: 80px;
  // position: absolute;
  // background-color: ${themes.secondaryDark};
  // z-index: 1000;
  max-width: 250px;
`;

const DataContainer = styled.div`
  font-family: ${themes.fontFamily};
  color: ${themes.white};
  // margin-left: 1rem;
  // margin-right: 1rem;
  // margin-top: 1rem;
  min-height: 17px;
`;

const DataPoint = styled.div`
  display: inline-block;
  // width: 50%;
`;

export default function Legend(props) {
  const {
    selectedPixel,
    mapLayer,
  } = props;

  const {
    percentiles,
    labels,
  } = mapLayer;

  let yIntercept = false;
  let xIntercept = false;

  // const [xIntercept, setXIntercept] = useState(false);
  // const [yIntercept, setYIntercept] = useState(false);

  const getXIntercept = (value) => {
    // Find index of percentile larger than selectedPixel

    const oneLarger = percentiles.reduce((curr, prev) => {
      const currDistance = Math.abs(curr.y - value);
      const prevDistance = Math.abs(prev.y - value);
      if (curr.y > value && currDistance < prevDistance) {
        return curr;
      }
      return prev;
    });

    const index = percentiles.indexOf(oneLarger);
    // if we can't get a smaller item, simply return oneLarger.x
    // note, if index is 0, oneSmaller would be undefined...
    if (index === 0) return oneLarger.x;

    const oneSmaller = percentiles[index - 1];

    // The difference between oneLarger and oneSmaller
    const diff = Math.abs(oneLarger.y - oneSmaller.y);

    if (diff === 0) {
      return oneLarger.x;
    }

    const pixelDiff = Math.abs(value - oneSmaller.y);
    const fraction = pixelDiff / diff;
    const x = oneSmaller.x + fraction;

    return x;
  };

  const getAdjectiveLabel = () => {
    const layer = selectedPixel.layers.find((l) => l.iName === mapLayer.internalName);

    const { value } = layer;

    const biggerOnes = mapLayer.classBreaks.filter((c) => c.y > value);
    const theOne = biggerOnes.reduce((prev, curr) => {
      const prevDistance = Math.abs(prev.y - value);
      const currDistance = Math.abs(curr.y - value);

      if (currDistance < prevDistance) return curr;
      return prev;
    });

    return theOne.adjective;
  };

  if (selectedPixel) {
    try {
      const layer = selectedPixel.layers.find((l) => l.iName === mapLayer.internalName);

      if (layer && layer.value > 0) {
        yIntercept = mapLayer.pixelConversion(layer.value);
        xIntercept = getXIntercept(yIntercept);
        // } else if (layer && layer.value > -10000 && layer.value < 0) {
        //   yIntercept = mapLayer.pixelConversion(layer.value * -1);
        //   xIntercept = getXIntercept(yIntercept);
      }
    } catch (error) {
      // NOTE: If this block throws an error, it will cause the app to crash
      // so we are just catching any error that may arise and logging.
      // NOTE: this may cause issues with the chart that is displayed but
      // at least the app won't crash.
      // Related issue: https://github.com/pyrologix/fire-viz/issues/38
      console.error(error);
    }
  }

  if (!mapLayer) return null;

  return (
    <LegendContainer
      key={uuidv4()}
    >
      <DataContainer
        key={uuidv4()}
      >
        {
          mapLayer.hasAdjectiveLabel && (
            <DataPoint
              key={uuidv4()}
            >
              {xIntercept ? `${getAdjectiveLabel()} (${ordinalSuffixOf(Math.round(xIntercept))} percentile)` : ''}
            </DataPoint>
          )
        }
        {
          !mapLayer.hasAdjectiveLabel && (
            <DataPoint
              key={uuidv4()}
            >
              {
              labels.graphTitle && (
                `${xIntercept ? `${labels.graphTitle}: ${mapLayer.titleFunction(yIntercept)} (${ordinalSuffixOf(Math.round(xIntercept))} percentile)` : ''}`
              )
            }
              {
            !labels.graphTitle && (
              xIntercept ? `${labels.legendXLabel}: ${ordinalSuffixOf(Math.round(xIntercept))}` : ''
            )
          }
            </DataPoint>
          )
        }
      </DataContainer>
      <VictoryChart
        key={uuidv4()}
        theme={VictoryTheme.material}
        scale={mapLayer.chartScale}
        padding={mapLayer.chartPadding}
        width={350}
        height={300}
      >
        <VictoryAxis
          label={labels.legendXLabel}
          key={uuidv4()}
          style={{
            axisLabel: {
              padding: 30,
              fill: themes.white,
              fontFamily: themes.fontFamily,
            },
            grid: {
              stroke: 'none',
            },
            tickLabels: {
              fill: themes.white,
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          key={uuidv4()}
          label={labels.legendYLabel}
          style={{
            axisLabel: {
              padding: 45,
              fill: themes.white,
              fontFamily: themes.fontFamily,
            },
            grid: {
              stroke: 'none',
            },
            tickLabels: {
              fill: themes.white,
            },
          }}
          tickValues={mapLayer.yTickValues}
        />
        {
          mapLayer.classBreaks.map((p) => (
            <VictoryArea
              key={uuidv4()}
              domain={mapLayer.domain}
              // domain={{ y: [percentiles[0].y, percentiles[percentiles.length - 1].y] }}
              style={{ data: { fill: p.color } }}
              data={[{ y: p.y, x: 0 }, { y: p.y, x: p.x }]}
            />
          ))
        }
        {mapLayer && (
        <VictoryLine
          key={uuidv4()}
          domain={mapLayer.domain}
          // domain={{ y: [percentiles[0].y, percentiles[percentiles.length - 1].y] }}
          style={{ data: { stroke: themes.mainDark } }}
          data={percentiles.map((p) => {
            const x = p.x === 0 ? 0 : p.x;
            const { y } = p;
            return {
              x,
              y,
            };
          })}
        />
        )}
        { xIntercept && yIntercept && (
        <VictoryLine
          key={uuidv4()}
          domain={mapLayer.domain}
          // domain={{ y: [percentiles[0].y, percentiles[percentiles.length - 1].y] }}
          style={{
            data: {
              strokeDasharray: [1, 2],
              stroke: themes.mainDark,
            },
          }}
          data={[
            { x: 0, y: yIntercept },
            { x: xIntercept, y: yIntercept },
          ]}
        />
        )}
        {xIntercept && yIntercept && (
        <VictoryLine
          domain={mapLayer.domain}
          // domain={{ y: [percentiles[0].y, percentiles[percentiles.length - 1].y] }}
          key={uuidv4()}
          data={[
            { x: xIntercept, y: 0 },
            { x: xIntercept, y: yIntercept },
          ]}
          style={{
            data: {
              strokeDasharray: [1, 2],
              stroke: themes.mainDark,
            },
          }}
        />
        )}
        {xIntercept && yIntercept && (
        <VictoryScatter
          domain={mapLayer.domain}
          // domain={{ y: [percentiles[0].y, percentiles[percentiles.length - 1].y] }}
          key={uuidv4()}
          data={[
            { x: xIntercept, y: yIntercept },
          ]}
          style={{ data: { fill: themes.mainDark } }}
        />
        )}
      </VictoryChart>
    </LegendContainer>
  );
}
