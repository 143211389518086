import WFHazAPI from '.';

export default class WFHazUsers extends WFHazAPI {
  constructor() {
    super();
    this.endpoint = 'users';
  }

  get(params = {}) {
    if (params.id) {
      return super.get(
        `${this.endpoint}/${params.id}`,
      );
    }
    return super.get(`${this.endpoint}`);
  }
}
