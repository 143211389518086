const twoDigitEvLut = {
  11: 'Open Water',
  12: 'Snow/Ice',
  13: 'Developed-Upland Deciduous Forest',
  14: 'Developed-Upland Evergreen Forest',
  15: 'Developed-Upland Mixed Forest',
  16: 'Developed-Upland Herbaceous',
  17: 'Developed-Upland Shrubland',
  22: 'Developed-Low Intensity',
  23: 'Developed-Medium Intensity',
  24: 'Developed-High Intensity',
  25: 'Developed-Roads',
  31: 'Barren',
  32: 'Quarries-Strip Mines-Gravel Pits-Well and Wind Pads',
  61: 'NASS-Vineyard',
  63: 'NASS-Row Crop-Close Grown Crop',
  64: 'NASS-Row Crop',
  65: 'NASS-Close Grown Crop',
  68: 'NASS-Wheat',
  69: 'NASS-Aquaculture',
  82: 'Cultivated Crops',
};

export default twoDigitEvLut;
