import { UnknownString } from '../constants';

export const formatBps = (val, code) => {
  if (val === false || val === undefined) return UnknownString;
  if (val && code >= 0 && code <= 100000) {
    return `${code} - ${val}`;
  }

  return UnknownString;
};

export default {
  formatBps,
};
