/* eslint-disable */

import React from 'react';
import Styled from 'styled-components';
import {
  VictoryChart,
  // VictoryBar,
  VictoryTheme,
  VictoryAxis,
  VictoryLine,
  VictoryScatter,
  // VictoryLabel,
} from 'victory';
import { line, points } from './ScatterData';
import themes from '../../../styles/themes';

const ChartContainer = Styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
`;

const Title = Styled.span`
  color: ${themes.white};
`;

export default function Chart(props) {
  const { year } = props;

  // console.log('year', year % 1990);

  const getLine = () => {
    const index = year % 1990;
    const data = line.slice(0, index + 1);
    return data;
  };

  const getScatter = () => {
    if (1992 <= year && year <= 2018) {
      const index = year % 1992
      const data = points.slice(0, index + 1)
      return data;
    } else if (year > 2018) {
      return points;
    }
    return []
  }

  return (
    <ChartContainer>
      {/* <Title>
        Title
      </Title> */}
      <VictoryChart
        width={500}
        height={300}
        domainPadding={10}
        padding={{ left: 70, bottom: 40, right: 10 }}
        theme={VictoryTheme.material}
      >
        <VictoryLine
          data={getLine()}
          style={{
            data: {
              stroke: '#b62e2d'
            }
          }}
        />
        <VictoryScatter
          data={getScatter()}
          style={{
            data: {
              fill: '#405497'
            }
          }}
        />
        <VictoryAxis
          dependentAxis
          style={{
            grid: {
              stroke: 'none',
            },
            tickLabels: {
              fill: themes.white,
            },
            ticks: {
              size: 3,
            },
            axis: {
              strokeWidth: '.5px',
            },
            axisLabel: {
              fill: themes.white,
              fontFamily: themes.fontFamily,
              padding: 40,
            }
          }}
          domain={[0, 12000000]}
          label="MM Acres"
          tickFormat={(t) => t / 1000000}
        />
        <VictoryAxis
          style={{
            grid: {
              stroke: 'none',
            },
            tickLabels: {
              fill: themes.white,
            },
            ticks: {
              size: 3,
            },
            axis: {
              strokeWidth: '.5px',
            },
            axisLabel: {
              fill: themes.white,
              fontFamily: themes.fontFamily,
              padding: 30,
            }
          }}
          label="Year"
          domain={[1990, 2030]}
          minDomain={{ x: 1990 }}
          // tickValues={[1.5, 4.5, 7.5, 10.5]}
          tickFormat={(t) => (t % 5 === 0 ? t : '')}
        />
      </VictoryChart>
    </ChartContainer>
  );
}
