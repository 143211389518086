import { UnknownString } from '../constants';

export const round = (num, dec = 2, fallback = null) => {
  try {
    const flt = parseFloat(num);
    if (Number.isNaN(flt)) return fallback;
    return flt.toFixed(dec);
  } catch (error) {
    return fallback;
  }
};

export const numeric = (val) => {
  const asStr = String(val);
  return !Number.isNaN(asStr) && !Number.isNaN(parseFloat(asStr));
};

export const outOfWithLocale = (lhs, rhs) => {
  if ((lhs >= 0) && (rhs >= 0)) {
    return `${parseInt(lhs, 10).toLocaleString()} out of ${parseInt(rhs, 10).toLocaleString()}`;
  }

  return UnknownString;
};

export const formatPercent = (num, denom) => {
  if (num === 0) {
    return '0%';
  }

  if (num > 0 && denom > 0) {
    return `${round((num / denom) * 100)}%`;
  }

  return UnknownString;
};

/**
  * Ensures that value is between lb and rb (inclusive) else returns UnknownString
  *
  * Primarily for use with values that could have NoData or values that have known ranges
  * @returns string
*/
export const filterValue = (lb, rb, val, callback = false, dec = 2) => {
  if (val === false || val === undefined || val === null || val === 'null') {
    return UnknownString;
  }
  if (val >= lb && val <= rb) {
    if (callback) {
      return `${round(val, dec)}`;
    }

    return `${val}`;
  }

  return UnknownString;
};

export default {
  round,
  numeric,
};
