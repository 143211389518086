import { UnknownString } from '../constants';
import twoDigitEvLut from './twoDigitEvLut';

export const decodeEvcCode = (code) => {
  if (code === false || code === undefined) return UnknownString;
  if (twoDigitEvLut[code]) {
    return twoDigitEvLut[code];
  }

  if (code >= 110 && code <= 195) {
    return `Tree Cover = ${code - 100}%`;
  }

  if (code >= 210 && code <= 279) {
    return `Shrub Cover = ${code - 200}%`;
  }

  if (code >= 310 && code <= 399) {
    return `Herb Cover = ${code - 300}%`;
  }

  return 'Unknown';
};

export default {
  decodeEvcCode,
};
