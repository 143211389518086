import React, { useEffect } from 'react';
import Styled from 'styled-components';
import Select from 'react-select';
import { useHistory } from 'react-router-dom';
import themes from '../../styles/themes';
import ImageSeries from './imageSeries';

const MiddleAlign = Styled.div`
  padding-top: 6.5rem;
  display: block;
  height: calc(100vh - 6.5rem)
  // overflow: auto;
`;

const SideBuffer = Styled.div`
  width: 65%;
  background-color: ${themes.secondaryDark};
  margin: auto;
  @media (max-width: 768px) {
    width: 85%;
  };
  padding-left: 2rem;
  padding-right: 2rem;
`;

const Title = Styled.span`
  font-family: ${themes.fontFamily};
  color: ${themes.white};
  padding-right: 1rem;
  padding-top: 3px;
`;

const StateSelector = Styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5rem;
`;

const customButtonStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? themes.hoverMainDark : themes.secondaryDark,
    borderColor: themes.white,
    cursor: 'pointer',
    boxShadow: 'unset',
    '&:hover': {
      borderColor: themes.white,
      backgroundColor: themes.hoverMainDark,
    },
  }),
  placeholder: () => ({
    color: themes.white,
    fontFamily: themes.fontFamily,
  }),
  input: () => ({
    color: themes.white,
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: themes.white,
    '&:hover': {
      color: themes.white,
    },
    '&:focus': {
      color: themes.white,
    },
  }),
  menu: (provided) => ({
    ...provided,
    cursor: 'pointer',
    '& *': {
      cursor: 'pointer',
    },
  }),
  menuList: () => ({
    fontFamily: themes.fontFamily,
  }),
};

export default function Home(props) {
  const { states, setSelectedState, setShowModal } = props;

  const history = useHistory();

  const options = [
    // {
    //   label: 'Flagship',
    //   options: [
    //     { value: 'pv', label: 'Placerville', isDisabled: true },
    //   ],
    // },
    {
      label: 'States',
      options: [
        { value: 'ca', label: 'California' },
        { value: 'mt', label: 'Montana' },
        { value: 'co', label: 'Colorado' },
        // { value: 'ut', label: 'Utah', isDisabled: true },
      ],
    },
    {
      label: 'Regions',
      options: [
        { value: 'anc', label: 'Anchorage', isDisabled: true },
        { value: 'calfire', label: 'CalFire' },
      ],
    },
    // {
    //   label: 'Counties',
    //   options: [
    //     { value: 'msla', label: 'Missoula', isDisabled: true },
    //   ],
    // },
  ];

  const handleChange = (e) => {
    const abbreviation = e?.value;

    const obj = states.find((s) => s.abbreviation === abbreviation);

    if (obj) {
      setSelectedState(obj);
      history.push(`/${abbreviation}`);
    }
  };

  useEffect(() => {
    // User hit 'back'
    if (history.action === 'POP') {
      setSelectedState(false);
    }
  });

  return (
    <SideBuffer>
      <MiddleAlign>
        <StateSelector>
          <Title>EXPLORE A PROJECT:</Title>
          <div style={{ width: '150px' }}>
            <Select styles={customButtonStyles} options={options} onChange={(e) => handleChange(e)} isSearchable />
          </div>
        </StateSelector>
        <ImageSeries setShowModal={setShowModal} />
      </MiddleAlign>
    </SideBuffer>
  );
}
