const caSESPercentiles = [
  {
    x: 10,
    y: 0,
  },
  {
    x: 11,
    y: 14.204722776413,
  },
  {
    x: 12,
    y: 18.2962837219238,
  },
  {
    x: 13,
    y: 23.3895099067688,
  },
  {
    x: 14,
    y: 30.4515857696533,
  },
  {
    x: 15,
    y: 38.4272727966309,
  },
  {
    x: 16,
    y: 43.6560173034668,
  },
  {
    x: 17,
    y: 47.8127072525025,
  },
  {
    x: 18,
    y: 51.7644036102295,
  },
  {
    x: 19,
    y: 54.9617347717285,
  },
  {
    x: 20,
    y: 57.5872611999512,
  },
  {
    x: 21,
    y: 60.0359306335449,
  },
  {
    x: 22,
    y: 62.6531753540039,
  },
  {
    x: 23,
    y: 65.6299438476562,
  },
  {
    x: 24,
    y: 68.9468002319336,
  },
  {
    x: 25,
    y: 72.5228118896484,
  },
  {
    x: 26,
    y: 76.3106536865234,
  },
  {
    x: 27,
    y: 80.1913452148438,
  },
  {
    x: 28,
    y: 84.0747985839844,
  },
  {
    x: 29,
    y: 87.9462738037109,
  },
  {
    x: 30,
    y: 91.7640991210938,
  },
  {
    x: 31,
    y: 95.5419845581055,
  },
  {
    x: 32,
    y: 99.3498434448243,
  },
  {
    x: 33,
    y: 103.304412841797,
  },
  {
    x: 34,
    y: 107.446754455566,
  },
  {
    x: 35,
    y: 111.784484863281,
  },
  {
    x: 36,
    y: 116.318176269531,
  },
  {
    x: 37,
    y: 120.946235656738,
  },
  {
    x: 38,
    y: 125.598281860352,
  },
  {
    x: 39,
    y: 130.248062133789,
  },
  {
    x: 40,
    y: 134.949600219727,
  },
  {
    x: 41,
    y: 139.762237548828,
  },
  {
    x: 42,
    y: 144.654647827148,
  },
  {
    x: 43,
    y: 149.544219970703,
  },
  {
    x: 44,
    y: 154.417922973633,
  },
  {
    x: 45,
    y: 159.276596069336,
  },
  {
    x: 46,
    y: 164.117523193359,
  },
  {
    x: 47,
    y: 168.898452758789,
  },
  {
    x: 48,
    y: 173.596588134766,
  },
  {
    x: 49,
    y: 178.229019165039,
  },
  {
    x: 50,
    y: 182.749542236328,
  },
  {
    x: 51,
    y: 187.165390014648,
  },
  {
    x: 52,
    y: 191.429092407227,
  },
  {
    x: 53,
    y: 195.535308837891,
  },
  {
    x: 54,
    y: 199.531707763672,
  },
  {
    x: 55,
    y: 203.439071655273,
  },
  {
    x: 56,
    y: 207.291525878906,
  },
  {
    x: 57,
    y: 211.109100341797,
  },
  {
    x: 58,
    y: 214.917495727539,
  },
  {
    x: 59,
    y: 218.683349609375,
  },
  {
    x: 60,
    y: 222.39469909668,
  },
  {
    x: 61,
    y: 226.100540161133,
  },
  {
    x: 62,
    y: 229.878112792969,
  },
  {
    x: 63,
    y: 233.771194458008,
  },
  {
    x: 64,
    y: 237.757186889648,
  },
  {
    x: 65,
    y: 241.853485107422,
  },
  {
    x: 66,
    y: 246.086822509766,
  },
  {
    x: 67,
    y: 250.547622680664,
  },
  {
    x: 68,
    y: 255.227416992188,
  },
  {
    x: 69,
    y: 260.162933349609,
  },
  {
    x: 70,
    y: 265.355163574219,
  },
  {
    x: 71,
    y: 270.805114746094,
  },
  {
    x: 72,
    y: 276.572021484375,
  },
  {
    x: 73,
    y: 282.608703613281,
  },
  {
    x: 74,
    y: 289.035072631836,
  },
  {
    x: 75,
    y: 296.014892578125,
  },
  {
    x: 76,
    y: 303.631103515625,
  },
  {
    x: 77,
    y: 311.846740722656,
  },
  {
    x: 78,
    y: 320.608062744141,
  },
  {
    x: 79,
    y: 329.915710449219,
  },
  {
    x: 80,
    y: 339.870452880859,
  },
  {
    x: 81,
    y: 350.490661621094,
  },
  {
    x: 82,
    y: 361.874725341797,
  },
  {
    x: 83,
    y: 374.026916503906,
  },
  {
    x: 84,
    y: 387.106170654297,
  },
  {
    x: 85,
    y: 401.263153076172,
  },
  {
    x: 86,
    y: 416.408935546875,
  },
  {
    x: 87,
    y: 432.502716064453,
  },
  {
    x: 88,
    y: 449.621002197266,
  },
  {
    x: 89,
    y: 468.022369384766,
  },
  {
    x: 90,
    y: 487.700164794922,
  },
  {
    x: 91,
    y: 508.774841308594,
  },
  {
    x: 92,
    y: 531.37158203125,
  },
  {
    x: 93,
    y: 555.344543457031,
  },
  {
    x: 94,
    y: 580.958862304688,
  },
  {
    x: 95,
    y: 608.632995605469,
  },
  {
    x: 96,
    y: 639.419677734375,
  },
  {
    x: 97,
    y: 674.770935058594,
  },
  {
    x: 98,
    y: 717.896362304688,
  },
  {
    x: 99,
    y: 774.497924804688,
  },
  {
    x: 100,
    y: 981.430236816406,
  },
];

export default caSESPercentiles;
