export default class GeoserverAPI {
  constructor() {
    this.baseURL = GeoserverAPI.defineBaseURL();
    this.restURL = `${this.baseURL}geoserver/rest/`;
  }

  // Could build out classes for each type of fetch that extend GeoserverAPI
  async getLayers() {
    const url = `${this.restURL}workspaces/pyrologix/layers`;
    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
      .catch(() => {});
    return resp;
  }

  static defineBaseURL() {
    return !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
      ? 'http://192.168.0.151:8001/'
      : 'http://web-map-load-balancer-http-1899608382.us-east-2.elb.amazonaws.com/';
  }
}
