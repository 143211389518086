import { UnknownString } from '../constants';

export const fm40CodeMap = {
  91: { label: 'NB1', color: '#686868' },
  92: { label: 'NB2', color: '#E1E1E1' },
  93: { label: 'NB3', color: '#ffeded' },
  98: { label: 'NB8', color: '#000ED6' },
  99: { label: 'NB9', color: '#4d6e70' },
  101: { label: 'GR1', color: '#ffebbe' },
  102: { label: 'GR2', color: '#ffd373' },
  103: { label: 'GR3', color: '#ffec8b' },
  104: { label: 'GR4', color: '#ffff73' },
  105: { label: 'GR5', color: '#f5de29' },
  106: { label: 'GR6', color: '#e6e640' },
  107: { label: 'GR7', color: '#CDC673' },
  108: { label: 'GR8', color: '#8b864e' },
  109: { label: 'GR9', color: '#A87000' },
  121: { label: 'GS1', color: '#FFAA00' },
  122: { label: 'GS2', color: '#ffa77f' },
  123: { label: 'GS3', color: '#ff6300' },
  124: { label: 'GS4', color: '#CD6600' },
  141: { label: 'SH1', color: '#d7c29e' },
  142: { label: 'SH2', color: '#d7b09e' },
  143: { label: 'SH3', color: '#cd8966' },
  144: { label: 'SH4', color: '#895a44' },
  145: { label: 'SH5', color: '#cdaa66' },
  146: { label: 'SH6', color: '#ed7044' },
  147: { label: 'SH7', color: '#cd7d39' },
  148: { label: 'SH8', color: '#a83800' },
  149: { label: 'SH9', color: '#731a00' },
  161: { label: 'TU1', color: '#e9ffbe' },
  162: { label: 'TU2', color: '#aaff00' },
  163: { label: 'TU3', color: '#b4d79e' },
  164: { label: 'TU4', color: '#70A800' },
  165: { label: 'TU5', color: '#267300' },
  181: { label: 'TL1', color: '#beffe8' },
  182: { label: 'TL2', color: '#00ffc5' },
  183: { label: 'TL3', color: '#bed2ff' },
  184: { label: 'TL4', color: '#7b68ee' },
  185: { label: 'TL5', color: '#bee8ff' },
  186: { label: 'TL6', color: '#00c5ff' },
  187: { label: 'TL7', color: '#0084A8' },
  188: { label: 'TL8', color: '#005ce6' },
  189: { label: 'TL9', color: '#4d6e91' },
  201: { label: 'SB1', color: '#E8BEFF' },
  202: { label: 'SB2', color: '#C500FF' },
  203: { label: 'SB3', color: '#FFBEE8' },
  204: { label: 'SB4', color: '#FF7F7F' },
};

export const decodeFm40 = (code) => {
  if (code === false || code === undefined) return UnknownString;
  const label = fm40CodeMap[code]?.label;
  if (label) return `${label} (${code})`;
  return 'Unknown';
};

export default {
  decodeFm40,
  fm40CodeMap,
};
