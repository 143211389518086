/* eslint-disable max-len */
import Project from './Project';
import Dataset from './Dataset';
import Reference from './Reference';
import viirs48Feature from './layers/viirs48Feature';
import viirs24Feature from './layers/viirs24Feature';
import firePerimetersCurrent from './layers/FirePerimetersCurrent';
import caWHPPercentiles from '../classBreaks/CAL_WHP';
import caSDIPercentiles from '../classBreaks/CAL_SDI';
import caBPPercentiles from '../classBreaks/CAL_BP';
import caSESPercentiles from '../classBreaks/CAL_SES';
import caDPPercentiles from '../classBreaks/CAL_DP';
import mtBPPercentiles from '../classBreaks/MT_BP';
import mtRPSPercentiles from '../classBreaks/MT_RPS';
import COAL2020BPPercentiles from '../classBreaks/COAL_20_BP';
import COAL2021BPPercentiles from '../classBreaks/COAL_21_BP';
import { fm40CodeMap } from '../helpers/fm40';

// const dateObj = new Date();

const ca = new Project({
  abbreviation: 'ca',
  title: 'CALIFORNIA',
  center: [-13507310.973915648, 4536028.540300894],
  zoom: 5,
  referenceLayers: [
    new Reference({
      internalName: 'caBuildingCover',
      selected: false,
      toolsVisible: false,
      name: 'Building Cover',
      wmsParams: {
        LAYERS: 'BuildingCover_CA_3857_optimized',
      },
    }),
    new Reference({
      internalName: 'caHUDen',
      selected: false,
      toolsVisible: false,
      name: 'Housing Unit Density',
      wmsParams: {
        LAYERS: 'HUDen_CA_3857_optimized',
      },
    }),
    new Reference({
      internalName: 'caFP2010To2014',
      selected: false,
      toolsVisible: false,
      name: '2010-2014 Fire Perimeters',
      wmsParams: {
        CQL_FILTER: 'YEAR_ BETWEEN 2010 AND 2014',
        LAYERS: 'CAL_2020_Fire_Perimeters',
        STYLES: 'Fire_Perimeter_Light_Charcoal',
      },
    }),
    new Reference({
      internalName: 'caFP2015To2019',
      selected: false,
      toolsVisible: false,
      name: '2015-2019 Fire Perimeters',
      wmsParams: {
        CQL_FILTER: 'YEAR_ BETWEEN 2015 AND 2019',
        LAYERS: 'CAL_2020_Fire_Perimeters',
        STYLES: 'Fire_Perimeter_Med_Charcoal',
      },
    }),
    new Reference({
      internalName: 'caFP2019',
      selected: false,
      toolsVisible: false,
      name: '2020 Fire Perimeters',
      wmsParams: {
        CQL_FILTER: 'YEAR_ = 2020',
        LAYERS: 'CAL_2020_Fire_Perimeters',
        STYLES: 'Fire_Perimeter_Dark_Charcoal',
      },
    }),
    new Reference({
      internalName: 'VIIRS24',
      selected: false,
      toolsVisible: false,
      name: '24-Hour VIIRS',
      externalLayer: viirs24Feature,
      nonInteractive: true,
    }),
    new Reference({
      internalName: 'VIIRS48',
      selected: false,
      toolsVisible: false,
      name: '48-Hour VIIRS',
      externalLayer: viirs48Feature,
      nonInteractive: true,
    }),
    new Reference({
      internalName: 'curFirePerimeters',
      selected: false,
      toolsVisible: false,
      name: 'Active Fire Perimeters',
      nonInteractive: true,
      externalLayer: firePerimetersCurrent,
    }),
  ],
  baseLayers: [
    // new Dataset({
    //   internalName: 'oSM',
    //   name: 'Open Street Map',
    //   selected: false,
    //   toolsVisible: false,
    //   isBase: true,
    // }),
    new Dataset({
      internalName: 'baseFootprints',
      name: 'Topo',
      selected: true,
      toolsVisible: false,
      isBase: true,
    }),
    new Dataset({
      internalName: 'baseSatellite',
      name: 'Satellite',
      selected: false,
      toolsVisible: false,
      isBase: true,
    }),
  ],
  // Order matters here, or at least it does until we define explict ordering
  // and/or allow re-ordering of layers
  datasets: [
    new Dataset({
      internalName: 'BP',
      name: 'Burn Probability',
      percentiles: caBPPercentiles,
      labels: {
        legendXLabel: 'Percentile Risk',
        legendYLabel: 'Annual BP',
        graphTitle: 'Annual BP',
      },
      legendType: 'BP',
      chartScale: { y: 'log' },
      chartPadding: {
        left: 60,
        right: 20,
        top: 20,
        bottom: 40,
      },
      yTickValues: [0.0001, 0.001, 0.01, 0.1],
      domain: { y: [0.00000999999974737875, 0.2154] },
      selected: true,
      toolsVisible: true,
      wmsParams: {
        LAYERS: 'CAL_BP_NoMexico_3857_optimized',
      },
      titleFunction: (v) => v.toFixed(5),
      classBreaks: [
        // Manually changed to make top class the same "width" as other classes
        { y: 0.2154, x: 100, color: '#800026' },
        { y: 0.1, x: 99.4968381025429, color: '#bd0026' },
        { y: 0.046416, x: 95.6014318039741, color: '#e31a1c' },
        { y: 0.021544, x: 83.469950513445, color: '#fc4e2a' },
        { y: 0.01, x: 69.4194476201668, color: '#fd8d3c' },
        { y: 0.004642, x: 57.0789351208046, color: '#feb24c' },
        { y: 0.002154, x: 46.4373524336643, color: '#fed976' },
        { y: 0.001, x: 37.6580926732108, color: '#ffeda0' },
      ],
    }),
    new Dataset({
      internalName: 'SDI',
      name: 'Suppression Difficulty Index',
      percentiles: caSDIPercentiles,
      labels: {
        legendXLabel: 'Percentile Risk',
        legendYLabel: 'SDI',
        graphTitle: 'SDI',
      },
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'CAL_SDI_30m_3857_optimized',
      },
      legendType: 'BP',
      chartScale: { y: 'log' },
      chartPadding: {
        left: 60,
        right: 20,
        top: 20,
        bottom: 40,
      },
      yTickValues: [0.01, 0.1, 1],
      domain: { y: [0.01, 2.5] },
      titleFunction: (v) => v.toFixed(5),
      hasAdjectiveLabel: true,
      classBreaks: [
        {
          y: 2.38095235824585,
          x: 100,
          color: '#D7191C',
          adjective: 'Very High Difficulty',
        },
        {
          y: 0.8,
          x: 91.9999997351691,
          color: '#FDAE61',
          adjective: 'High Difficulty',
        },
        {
          y: 0.4,
          x: 76.5704924407761,
          color: '#FFFFBF',
          adjective: 'Moderate Difficulty',
        },
        {
          y: 0.2,
          x: 61.9999986103875,
          color: '#ABD9E9',
          adjective: 'Low Difficulty',
        },
        {
          y: 0.1,
          x: 38.2083854912068,
          color: '#95B0C4',
          adjective: 'Very Low Difficulty',
        },
        {
          y: 0.01,
          x: 17.0000000075903,
          color: '#B2B2B2',
          adjective: 'Little to No Difficulty',
        },
      ],
    }),
    new Dataset({
      internalName: 'WHP',
      name: 'Wildfire Hazard Potential',
      percentiles: caWHPPercentiles,
      labels: {
        legendYLabel: 'WHP',
        legendXLabel: 'Percentile Risk',
        graphTitle: 'WHP',
      },
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'CAL_WHP_30m_3857_optimized',
      },
      legendType: 'BP',
      chartScale: { y: 'log' },
      chartPadding: {
        left: 60,
        right: 20,
        top: 20,
        bottom: 40,
      },
      domain: { y: [1, 98158] },
      yTickValues: [1, 10, 100, 1000, 10000, 100000],
      classBreaks: [
        { y: 98158, x: 100, color: '#FF2B18' },
        { y: 10000, x: 93.8949367088608, color: '#FE7A18' },
        { y: 3162, x: 87.3535353535354, color: '#FEA600' },
        { y: 1000, x: 80.0347222222222, color: '#FFE000' },
        { y: 316.2, x: 65.3444444444444, color: '#FFFD74' },
        { y: 100, x: 46.625, color: '#42FCEE' },
        { y: 31.62, x: 36.124, color: '#46D6FE' },
        { y: 10, x: 30, color: '#689EFF' },
      ],
      // pixelConversion: (v) => Math.log10(v),
    }),
    new Dataset({
      internalName: 'DP',
      name: 'Damage Potential',
      percentiles: caDPPercentiles,
      labels: {
        legendXLabel: 'Percentile Risk',
        legendYLabel: 'Damage Potential',
        graphTitle: 'DP',
      },
      legendType: 'BP',
      // chartScale: { y: 'log' },
      chartPadding: {
        left: 60,
        right: 20,
        top: 20,
        bottom: 40,
      },
      // yTickValues: [10, 100, 1000],
      domain: { y: [0, 100] },
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'CAL_DP_NoMexico_3857_optimized',
      },
      titleFunction: (v) => v.toFixed(5),
      classBreaks: [
        { y: 101.716720581055, x: 100, color: '#6B0601' },
        { y: 80, x: 98.4900672741842, color: '#9E4410' },
        { y: 60, x: 91.7707418052419, color: '#D68522' },
        { y: 40, x: 77.5025412216601, color: '#F7BA3E' },
        { y: 20, x: 34.7503873797768, color: '#FCDD5D' },
      ],
    }),
    new Dataset({
      internalName: 'SES',
      name: 'Structure Exposure Score',
      percentiles: caSESPercentiles,
      labels: {
        legendXLabel: 'Percentile Risk',
        legendYLabel: 'SES',
        haulingLegendXLabel: 'Annual Burn Probability',
        haulingLegendYLabel: 'Damage Potential',
        graphTitle: 'SES',
      },
      legendType: 'BP',
      chartScale: { y: 'log' },
      chartPadding: {
        left: 60,
        right: 20,
        top: 20,
        bottom: 40,
      },
      yTickValues: [10, 100, 1000],
      domain: { y: [14.204, 1000] },
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'CAL_SES_NoMexico_3857_optimized',
      },
      titleFunction: (v) => v.toFixed(5),
      hasHauling: true,
      hauling: {
        url: 'ca_hauling_1.json',
        domain: { y: [0, 100], x: [0.0000106993205379072, 0.290740797443507] },
        scale: { x: 'log' },
        yTickValues: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
        xTickValues: [0.0001, 0.001, 0.01, 0.1],
        chartPadding: {
          left: 60,
          right: 20,
          top: 20,
          bottom: 50,
        },
      },
      hasAdjectiveLabel: true,
      classBreaks: [
        {
          y: 1000,
          x: 100,
          color: '#6B0000',
          adjective: 'SES X',
        },
        {
          y: 854,
          x: 99.3841936255504,
          color: '#872A08',
          adjective: 'SES IX',
        },
        {
          y: 570,
          x: 93.5721587456662,
          color: '#A64C11',
          adjective: 'SES VIII',
        },
        {
          y: 380,
          x: 83.4566838007285,
          color: '#C46D1B',
          adjective: 'SES VII',
        },
        {
          y: 253,
          x: 67.5240352793491,
          color: '#E39529',
          adjective: 'SES VI',
        },
        {
          y: 169,
          x: 47.0216143710397,
          color: '#F5AF36',
          adjective: 'SES V',
        },
        {
          y: 113,
          x: 35.2681071620894,
          color: '#F7C348',
          adjective: 'SES IV',
        },
        {
          y: 75,
          x: 25.6539840476958,
          color: '#FCD75B',
          adjective: 'SES III',
        },
        {
          y: 50,
          x: 17.5535072914245,
          color: '#E1E1FA',
          adjective: 'SES II',
        },
      ],
    }),
  ],
});

const mt = new Project({
  abbreviation: 'mt',
  title: 'MONTANA',
  center: [-12181066.935553918, 5952989.503318844],
  zoom: 5,
  referenceLayers: [
    new Reference({
      internalName: 'mtBuildingCover',
      selected: false,
      toolsVisible: false,
      name: 'Building Cover',
      wmsParams: {
        LAYERS: 'BuildingCover_MT_3857_optimized',
      },
    }),
    new Reference({
      internalName: 'mtHUDen',
      selected: false,
      toolsVisible: false,
      name: 'Housing Unit Density',
      wmsParams: {
        LAYERS: 'HUDen_MT_3857_optimized',
      },
    }),
    new Reference({
      internalName: 'curFirePerimeters',
      selected: false,
      toolsVisible: false,
      name: 'Active Fire Perimeters',
      nonInteractive: true,
      externalLayer: firePerimetersCurrent,
    }),
    new Reference({
      internalName: 'VIIRS24',
      selected: false,
      toolsVisible: false,
      name: '24-Hour VIIRS',
      externalLayer: viirs24Feature,
      nonInteractive: true,
    }),
    new Reference({
      internalName: 'VIIRS48',
      selected: false,
      toolsVisible: false,
      name: '48-Hour VIIRS',
      externalLayer: viirs48Feature,
      nonInteractive: true,
    }),
  ],
  baseLayers: [
    // new Dataset({
    //   internalName: 'oSM',
    //   name: 'Open Street Map',
    //   selected: true,
    //   toolsVisible: false,
    // }),
    new Dataset({
      internalName: 'baseFootprints',
      name: 'Topo',
      selected: true,
      toolsVisible: false,
      isBase: true,
    }),
    new Dataset({
      internalName: 'baseSatellite',
      name: 'Satellite',
      selected: false,
      toolsVisible: false,
      isBase: true,
    }),
  ],
  datasets: [
    new Dataset({
      internalName: 'BP',
      name: 'Burn Probability',
      selected: true,
      toolsVisible: true,
      percentiles: mtBPPercentiles,
      legendType: 'BP',
      chartScale: { y: 'log' },
      chartPadding: {
        left: 60,
        right: 20,
        top: 20,
        bottom: 40,
      },
      yTickValues: [0.0001, 0.001, 0.01, 0.1],
      domain: { y: [0.00000999999974737875, 0.2154] },
      labels: {
        legendXLabel: 'Percentile Risk',
        legendYLabel: 'Annual BP',
        graphTitle: 'Annual BP',
      },
      wmsParams: {
        LAYERS: 'mwra_bp_30m_3857_optimized',
      },
      titleFunction: (v) => v.toFixed(5),
      classBreaks: [
        // Manually changed to make top class the same "width" as other classes
        { y: 0.2154, x: 100, color: '#800026' },
        { y: 0.1, x: 99.4968381025429, color: '#bd0026' },
        { y: 0.046416, x: 99.4302214593172, color: '#e31a1c' },
        { y: 0.021544, x: 98.9774604446693, color: '#fc4e2a' },
        { y: 0.01, x: 93.9469034657627, color: '#fd8d3c' },
        { y: 0.004642, x: 78.5619893891508, color: '#feb24c' },
        { y: 0.002154, x: 57.9024820994735, color: '#fed976' },
        { y: 0.001, x: 37.7041222678409, color: '#ffeda0' },
      ],
    }),
    new Dataset({
      internalName: 'RPS',
      name: 'Risk to Potential Structures',
      selected: false,
      toolsVisible: false,
      percentiles: mtRPSPercentiles,
      wmsParams: {
        LAYERS: 'mwra_RPS_30m_3857_optimized',
      },
      legendType: 'BP',
      chartScale: { y: 'log' },
      chartPadding: {
        left: 60,
        right: 20,
        top: 20,
        bottom: 40,
      },
      yTickValues: [0.0001, 0.001, 0.01, 0.1, 1, 10],
      domain: { y: [0.00133045330178, 10] },
      labels: {
        legendXLabel: 'Percentile Risk',
        legendYLabel: 'RPS',
        graphTitle: 'RPS',
      },
      titleFunction: (v) => v.toFixed(5),
      classBreaks: [
        // Manually changed to make top class the same "width" as other classes
        { y: 10, x: 100, color: '#6B0000' },
        { y: 3.162278, x: 99.348505487054, color: '#91330A' },
        { y: 1.0, x: 98.1826496420174, color: '#B55E18' },
        { y: 0.316228, x: 88.7130978930678, color: '#E09026' },
        { y: 0.1, x: 65.951767039874, color: '#F7B543' },
        { y: 0.031623, x: 39.4899079852313, color: '#FCCC6A' },
        { y: 0.01, x: 17.9649311566504, color: '#FFE494' },
        { y: 0.003162, x: 9.98898448160889, color: '#FFFFBF' },
      ],
    }),
  ],
});

const co = new Project({
  abbreviation: 'co',
  title: 'COLORADO',
  center: [-11741297.631419545, 4739036.127435003],
  zoom: 5,
  referenceLayers: [
    new Reference({
      internalName: 'coBuildingCover',
      selected: false,
      toolsVisible: false,
      name: 'Building Cover',
      wmsParams: {
        LAYERS: 'BuildingCover_CO_3857_optimized',
      },
    }),
    new Reference({
      internalName: 'coHUDen',
      selected: false,
      toolsVisible: false,
      name: 'Housing Unit Density',
      wmsParams: {
        LAYERS: 'HUDen_CO_3857_optimized',
      },
    }),
    new Reference({
      internalName: 'COAL_2020_Fire_Perimeters',
      selected: false,
      toolsVisible: false,
      name: '2020 Fire Perimeters',
      wmsParams: {
        LAYERS: 'COAL_2020_Fire_Perimeters',
        STYLES: 'Fire_Perimeter_Med_Charcoal',
      },
    }),
    new Reference({
      internalName: 'COAL_2021_Fire_Perimeters',
      selected: false,
      toolsVisible: false,
      name: '2021 Fire Perimeters',
      wmsParams: {
        LAYERS: 'COAL_2021_Fire_Perimeters',
        STYLES: 'Fire_Perimeter_Dark_Charcoal',
      },
    }),
    new Reference({
      internalName: 'VIIRS24',
      selected: false,
      toolsVisible: false,
      name: '24-Hour VIIRS',
      externalLayer: viirs24Feature,
      nonInteractive: true,
    }),
    new Reference({
      internalName: 'VIIRS48',
      selected: false,
      toolsVisible: false,
      name: '48-Hour VIIRS',
      externalLayer: viirs48Feature,
      nonInteractive: true,
    }),
    new Reference({
      internalName: 'curFirePerimeters',
      selected: false,
      toolsVisible: false,
      name: 'Active Fire Perimeters',
      nonInteractive: true,
      externalLayer: firePerimetersCurrent,
    }),
  ],
  baseLayers: [
    new Dataset({
      internalName: 'baseFootprints',
      name: 'Topo',
      selected: true,
      toolsVisible: false,
      isBase: true,
    }),
    new Dataset({
      internalName: 'baseSatellite',
      name: 'Satellite',
      selected: false,
      toolsVisible: false,
      isBase: true,
    }),
  ],
  // Order matters here, or at least it does until we define explict ordering
  // and/or allow re-ordering of layers
  datasets: [
    new Dataset({
      internalName: 'COAL_2020_BP',
      name: '2020 Burn Probability',
      percentiles: COAL2020BPPercentiles,
      labels: {
        legendXLabel: 'Percentile Risk',
        legendYLabel: 'Annual BP',
        graphTitle: 'Annual BP',
      },
      legendType: 'BP',
      chartScale: { y: 'log' },
      chartPadding: {
        left: 60,
        right: 20,
        top: 20,
        bottom: 40,
      },
      yTickValues: [0.0001, 0.001, 0.01, 0.1],
      domain: { y: [0.00000999999974737875, 0.0160823240876198] },
      selected: false,
      toolsVisible: true,
      wmsParams: {
        LAYERS: 'COAL_2020_BP',
      },
      titleFunction: (v) => v.toFixed(5),
      classBreaks: [
        // Manually changed to make top class the same "width" as other classes
        //
        // Trying with closest intercept (visually)
        { y: 0.2154, x: 100, color: '#800026' },
        { y: 0.1, x: 100, color: '#bd0026' },
        { y: 0.046416, x: 100, color: '#e31a1c' },
        { y: 0.021544, x: 100, color: '#fc4e2a' },
        { y: 0.01, x: 99, color: '#fd8d3c' },
        { y: 0.004642, x: 89, color: '#feb24c' },
        { y: 0.002154, x: 65.9835046, color: '#fed976' },
        { y: 0.001, x: 37.6580926732108, color: '#ffeda0' },
      ],
    }),
    new Dataset({
      internalName: 'COAL_2021_BP',
      name: '2021 Burn Probability',
      percentiles: COAL2021BPPercentiles,
      labels: {
        legendXLabel: 'Percentile Risk',
        legendYLabel: 'Annual BP',
        graphTitle: 'Annual BP',
      },
      legendType: 'BP',
      chartScale: { y: 'log' },
      chartPadding: {
        left: 60,
        right: 20,
        top: 20,
        bottom: 40,
      },
      yTickValues: [0.0001, 0.001, 0.01, 0.1],
      domain: { y: [0.00000999999974737875, 0.016124073] },
      selected: true,
      toolsVisible: true,
      wmsParams: {
        LAYERS: 'COAL_2021_BP',
      },
      titleFunction: (v) => v.toFixed(5),
      classBreaks: [
        // Manually changed to make top class the same "width" as other classes
        { y: 0.2154, x: 100, color: '#800026' },
        { y: 0.1, x: 100, color: '#bd0026' },
        { y: 0.046416, x: 100, color: '#e31a1c' },
        { y: 0.021544, x: 100, color: '#fc4e2a' },
        { y: 0.01, x: 99, color: '#fd8d3c' },
        { y: 0.004642, x: 90, color: '#feb24c' },
        { y: 0.002154, x: 71, color: '#fed976' },
        { y: 0.001, x: 38, color: '#ffeda0' },
      ],
    }),
  ],
});

const calfire = new Project({
  abbreviation: 'calfire',
  title: 'CalFire',
  hazardLayerHeaderName: 'Layers',
  center: [-13293543.289, 4408243.893],
  zoom: 6,
  maxZoom: 16,
  referenceLayers: [],
  datasets: [
    new Dataset({
      internalName: 'fm40',
      name: 'Fire Behavior Fuel Model (FM40)',
      selected: true,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_fm40',
      },
      legendType: 'Paletted',
      classBreaks: Object.keys(fm40CodeMap).map((key) => fm40CodeMap[key]),
    }),
    new Dataset({
      internalName: 'fl',
      name: 'Flame Length (FL) 15 mi/hr',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_flamelength',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: '0 ft', color: '#F0F0F0' },
        { label: '>0 - 4 ft', color: '#FAFAB9' },
        { label: '4 - 8 ft', color: '#FED98E' },
        { label: '8 - 11 ft', color: '#FE9929' },
        { label: '11 - 25 ft', color: '#D95F0E' },
        { label: '>25 ft', color: '#993404' },
      ],
    }),
    new Dataset({
      internalName: 'ros',
      name: 'Rate of Spread (ROS) 15 mi/hr',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_spreadrate',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: '0 ch/hr', color: '#F0F0F0' },
        { label: '>0 - 5 ch/hr', color: '#FCE468' },
        { label: '5 - 10 ch/hr', color: '#FACD50' },
        { label: '10 - 20 ch/hr', color: '#F5B338' },
        { label: '20 - 30 ch/hr', color: '#E09026' },
        { label: '30 - 50 ch/hr', color: '#B55E18' },
        { label: '50 - 100 ch/hr', color: '#91330A' },
        { label: '>100 ch/hr', color: '#6B0000' },
      ],
    }),
    new Dataset({
      internalName: 'wfl',
      name: 'WildEST Flame Length (wFL)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_wfl',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: '0 ft', color: '#F0F0F0' },
        { label: '>0 - 4 ft', color: '#FAFAB9' },
        { label: '4 - 8 ft', color: '#FED98E' },
        { label: '8 - 11 ft', color: '#FE9929' },
        { label: '11 - 25 ft', color: '#D95F0E' },
        { label: '>25 ft', color: '#993404' },
      ],
    }),
    new Dataset({
      internalName: 'wros',
      name: 'WildEST Rate of Spread (wROS)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_wros',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: '0 ch/hr', color: '#F0F0F0' },
        { label: '>0 - 5 ch/hr', color: '#FCE468' },
        { label: '5 - 10 ch/hr', color: '#FACD50' },
        { label: '10 - 20 ch/hr', color: '#F5B338' },
        { label: '20 - 30 ch/hr', color: '#E09026' },
        { label: '30 - 50 ch/hr', color: '#B55E18' },
        { label: '50 - 100 ch/hr', color: '#91330A' },
        { label: '>100 ch/hr', color: '#6B0000' },
      ],
    }),
    new Dataset({
      internalName: 'ti',
      name: 'Torching Index (TI)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_ti',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: '0 mi/hr', color: '#6B0000' },
        { label: '>0 - 5 mi/hr', color: '#91330A' },
        { label: '5 - 10 mi/hr', color: '#B55E18' },
        { label: '10 - 15 mi/hr', color: '#E09026' },
        { label: '15 - 20 mi/hr', color: '#F5B338' },
        { label: '20 - 25 mi/hr', color: '#FACD50' },
        { label: '25 - 30 mi/hr', color: '#FCE468' },
        { label: '> 30 mi/hr', color: '#FFFF80' },
      ],
    }),
    new Dataset({
      internalName: 'ci',
      name: 'Crowning Index (CI)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_ci',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: '0 mi/hr', color: '#6B0000' },
        { label: '>0 - 5 mi/hr', color: '#91330A' },
        { label: '5 - 10 mi/hr', color: '#B55E18' },
        { label: '10 - 15 mi/hr', color: '#E09026' },
        { label: '15 - 20 mi/hr', color: '#F5B338' },
        { label: '20 - 25 mi/hr', color: '#FACD50' },
        { label: '25 - 30 mi/hr', color: '#FCE468' },
        { label: '> 30 mi/hr', color: '#FFFF80' },
      ],
    }),
    new Dataset({
      internalName: 'ftp_active',
      name: 'WildEST FTP - Active',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_ftp_active',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: '0', color: '#F2F2F2' },
        { label: '>0 - 0.1', color: '#E6E6E6' },
        { label: '0.1 - 0.2', color: '#CCCCCC' },
        { label: '0.2 - 0.3', color: '#B3B3B3' },
        { label: '0.3 - 0.4', color: '#999999' },
        { label: '0.4 - 0.5', color: '#808080' },
        { label: '0.5 - 0.6', color: '#666666' },
        { label: '0.6 - 0.7', color: '#4D4D4D' },
        { label: '0.7 - 0.8', color: '#333333' },
        { label: '0.8 - 0.9', color: '#1A1A1A' },
        { label: '0.9 - 1', color: '#000000' },
      ],
    }),
    new Dataset({
      internalName: 'ftp_passive',
      name: 'WildEST FTP - Passive',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_ftp_passive',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: '0', color: '#F2F2F2' },
        { label: '>0 - 0.1', color: '#E6E6E6' },
        { label: '0.1 - 0.2', color: '#CCCCCC' },
        { label: '0.2 - 0.3', color: '#B3B3B3' },
        { label: '0.3 - 0.4', color: '#999999' },
        { label: '0.4 - 0.5', color: '#808080' },
        { label: '0.5 - 0.6', color: '#666666' },
        { label: '0.6 - 0.7', color: '#4D4D4D' },
        { label: '0.7 - 0.8', color: '#333333' },
        { label: '0.8 - 0.9', color: '#1A1A1A' },
        { label: '0.9 - 1', color: '#000000' },
      ],
    }),
    new Dataset({
      internalName: 'ftp_underburn',
      name: 'WildEST FTP - Underburn',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_ftp_underburn',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: '0', color: '#F2F2F2' },
        { label: '>0 - 0.1', color: '#E6E6E6' },
        { label: '0.1 - 0.2', color: '#CCCCCC' },
        { label: '0.2 - 0.3', color: '#B3B3B3' },
        { label: '0.3 - 0.4', color: '#999999' },
        { label: '0.4 - 0.5', color: '#808080' },
        { label: '0.5 - 0.6', color: '#666666' },
        { label: '0.6 - 0.7', color: '#4D4D4D' },
        { label: '0.7 - 0.8', color: '#333333' },
        { label: '0.8 - 0.9', color: '#1A1A1A' },
        { label: '0.9 - 1', color: '#000000' },
      ],
    }),
    new Dataset({
      internalName: 'ftp_surface',
      name: 'WildEST FTP - Surface',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_ftp_surface',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: '0', color: '#F2F2F2' },
        { label: '>0 - 0.1', color: '#E6E6E6' },
        { label: '0.1 - 0.2', color: '#CCCCCC' },
        { label: '0.2 - 0.3', color: '#B3B3B3' },
        { label: '0.3 - 0.4', color: '#999999' },
        { label: '0.4 - 0.5', color: '#808080' },
        { label: '0.5 - 0.6', color: '#666666' },
        { label: '0.6 - 0.7', color: '#4D4D4D' },
        { label: '0.7 - 0.8', color: '#333333' },
        { label: '0.8 - 0.9', color: '#1A1A1A' },
        { label: '0.9 - 1', color: '#000000' },
      ],
    }),
    new Dataset({
      internalName: 'cc',
      name: 'Canopy Cover (CC)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_cc',
      },
      legendType: 'Gradient',
      classBreaks: [
        {
          breaks: [
            { label: '10% <= CC < 20%', color: '#EAFFBF' },
            { label: '90% <= CC <= 100%', color: '#10240C' },
          ],
          type: 'gradient',
        },
        { label: 'Non-Forested', color: '#828282', type: 'palette' },
      ],
    }),
    new Dataset({
      internalName: 'ch',
      name: 'Canopy Height (CH)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_ch',
      },
      legendType: 'Gradient',
      classBreaks: [
        {
          breaks: [
            { label: '1.8 m <= CH < 5 m', color: '#CCFFCC' },
            { label: 'CH >= 50 m', color: '#0ECC0E' },
          ],
          type: 'gradient',
        },
        { label: 'Non-Forested', color: '#828282', type: 'palette' },
      ],
    }),
    new Dataset({
      internalName: 'evc',
      name: 'Existing Veg. Cover (EVC)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_evc',
      },
      legendType: 'Gradient',
      classBreaks: [
        {
          breaks: [
            { label: 'Tree Cover = 10%', color: '#CCFF99' },
            { label: 'Tree Cover = 95%', color: '#043B02' },
          ],
          type: 'gradient',
        },
        {
          breaks: [
            { label: 'Shrub Cover = 10%', color: '#D4B27D' },
            { label: 'Shrub Cover = 79%', color: '#AD3731' },
          ],
          type: 'gradient',
        },
        {
          breaks: [
            { label: 'Herb Cover = 10%', color: '#FFCC66' },
            { label: 'Herb Cover = 99%', color: '#FF3300' },
          ],
          type: 'gradient',
        },
        { label: 'Open Water', color: '#0000FF', type: 'palette' },
        { label: 'Snow/Ice', color: '#9FA1F0', type: 'palette' },
        { label: 'Developed-Upland Deciduous Forest', color: '#403DA8', type: 'palette' },
        { label: 'Developed-Upland Evergreen Forest', color: '#444F89', type: 'palette' },
        { label: 'Developed-Upland Mixed Forest', color: '#6677CD', type: 'palette' },
        { label: 'Developed-Upland Herbaceous', color: '#7A8EF5', type: 'palette' },
        { label: 'Developed-Upland Shrubland', color: '#9EAAD7', type: 'palette' },
        { label: 'Developed-Low Intensity', color: '#FF7A8F', type: 'palette' },
        { label: 'Developed-Medium Intensity', color: '#FD2C4F', type: 'palette' },
        { label: 'Developed-High Intensity', color: '#AD001C', type: 'palette' },
        { label: 'Developed-Roads', color: '#010101', type: 'palette' },
        { label: 'Barren', color: '#BFBFBF', type: 'palette' },
        { label: 'Quarries-Strip Mines-Gravel Pits-Well and Wind Pads', color: '#E6E8FA', type: 'palette' },
        { label: 'NASS-Vineyard', color: '#A80084', type: 'palette' },
        { label: 'NASS-Row Crop-Close Grown Crop', color: '#FFF58C', type: 'palette' },
        { label: 'NASS-Row Crop', color: '#FAFF77', type: 'palette' },
        { label: 'NASS-Close Grown Crop', color: '#FFED77', type: 'palette' },
        { label: 'NASS-Wheat', color: '#FAE3A3', type: 'palette' },
        { label: 'NASS-Aquaculture', color: '#D2FFED', type: 'palette' },
        { label: 'Cultivated Crops', color: '#FFFF78', type: 'palette' },
        { label: 'Sparse Vegetation Canopy', color: '#7A7F75', type: 'palette' },
      ],
    }),
    new Dataset({
      internalName: 'evh',
      name: 'Existing Veg. Height (EVH)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_evh',
      },
      legendType: 'Gradient',
      classBreaks: [
        {
          breaks: [
            { label: 'Tree Height = 1 m', color: '#CCFF99' },
            { label: 'Tree Height = 42 m', color: '#043B02' },
          ],
          type: 'gradient',
        },
        {
          breaks: [
            { label: 'Shrub Height = 0.1 m', color: '#D4B27D' },
            { label: 'Shrub Height >= 3 m', color: '#9E0020' },
          ],
          type: 'gradient',
        },
        {
          breaks: [
            { label: 'Herb Height = 0.1 m', color: '#FFCC66' },
            { label: 'Herb Height >= 1 m', color: '#FF3300' },
          ],
          type: 'gradient',
        },
        { label: 'Open Water', color: '#0000FF', type: 'palette' },
        { label: 'Snow/Ice', color: '#9FA1F0', type: 'palette' },
        { label: 'Developed-Upland Deciduous Forest', color: '#403DA8', type: 'palette' },
        { label: 'Developed-Upland Evergreen Forest', color: '#444F89', type: 'palette' },
        { label: 'Developed-Upland Mixed Forest', color: '#6677CD', type: 'palette' },
        { label: 'Developed-Upland Herbaceous', color: '#7A8EF5', type: 'palette' },
        { label: 'Developed-Upland Shrubland', color: '#9EAAD7', type: 'palette' },
        { label: 'Developed-Low Intensity', color: '#FF7A8F', type: 'palette' },
        { label: 'Developed-Medium Intensity', color: '#FD2C4F', type: 'palette' },
        { label: 'Developed-High Intensity', color: '#AD001C', type: 'palette' },
        { label: 'Developed-Roads', color: '#010101', type: 'palette' },
        { label: 'Barren', color: '#BFBFBF', type: 'palette' },
        { label: 'Quarries-Strip Mines-Gravel Pits-Well and Wind Pads', color: '#E6E8FA', type: 'palette' },
        { label: 'NASS-Vineyard', color: '#A80084', type: 'palette' },
        { label: 'NASS-Row Crop-Close Grown Crop', color: '#FFF58C', type: 'palette' },
        { label: 'NASS-Row Crop', color: '#FAFF77', type: 'palette' },
        { label: 'NASS-Close Grown Crop', color: '#FFED77', type: 'palette' },
        { label: 'NASS-Wheat', color: '#FAE3A3', type: 'palette' },
        { label: 'NASS-Aquaculture', color: '#D2FFED', type: 'palette' },
        { label: 'Cultivated Crops', color: '#FFFF78', type: 'palette' },
        { label: 'Sparse Vegetation Canopy', color: '#7A7F75', type: 'palette' },
      ],
    }),
    new Dataset({
      internalName: 'cbh',
      name: 'Canopy Base Height (CBH)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_cbh',
      },
      legendType: 'Gradient',
      classBreaks: [
        {
          breaks: [
            { label: 'CBH = 0.2 m', color: '#FAA905' },
            { label: 'CBH >= 10 m', color: '#005CE6' },
          ],
          type: 'gradient',
        },
        { label: 'Non-Forested', color: '#000000', type: 'palette' },
      ],
    }),
    new Dataset({
      internalName: 'cbd',
      name: 'Canopy Bulk Density (CBD)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_cbd',
      },
      legendType: 'Gradient',
      classBreaks: [
        {
          breaks: [
            { label: 'CBD = 0.01 kg/m^3', color: '#BEFFE8' },
            { label: 'CBD >= 0.45 kg/m^3', color: '#004DA8' },
          ],
          type: 'gradient',
        },
        { label: 'Non-Forested', color: '#000000', type: 'palette' },
      ],
    }),
    new Dataset({
      internalName: 'waf',
      name: 'Wind Adjustment Factor (WAF)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_waf',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: '0', color: '#F0F0F0' },
        { label: '>0 - 0.1', color: '#9DD6ED' },
        { label: '0.1 - 0.2', color: '#81C0EB' },
        { label: '0.2 - 0.3', color: '#66ABE8' },
        { label: '0.3 - 0.4', color: '#4695E3' },
        { label: '0.4 - 0.5', color: '#1F83E0' },
        { label: '0.5 - 0.6', color: '#216BD1' },
        { label: '0.6 - 0.7', color: '#2150BF' },
        { label: '0.7 - 0.8', color: '#1C3AB0' },
        { label: '0.8 - 0.9', color: '#1523A1' },
        { label: '0.9 - 1', color: '#090991' },
      ],
    }),
    new Dataset({
      internalName: 'fdist',
      name: 'Fuel Disturbance (FDIST)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_fdist',
      },
      legendType: 'Paletted',
      classBreaks: [
        { label: 'No Disturbance', color: '#000000' },
        { label: 'Fire' },
        { label: 'Low-severity', color: '#FFCCCC' },
        { label: 'Moderate-severity', color: '#FF9999' },
        { label: 'High-severity', color: '#FF5050' },
        { label: 'Mechanical Add' },
        { label: 'Low-severity', color: '#FCE4D6' },
        { label: 'Moderate-severity', color: '#F4B084' },
        { label: 'High-severity', color: '#C65911' },
        { label: 'Mechanical Remove' },
        { label: 'Low-severity', color: '#FFF2CC' },
        { label: 'Moderate-severity', color: '#FFE699' },
        { label: 'High-severity', color: '#FFD966' },
        { label: 'Windthrow' },
        { label: 'Low-severity', color: '#CDFFE6' },
        { label: 'Moderate-severity', color: '#66FFCC' },
        { label: 'High-severity', color: '#00CC99' },
        { label: 'Insects-Disease' },
        { label: 'Low-severity', color: '#DDEBF7' },
        { label: 'Moderate-severity', color: '#BDD7EE' },
        { label: 'High-severity', color: '#9BC2E6' },
        { label: 'Mechanical Unknown' },
        { label: 'Low-severity', color: '#4988F9' },
        { label: 'Moderate-severity', color: '#0857E6' },
        { label: 'High-severity', color: '#042D75' },
        { label: 'Mastication' },
        { label: 'Low-severity', color: '#F6CB9C' },
        { label: 'Moderate-severity', color: '#F1B069' },
        { label: 'High-severity', color: '#ED7D31' },
      ],
    }),
    new Dataset({
      internalName: 'bp',
      name: 'Burn Probability (BP)',
      selected: false,
      toolsVisible: false,
      percentiles: caBPPercentiles,
      legendType: 'BP',
      chartScale: { y: 'log' },
      chartPadding: {
        left: 60,
        right: 20,
        top: 20,
        bottom: 40,
      },
      yTickValues: [0.0001, 0.001, 0.01, 0.1],
      domain: { y: [0.0000256704497587634, 0.121365398] },
      labels: {
        legendXLabel: 'Percentile Risk',
        legendYLabel: 'Annual BP',
        graphTitle: 'Annual BP',
      },
      wmsParams: {
        LAYERS: 'calfire_bp',
      },
      titleFunction: (v) => v.toFixed(5),
      classBreaks: [
        // Manually changed to make top class the same "width" as other classes
        { y: 0.121365398, x: 100, color: '#800026' },
        { y: 0.1, x: 99.5, color: '#bd0026' },
        { y: 0.046416, x: 99.3, color: '#e31a1c' },
        { y: 0.021544, x: 98.1, color: '#fc4e2a' },
        { y: 0.01, x: 97, color: '#fd8d3c' },
        { y: 0.004642, x: 89.5, color: '#feb24c' },
        { y: 0.002154, x: 79.4, color: '#fed976' },
        { y: 0.001, x: 65, color: '#ffeda0' },
      ],
    }),
    new Dataset({
      internalName: 'slp',
      name: 'Slope',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_slp',
      },
      legendType: 'Gradient',
      classBreaks: [
        {
          breaks: [
            { label: '0 \u00B0', color: '#3BA800' },
            { label: '43 \u00B0', color: '#FFFF00' },
            { label: '87  \u00B0', color: '#FF0000' },
          ],
          type: 'gradient',
        },
      ],
    }),
    new Dataset({
      internalName: 'asp',
      name: 'Aspect',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_asp',
      },
      legendType: 'Gradient',
      classBreaks: [
        {
          breaks: [
            { label: '0 \u00B0', color: '#B5AAA7' },
            { label: '99 \u00B0', color: '#FFD500' },
            { label: '198 \u00B0', color: '#FFFF00' },
          ],
          type: 'gradient',
        },
        {
          breaks: [
            { label: '199 \u00B0', color: '#00FFFF' },
            { label: '280 \u00B0', color: '#1500FF' },
            { label: '359 \u00B0', color: '#FF0008' },
          ],
          type: 'gradient',
        },
        { label: '-1', color: '#B3ADAB', type: 'palette' },
      ],
    }),
    new Dataset({
      internalName: 'bps',
      name: 'Biophysical Settings (BPS)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_bps',
      },
      legendType: false,
      classBreaks: [],
      onlyQueryable: true,
    }),
    new Dataset({
      internalName: 'rule_id',
      name: 'Rule ID',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_rule_id',
      },
      legendType: 'Paletted',
      classBreaks: [],
      onlyQueryable: true,
    }),
    new Dataset({
      internalName: 'fvt',
      name: 'Existing Veg. Type (EVT)',
      selected: false,
      toolsVisible: false,
      wmsParams: {
        LAYERS: 'calfire_fvt',
      },
      legendType: false,
      classBreaks: [],
      onlyQueryable: true,
    }),
  ],
  baseLayers: [
    new Dataset({
      internalName: 'baseFootprints',
      name: 'Topo',
      selected: true,
      toolsVisible: false,
      isBase: true,
    }),
    new Dataset({
      internalName: 'baseSatellite',
      name: 'Satellite',
      selected: false,
      toolsVisible: false,
      isBase: true,
    }),
  ],
});

export default {
  ca,
  mt,
  co,
  calfire,
};
