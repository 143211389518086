import WFHazAPI from '.';

export default class WFHazAuth extends WFHazAPI {
  constructor() {
    super();
    this.endpoint = 'auth';
  }

  async login(params) {
    // Should raise
    if (params.email && params.password) {
      const { email, password } = params;
      const url = `${this.endpoint}/login`;
      return super.post(url, { email, password });
    }
    return {};
  }

  async autologin() {
    // autologin is facilitated by user session cookies.
    // we simply post to /auth/autologin and the api will determine
    // whether or not the session exists and or is valid
    const url = `${this.endpoint}/autologin`;
    return super.post(url);
  }

  async logout() {
    const url = `${this.endpoint}/logout`;
    return super.post(url);
  }
}
