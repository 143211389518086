import { UnknownString } from '../constants';
import { round } from './number';

export const formatFl = (val) => {
  if (val === false || val === undefined) return UnknownString;
  if (val >= 0 && val <= 1000) {
    return `${round(val, 2)} ft`;
  }

  return UnknownString;
};

export default {
  formatFl,
};
