import WFHazAPI from '.';

export default class WFHazEvts extends WFHazAPI {
  constructor() {
    super();
    this.endpoint = 'evts';
  }

  async get(params = {}) {
    if (!params.id) throw Error('Missing required param: id');

    return super.get(
      `${this.endpoint}/${params.id}`,
    );
  }
}
