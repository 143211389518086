import React from 'react';
import Styled from 'styled-components';
import {
  VictoryChart,
  VictoryBar,
  VictoryTheme,
  VictoryAxis,
  // VictoryLabel,
} from 'victory';
import { data, colors } from './Data';
import themes from '../../../styles/themes';

const ChartContainer = Styled.div`
  height: 100%;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
`;

export default function Chart(props) {
  const { year } = props;

  const getData = () => data[year % 1990];

  const valueMap = [
    { x: 1.5, formatValue: 0.0001 },
    { x: 4.5, formatValue: 0.001 },
    { x: 7.5, formatValue: 0.01 },
    { x: 10.5, formatValue: 0.1 },
  ];

  return (
    <ChartContainer>
      <VictoryChart
        width={500}
        height={300}
        domainPadding={10}
        padding={{ left: 70, bottom: 42 }}
        theme={VictoryTheme.material}
      >
        <VictoryBar
          data={getData()}
          barWidth={35}
          style={{
            data: {
              fill: ({ datum }) => colors[datum.x - 1],
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          style={{
            grid: {
              stroke: 'none',
            },
            tickLabels: {
              fill: themes.white,
            },
            ticks: {
              size: 3,
            },
            axis: {
              strokeWidth: '.5px',
            },
            axisLabel: {
              fill: themes.white,
              fontFamily: themes.fontFamily,
              padding: 40,
            },
          }}
          label="MM Acres"
          domain={[0, 3000000]}
          tickFormat={(t) => t / 1000000}

        />
        <VictoryAxis
          style={{
            grid: {
              stroke: 'none',
            },
            tickLabels: {
              fill: themes.white,
            },
            ticks: {
              size: 3,
            },
            axis: {
              strokeWidth: '.5px',
            },
            axisLabel: {
              fill: themes.white,
              fontFamily: themes.fontFamily,
              padding: 30,
            },
          }}
          label="Annual Burn Probability"
          domain={[1, 11]}
          tickValues={[1.5, 4.5, 7.5, 10.5]}
          tickFormat={(t) => valueMap.find((o) => o.x === t).formatValue}
        />
      </VictoryChart>
    </ChartContainer>
  );
}
