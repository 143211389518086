const caSDIPercentiles = [
  {
    x: 5,
    y: 0.01,
  },
  {
    x: 6,
    y: 0.01,
  },
  {
    x: 7,
    y: 0.01,
  },
  {
    x: 8,
    y: 0.01,
  },
  {
    x: 9,
    y: 0.01,
  },
  {
    x: 10,
    y: 0.01,
  },
  {
    x: 11,
    y: 0.01,
  },
  {
    x: 12,
    y: 0.01,
  },
  {
    x: 13,
    y: 0.01,
  },
  {
    x: 14,
    y: 0.01,
  },
  {
    x: 15,
    y: 0.01,
  },
  {
    x: 16,
    y: 0.01,
  },
  {
    x: 17,
    y: 0.01,
  },
  {
    x: 18,
    y: 0.039447732269764,
  },
  {
    x: 19,
    y: 0.047851208597422,
  },
  {
    x: 20,
    y: 0.053067032247782,
  },
  {
    x: 21,
    y: 0.056429736316204,
  },
  {
    x: 22,
    y: 0.058685444295406,
  },
  {
    x: 23,
    y: 0.059875237978995,
  },
  {
    x: 24,
    y: 0.06131986528635,
  },
  {
    x: 25,
    y: 0.063796676695347,
  },
  {
    x: 26,
    y: 0.06839395314455,
  },
  {
    x: 27,
    y: 0.07198560923338,
  },
  {
    x: 28,
    y: 0.075272716581821,
  },
  {
    x: 29,
    y: 0.078917376697063,
  },
  {
    x: 30,
    y: 0.082221813499928,
  },
  {
    x: 31,
    y: 0.085028946399689,
  },
  {
    x: 32,
    y: 0.087631724774838,
  },
  {
    x: 33,
    y: 0.08912655711174,
  },
  {
    x: 34,
    y: 0.091119945049286,
  },
  {
    x: 35,
    y: 0.09298712015152,
  },
  {
    x: 36,
    y: 0.094871796667576,
  },
  {
    x: 37,
    y: 0.096947811543942,
  },
  {
    x: 38,
    y: 0.099439136683941,
  },
  {
    x: 39,
    y: 0.102130606770515,
  },
  {
    x: 40,
    y: 0.105008073151112,
  },
  {
    x: 41,
    y: 0.107995837926865,
  },
  {
    x: 42,
    y: 0.110997267067432,
  },
  {
    x: 43,
    y: 0.113878258094191,
  },
  {
    x: 44,
    y: 0.116626791656017,
  },
  {
    x: 45,
    y: 0.118603363633156,
  },
  {
    x: 46,
    y: 0.12093648314476,
  },
  {
    x: 47,
    y: 0.123176045715809,
  },
  {
    x: 48,
    y: 0.125866010785103,
  },
  {
    x: 49,
    y: 0.129811033606529,
  },
  {
    x: 50,
    y: 0.134124889969826,
  },
  {
    x: 51,
    y: 0.138420775383711,
  },
  {
    x: 52,
    y: 0.14255166053772,
  },
  {
    x: 53,
    y: 0.146396398544312,
  },
  {
    x: 54,
    y: 0.150315716862679,
  },
  {
    x: 55,
    y: 0.155172422528267,
  },
  {
    x: 56,
    y: 0.160857915878296,
  },
  {
    x: 57,
    y: 0.166311860084534,
  },
  {
    x: 58,
    y: 0.172626197338104,
  },
  {
    x: 59,
    y: 0.180256113409996,
  },
  {
    x: 60,
    y: 0.188569769263268,
  },
  {
    x: 61,
    y: 0.197855353355408,
  },
  {
    x: 62,
    y: 0.200000002980232,
  },
  {
    x: 63,
    y: 0.206813827157021,
  },
  {
    x: 64,
    y: 0.217910513281822,
  },
  {
    x: 65,
    y: 0.230334624648094,
  },
  {
    x: 66,
    y: 0.243629470467567,
  },
  {
    x: 67,
    y: 0.257279515266418,
  },
  {
    x: 68,
    y: 0.270641207695007,
  },
  {
    x: 69,
    y: 0.283966779708862,
  },
  {
    x: 70,
    y: 0.297558784484863,
  },
  {
    x: 71,
    y: 0.311614096164703,
  },
  {
    x: 72,
    y: 0.326373219490051,
  },
  {
    x: 73,
    y: 0.342831283807755,
  },
  {
    x: 74,
    y: 0.360262423753738,
  },
  {
    x: 75,
    y: 0.377969264984131,
  },
  {
    x: 76,
    y: 0.395715326070786,
  },
  {
    x: 77,
    y: 0.403225809335709,
  },
  {
    x: 78,
    y: 0.420713841915131,
  },
  {
    x: 79,
    y: 0.438142240047455,
  },
  {
    x: 80,
    y: 0.456136435270309,
  },
  {
    x: 81,
    y: 0.475055187940598,
  },
  {
    x: 82,
    y: 0.495037376880646,
  },
  {
    x: 83,
    y: 0.516312062740326,
  },
  {
    x: 84,
    y: 0.539686748981474,
  },
  {
    x: 85,
    y: 0.565292656421661,
  },
  {
    x: 86,
    y: 0.593078970909119,
  },
  {
    x: 87,
    y: 0.610435247421265,
  },
  {
    x: 88,
    y: 0.641529202461243,
  },
  {
    x: 89,
    y: 0.674764788746835,
  },
  {
    x: 90,
    y: 0.711805826425554,
  },
  {
    x: 91,
    y: 0.754986643791199,
  },
  {
    x: 92,
    y: 0.800000011920929,
  },
  {
    x: 93,
    y: 0.842793226242065,
  },
  {
    x: 94,
    y: 0.909127593040466,
  },
  {
    x: 95,
    y: 0.991365134716034,
  },
  {
    x: 96,
    y: 1.01728475093842,
  },
  {
    x: 97,
    y: 1.12897116303444,
  },
  {
    x: 98,
    y: 1.27581059932709,
  },
  {
    x: 99,
    y: 1.43510496616364,
  },
  {
    x: 100,
    y: 2.38095235824585,
  },
];

export default caSDIPercentiles;
