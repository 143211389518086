const CAHauling = [
  [
    {
      x: 0,
      y: 50,
    },
    {
      x: 1.06993205379072e-05,
      y: 46.8342332005168,
    },
    {
      x: 1.28391846454886e-05,
      y: 40.0026657097571,
    },
    {
      x: 1.54070215745864e-05,
      y: 34.9103926590184,
    },
    {
      x: 1.84884258895036e-05,
      y: 30.9681930105744,
    },
    {
      x: 2.21861110674044e-05,
      y: 27.8259882734202,
    },
    {
      x: 2.66233332808852e-05,
      y: 25.2626950273143,
    },
    {
      x: 3.19479999370623e-05,
      y: 23.13182215688,
    },
    {
      x: 3.83375999244747e-05,
      y: 21.3324591654729,
    },
    {
      x: 4.60051199093697e-05,
      y: 19.7928276834657,
    },
    {
      x: 5.52061438912436e-05,
      y: 18.4604763436127,
    },
    {
      x: 6.62473726694923e-05,
      y: 17.2961860735238,
    },
    {
      x: 7.94968472033908e-05,
      y: 16.2700448942961,
    },
    {
      x: 9.53962166440689e-05,
      y: 15.3588417035872,
    },
    {
      x: 0.000114475459973,
      y: 14.5442893790611,
    },
    {
      x: 0.000137370551967,
      y: 13.8117848285813,
    },
    {
      x: 0.000164844662361,
      y: 13.1495257653351,
    },
    {
      x: 0.000197813594833,
      y: 12.5478699543837,
    },
    {
      x: 0.0002373763138,
      y: 11.998862668629,
    },
    {
      x: 0.00028485157656,
      y: 11.4958829960657,
    },
    {
      x: 0.000341821891872,
      y: 11.0333755265757,
    },
    {
      x: 0.000410186270246,
      y: 10.6066443030529,
    },
    {
      x: 0.000492223524295,
      y: 10.2116928074333,
    },
    {
      x: 0.000590668229154,
      y: 9.84509841322813,
    },
    {
      x: 0.000708801874985,
      y: 9.50391294341706,
    },
    {
      x: 0.000850562249982,
      y: 9.18558321298,
    },
    {
      x: 0.001020674699979,
      y: 8.8878870222843,
    },
    {
      x: 0.001224809639974,
      y: 8.60888120612726,
    },
    {
      x: 0.001469771567969,
      y: 8.34685916993173,
    },
    {
      x: 0.001763725881563,
      y: 8.10031595152435,
    },
    {
      x: 0.002116471057875,
      y: 7.86791929714267,
    },
    {
      x: 0.002539765269451,
      y: 7.64848557752717,
    },
    {
      x: 0.003047718323341,
      y: 7.44095962481748,
    },
    {
      x: 0.003657261988009,
      y: 7.24439776524393,
    },
    {
      x: 0.004388714385611,
      y: 7.05795347187768,
    },
    {
      x: 0.005266457262733,
      y: 6.88086517726761,
    },
    {
      x: 0.006319748715279,
      y: 6.71244587589906,
    },
    {
      x: 0.007583698458335,
      y: 6.55207421714145,
    },
    {
      x: 0.009100438150002,
      y: 6.39918684522923,
    },
    {
      x: 0.010920525780003,
      y: 6.25327178723104,
    },
    {
      x: 0.013104630936003,
      y: 6.11386272546191,
    },
    {
      x: 0.015725557123204,
      y: 5.98053401932524,
    },
    {
      x: 0.018870668547844,
      y: 5.85289636462336,
    },
    {
      x: 0.022644802257413,
      y: 5.7305929970919,
    },
    {
      x: 0.027173762708896,
      y: 5.61329636218197,
    },
    {
      x: 0.032608515250675,
      y: 5.50070518562608,
    },
    {
      x: 0.03913021830081,
      y: 5.39254188962231,
    },
    {
      x: 0.046956261960972,
      y: 5.28855030798152,
    },
    {
      x: 0.056347514353167,
      y: 5.18849366064384,
    },
    {
      x: 0.0676170172238,
      y: 5.09215275385264,
    },
    {
      x: 0.08114042066856,
      y: 4.99932437719002,
    },
    {
      x: 0.097368504802272,
      y: 4.90981987280269,
    },
    {
      x: 0.116842205762726,
      y: 4.82346385561824,
    },
    {
      x: 0.140210646915272,
      y: 4.74009306628331,
    },
    {
      x: 0.168252776298326,
      y: 4.65955534103848,
    },
    {
      x: 0.201903331557991,
      y: 4.58170868485416,
    },
    {
      x: 0.242283997869589,
      y: 4.50642043594988,
    },
    {
      x: 0.290740797443507,
      y: 4.43356651135532,
    },
  ],
  [
    {
      x: 0,
      y: 75,
    },
    {
      x: 1.06993205379072e-05,
      y: 70.2513498007752,
    },
    {
      x: 1.28391846454886e-05,
      y: 60.0039985646357,
    },
    {
      x: 1.54070215745864e-05,
      y: 52.3655889885275,
    },
    {
      x: 1.84884258895036e-05,
      y: 46.4522895158617,
    },
    {
      x: 2.21861110674044e-05,
      y: 41.7389824101303,
    },
    {
      x: 2.66233332808852e-05,
      y: 37.8940425409715,
    },
    {
      x: 3.19479999370623e-05,
      y: 34.6977332353201,
    },
    {
      x: 3.83375999244747e-05,
      y: 31.9986887482094,
    },
    {
      x: 4.60051199093697e-05,
      y: 29.6892415251985,
    },
    {
      x: 5.52061438912436e-05,
      y: 27.6907145154191,
    },
    {
      x: 6.62473726694923e-05,
      y: 25.9442791102857,
    },
    {
      x: 7.94968472033908e-05,
      y: 24.4050673414442,
    },
    {
      x: 9.53962166440689e-05,
      y: 23.0382625553808,
    },
    {
      x: 0.000114475459973,
      y: 21.8164340685916,
    },
    {
      x: 0.000137370551967,
      y: 20.7176772428719,
    },
    {
      x: 0.000164844662361,
      y: 19.7242886480026,
    },
    {
      x: 0.000197813594833,
      y: 18.8218049315756,
    },
    {
      x: 0.0002373763138,
      y: 17.9982940029435,
    },
    {
      x: 0.00028485157656,
      y: 17.2438244940985,
    },
    {
      x: 0.000341821891872,
      y: 16.5500632898635,
    },
    {
      x: 0.000410186270246,
      y: 15.9099664545794,
    },
    {
      x: 0.000492223524295,
      y: 15.3175392111499,
    },
    {
      x: 0.000590668229154,
      y: 14.7676476198422,
    },
    {
      x: 0.000708801874985,
      y: 14.2558694151256,
    },
    {
      x: 0.000850562249982,
      y: 13.77837481947,
    },
    {
      x: 0.001020674699979,
      y: 13.3318305334264,
    },
    {
      x: 0.001224809639974,
      y: 12.9133218091909,
    },
    {
      x: 0.001469771567969,
      y: 12.5202887548976,
    },
    {
      x: 0.001763725881563,
      y: 12.1504739272865,
    },
    {
      x: 0.002116471057875,
      y: 11.801878945714,
    },
    {
      x: 0.002539765269451,
      y: 11.4727283662908,
    },
    {
      x: 0.003047718323341,
      y: 11.1614394372262,
    },
    {
      x: 0.003657261988009,
      y: 10.8665966478659,
    },
    {
      x: 0.004388714385611,
      y: 10.5869302078165,
    },
    {
      x: 0.005266457262733,
      y: 10.3212977659014,
    },
    {
      x: 0.006319748715279,
      y: 10.0686688138486,
    },
    {
      x: 0.007583698458335,
      y: 9.82811132571218,
    },
    {
      x: 0.009100438150002,
      y: 9.59878026784384,
    },
    {
      x: 0.010920525780003,
      y: 9.37990768084656,
    },
    {
      x: 0.013104630936003,
      y: 9.17079408819287,
    },
    {
      x: 0.015725557123204,
      y: 8.97080102898787,
    },
    {
      x: 0.018870668547844,
      y: 8.77934454693503,
    },
    {
      x: 0.022644802257413,
      y: 8.59588949563786,
    },
    {
      x: 0.027173762708896,
      y: 8.41994454327296,
    },
    {
      x: 0.032608515250675,
      y: 8.25105777843911,
    },
    {
      x: 0.03913021830081,
      y: 8.08881283443347,
    },
    {
      x: 0.046956261960972,
      y: 7.93282546197228,
    },
    {
      x: 0.056347514353167,
      y: 7.78274049096576,
    },
    {
      x: 0.0676170172238,
      y: 7.63822913077895,
    },
    {
      x: 0.08114042066856,
      y: 7.49898656578503,
    },
    {
      x: 0.097368504802272,
      y: 7.36472980920404,
    },
    {
      x: 0.116842205762726,
      y: 7.23519578342736,
    },
    {
      x: 0.140210646915272,
      y: 7.11013959942497,
    },
    {
      x: 0.168252776298326,
      y: 6.98933301155772,
    },
    {
      x: 0.201903331557991,
      y: 6.87256302728124,
    },
    {
      x: 0.242283997869589,
      y: 6.75963065392481,
    },
    {
      x: 0.290740797443507,
      y: 6.65034976703297,
    },
  ],
  [
    {
      x: 0,
      y: 113,
    },
    {
      x: 1.06993205379072e-05,
      y: 105.377024701163,
    },
    {
      x: 1.28391846454886e-05,
      y: 90.0059978469535,
    },
    {
      x: 1.54070215745864e-05,
      y: 78.5483834827913,
    },
    {
      x: 1.84884258895036e-05,
      y: 69.6784342737925,
    },
    {
      x: 2.21861110674044e-05,
      y: 62.6084736151955,
    },
    {
      x: 2.66233332808852e-05,
      y: 56.8410638114572,
    },
    {
      x: 3.19479999370623e-05,
      y: 52.0465998529801,
    },
    {
      x: 3.83375999244747e-05,
      y: 47.9980331223141,
    },
    {
      x: 4.60051199093697e-05,
      y: 44.5338622877978,
    },
    {
      x: 5.52061438912436e-05,
      y: 41.5360717731286,
    },
    {
      x: 6.62473726694923e-05,
      y: 38.9164186654285,
    },
    {
      x: 7.94968472033908e-05,
      y: 36.6076010121663,
    },
    {
      x: 9.53962166440689e-05,
      y: 34.5573938330713,
    },
    {
      x: 0.000114475459973,
      y: 32.7246511028874,
    },
    {
      x: 0.000137370551967,
      y: 31.0765158643079,
    },
    {
      x: 0.000164844662361,
      y: 29.5864329720039,
    },
    {
      x: 0.000197813594833,
      y: 28.2327073973633,
    },
    {
      x: 0.0002373763138,
      y: 26.9974410044153,
    },
    {
      x: 0.00028485157656,
      y: 25.8657367411478,
    },
    {
      x: 0.000341821891872,
      y: 24.8250949347953,
    },
    {
      x: 0.000410186270246,
      y: 23.8649496818691,
    },
    {
      x: 0.000492223524295,
      y: 22.9763088167249,
    },
    {
      x: 0.000590668229154,
      y: 22.1514714297633,
    },
    {
      x: 0.000708801874985,
      y: 21.3838041226884,
    },
    {
      x: 0.000850562249982,
      y: 20.667562229205,
    },
    {
      x: 0.001020674699979,
      y: 19.9977458001397,
    },
    {
      x: 0.001224809639974,
      y: 19.3699827137863,
    },
    {
      x: 0.001469771567969,
      y: 18.7804331323464,
    },
    {
      x: 0.001763725881563,
      y: 18.2257108909298,
    },
    {
      x: 0.002116471057875,
      y: 17.702818418571,
    },
    {
      x: 0.002539765269451,
      y: 17.2090925494361,
    },
    {
      x: 0.003047718323341,
      y: 16.7421591558393,
    },
    {
      x: 0.003657261988009,
      y: 16.2998949717988,
    },
    {
      x: 0.004388714385611,
      y: 15.8803953117248,
    },
    {
      x: 0.005266457262733,
      y: 15.4819466488521,
    },
    {
      x: 0.006319748715279,
      y: 15.1030032207729,
    },
    {
      x: 0.007583698458335,
      y: 14.7421669885683,
    },
    {
      x: 0.009100438150002,
      y: 14.3981704017658,
    },
    {
      x: 0.010920525780003,
      y: 14.0698615212698,
    },
    {
      x: 0.013104630936003,
      y: 13.7561911322893,
    },
    {
      x: 0.015725557123204,
      y: 13.4562015434818,
    },
    {
      x: 0.018870668547844,
      y: 13.1690168204025,
    },
    {
      x: 0.022644802257413,
      y: 12.8938342434568,
    },
    {
      x: 0.027173762708896,
      y: 12.6299168149094,
    },
    {
      x: 0.032608515250675,
      y: 12.3765866676587,
    },
    {
      x: 0.03913021830081,
      y: 12.1332192516502,
    },
    {
      x: 0.046956261960972,
      y: 11.8992381929584,
    },
    {
      x: 0.056347514353167,
      y: 11.6741107364486,
    },
    {
      x: 0.0676170172238,
      y: 11.4573436961684,
    },
    {
      x: 0.08114042066856,
      y: 11.2484798486775,
    },
    {
      x: 0.097368504802272,
      y: 11.0470947138061,
    },
    {
      x: 0.116842205762726,
      y: 10.852793675141,
    },
    {
      x: 0.140210646915272,
      y: 10.6652093991375,
    },
    {
      x: 0.168252776298326,
      y: 10.4839995173366,
    },
    {
      x: 0.201903331557991,
      y: 10.3088445409219,
    },
    {
      x: 0.242283997869589,
      y: 10.1394459808872,
    },
    {
      x: 0.290740797443507,
      y: 9.97552465054946,
    },
  ],
  [
    {
      x: 0,
      y: 169,
    },
    {
      x: 1.06993205379072e-05,
      y: 158.065537051744,
    },
    {
      x: 1.28391846454886e-05,
      y: 135.00899677043,
    },
    {
      x: 1.54070215745864e-05,
      y: 117.822575224187,
    },
    {
      x: 1.84884258895036e-05,
      y: 104.517651410689,
    },
    {
      x: 2.21861110674044e-05,
      y: 93.9127104227932,
    },
    {
      x: 2.66233332808852e-05,
      y: 85.2615957171858,
    },
    {
      x: 3.19479999370623e-05,
      y: 78.0698997794702,
    },
    {
      x: 3.83375999244747e-05,
      y: 71.9970496834712,
    },
    {
      x: 4.60051199093697e-05,
      y: 66.8007934316967,
    },
    {
      x: 5.52061438912436e-05,
      y: 62.3041076596929,
    },
    {
      x: 6.62473726694923e-05,
      y: 58.3746279981428,
    },
    {
      x: 7.94968472033908e-05,
      y: 54.9114015182495,
    },
    {
      x: 9.53962166440689e-05,
      y: 51.8360907496069,
    },
    {
      x: 0.000114475459973,
      y: 49.0869766543311,
    },
    {
      x: 0.000137370551967,
      y: 46.6147737964618,
    },
    {
      x: 0.000164844662361,
      y: 44.3796494580058,
    },
    {
      x: 0.000197813594833,
      y: 42.349061096045,
    },
    {
      x: 0.0002373763138,
      y: 40.496161506623,
    },
    {
      x: 0.00028485157656,
      y: 38.7986051117217,
    },
    {
      x: 0.000341821891872,
      y: 37.2376424021929,
    },
    {
      x: 0.000410186270246,
      y: 35.7974245228037,
    },
    {
      x: 0.000492223524295,
      y: 34.4644632250873,
    },
    {
      x: 0.000590668229154,
      y: 33.2272071446449,
    },
    {
      x: 0.000708801874985,
      y: 32.0757061840326,
    },
    {
      x: 0.000850562249982,
      y: 31.0013433438075,
    },
    {
      x: 0.001020674699979,
      y: 29.9966187002095,
    },
    {
      x: 0.001224809639974,
      y: 29.0549740706795,
    },
    {
      x: 0.001469771567969,
      y: 28.1706496985196,
    },
    {
      x: 0.001763725881563,
      y: 27.3385663363947,
    },
    {
      x: 0.002116471057875,
      y: 26.5542276278565,
    },
    {
      x: 0.002539765269451,
      y: 25.8136388241542,
    },
    {
      x: 0.003047718323341,
      y: 25.113238733759,
    },
    {
      x: 0.003657261988009,
      y: 24.4498424576983,
    },
    {
      x: 0.004388714385611,
      y: 23.8205929675872,
    },
    {
      x: 0.005266457262733,
      y: 23.2229199732782,
    },
    {
      x: 0.006319748715279,
      y: 22.6545048311593,
    },
    {
      x: 0.007583698458335,
      y: 22.1132504828524,
    },
    {
      x: 0.009100438150002,
      y: 21.5972556026486,
    },
    {
      x: 0.010920525780003,
      y: 21.1047922819048,
    },
    {
      x: 0.013104630936003,
      y: 20.634286698434,
    },
    {
      x: 0.015725557123204,
      y: 20.1843023152227,
    },
    {
      x: 0.018870668547844,
      y: 19.7535252306038,
    },
    {
      x: 0.022644802257413,
      y: 19.3407513651852,
    },
    {
      x: 0.027173762708896,
      y: 18.9448752223642,
    },
    {
      x: 0.032608515250675,
      y: 18.564880001488,
    },
    {
      x: 0.03913021830081,
      y: 18.1998288774753,
    },
    {
      x: 0.046956261960972,
      y: 17.8488572894376,
    },
    {
      x: 0.056347514353167,
      y: 17.5111661046729,
    },
    {
      x: 0.0676170172238,
      y: 17.1860155442526,
    },
    {
      x: 0.08114042066856,
      y: 16.8727197730163,
    },
    {
      x: 0.097368504802272,
      y: 16.5706420707091,
    },
    {
      x: 0.116842205762726,
      y: 16.2791905127116,
    },
    {
      x: 0.140210646915272,
      y: 15.9978140987062,
    },
    {
      x: 0.168252776298326,
      y: 15.7259992760049,
    },
    {
      x: 0.201903331557991,
      y: 15.4632668113828,
    },
    {
      x: 0.242283997869589,
      y: 15.2091689713308,
    },
    {
      x: 0.290740797443507,
      y: 14.9632869758242,
    },
  ],
  [
    {
      x: 0,
      y: 253,
    },
    {
      x: 1.06993205379072e-05,
      y: 237.098305577616,
    },
    {
      x: 1.28391846454886e-05,
      y: 202.513495155645,
    },
    {
      x: 1.54070215745864e-05,
      y: 176.73386283628,
    },
    {
      x: 1.84884258895036e-05,
      y: 156.776477116033,
    },
    {
      x: 2.21861110674044e-05,
      y: 140.86906563419,
    },
    {
      x: 2.66233332808852e-05,
      y: 127.892393575779,
    },
    {
      x: 3.19479999370623e-05,
      y: 117.104849669205,
    },
    {
      x: 3.83375999244747e-05,
      y: 107.995574525207,
    },
    {
      x: 4.60051199093697e-05,
      y: 100.201190147545,
    },
    {
      x: 5.52061438912436e-05,
      y: 93.4561614895393,
    },
    {
      x: 6.62473726694923e-05,
      y: 87.5619419972142,
    },
    {
      x: 7.94968472033908e-05,
      y: 82.3671022773742,
    },
    {
      x: 9.53962166440689e-05,
      y: 77.7541361244104,
    },
    {
      x: 0.000114475459973,
      y: 73.6304649814966,
    },
    {
      x: 0.000137370551967,
      y: 69.9221606946927,
    },
    {
      x: 0.000164844662361,
      y: 66.5694741870087,
    },
    {
      x: 0.000197813594833,
      y: 63.5235916440675,
    },
    {
      x: 0.0002373763138,
      y: 60.7442422599345,
    },
    {
      x: 0.00028485157656,
      y: 58.1979076675825,
    },
    {
      x: 0.000341821891872,
      y: 55.8564636032894,
    },
    {
      x: 0.000410186270246,
      y: 53.6961367842055,
    },
    {
      x: 0.000492223524295,
      y: 51.696694837631,
    },
    {
      x: 0.000590668229154,
      y: 49.8408107169674,
    },
    {
      x: 0.000708801874985,
      y: 48.1135592760489,
    },
    {
      x: 0.000850562249982,
      y: 46.5020150157112,
    },
    {
      x: 0.001020674699979,
      y: 44.9949280503143,
    },
    {
      x: 0.001224809639974,
      y: 43.5824611060192,
    },
    {
      x: 0.001469771567969,
      y: 42.2559745477794,
    },
    {
      x: 0.001763725881563,
      y: 41.007849504592,
    },
    {
      x: 0.002116471057875,
      y: 39.8313414417848,
    },
    {
      x: 0.002539765269451,
      y: 38.7204582362313,
    },
    {
      x: 0.003047718323341,
      y: 37.6698581006385,
    },
    {
      x: 0.003657261988009,
      y: 36.6747636865474,
    },
    {
      x: 0.004388714385611,
      y: 35.7308894513808,
    },
    {
      x: 0.005266457262733,
      y: 34.8343799599173,
    },
    {
      x: 0.006319748715279,
      y: 33.981757246739,
    },
    {
      x: 0.007583698458335,
      y: 33.1698757242786,
    },
    {
      x: 0.009100438150002,
      y: 32.395883403973,
    },
    {
      x: 0.010920525780003,
      y: 31.6571884228571,
    },
    {
      x: 0.013104630936003,
      y: 30.9514300476509,
    },
    {
      x: 0.015725557123204,
      y: 30.276453472834,
    },
    {
      x: 0.018870668547844,
      y: 29.6302878459057,
    },
    {
      x: 0.022644802257413,
      y: 29.0111270477778,
    },
    {
      x: 0.027173762708896,
      y: 28.4173128335462,
    },
    {
      x: 0.032608515250675,
      y: 27.847320002232,
    },
    {
      x: 0.03913021830081,
      y: 27.299743316213,
    },
    {
      x: 0.046956261960972,
      y: 26.7732859341564,
    },
    {
      x: 0.056347514353167,
      y: 26.2667491570094,
    },
    {
      x: 0.0676170172238,
      y: 25.779023316379,
    },
    {
      x: 0.08114042066856,
      y: 25.3090796595245,
    },
    {
      x: 0.097368504802272,
      y: 24.8559631060636,
    },
    {
      x: 0.116842205762726,
      y: 24.4187857690673,
    },
    {
      x: 0.140210646915272,
      y: 23.9967211480593,
    },
    {
      x: 0.168252776298326,
      y: 23.5889989140073,
    },
    {
      x: 0.201903331557991,
      y: 23.1949002170742,
    },
    {
      x: 0.242283997869589,
      y: 22.8137534569962,
    },
    {
      x: 0.290740797443507,
      y: 22.4449304637363,
    },
  ],
  [
    {
      x: 0,
      y: 380,
    },
    {
      x: 1.06993205379072e-05,
      y: 355.647458366424,
    },
    {
      x: 1.28391846454886e-05,
      y: 303.770242733468,
    },
    {
      x: 1.54070215745864e-05,
      y: 265.100794254421,
    },
    {
      x: 1.84884258895036e-05,
      y: 235.16471567405,
    },
    {
      x: 2.21861110674044e-05,
      y: 211.303598451285,
    },
    {
      x: 2.66233332808852e-05,
      y: 191.838590363668,
    },
    {
      x: 3.19479999370623e-05,
      y: 175.657274503808,
    },
    {
      x: 3.83375999244747e-05,
      y: 161.99336178781,
    },
    {
      x: 4.60051199093697e-05,
      y: 150.301785221318,
    },
    {
      x: 5.52061438912436e-05,
      y: 140.184242234309,
    },
    {
      x: 6.62473726694923e-05,
      y: 131.342912995821,
    },
    {
      x: 7.94968472033908e-05,
      y: 123.550653416061,
    },
    {
      x: 9.53962166440689e-05,
      y: 116.631204186616,
    },
    {
      x: 0.000114475459973,
      y: 110.445697472245,
    },
    {
      x: 0.000137370551967,
      y: 104.883241042039,
    },
    {
      x: 0.000164844662361,
      y: 99.8542112805131,
    },
    {
      x: 0.000197813594833,
      y: 95.2853874661013,
    },
    {
      x: 0.0002373763138,
      y: 91.1163633899017,
    },
    {
      x: 0.00028485157656,
      y: 87.2968615013738,
    },
    {
      x: 0.000341821891872,
      y: 83.7846954049341,
    },
    {
      x: 0.000410186270246,
      y: 80.5442051763083,
    },
    {
      x: 0.000492223524295,
      y: 77.5450422564465,
    },
    {
      x: 0.000590668229154,
      y: 74.7612160754511,
    },
    {
      x: 0.000708801874985,
      y: 72.1703389140733,
    },
    {
      x: 0.000850562249982,
      y: 69.7530225235669,
    },
    {
      x: 0.001020674699979,
      y: 67.4923920754714,
    },
    {
      x: 0.001224809639974,
      y: 65.3736916590289,
    },
    {
      x: 0.001469771567969,
      y: 63.3839618216691,
    },
    {
      x: 0.001763725881563,
      y: 61.511774256888,
    },
    {
      x: 0.002116471057875,
      y: 59.7470121626772,
    },
    {
      x: 0.002539765269451,
      y: 58.0806873543469,
    },
    {
      x: 0.003047718323341,
      y: 56.5047871509577,
    },
    {
      x: 0.003657261988009,
      y: 55.0121455298211,
    },
    {
      x: 0.004388714385611,
      y: 53.5963341770711,
    },
    {
      x: 0.005266457262733,
      y: 52.2515699398759,
    },
    {
      x: 0.006319748715279,
      y: 50.9726358701085,
    },
    {
      x: 0.007583698458335,
      y: 49.7548135864179,
    },
    {
      x: 0.009100438150002,
      y: 48.5938251059594,
    },
    {
      x: 0.010920525780003,
      y: 47.4857826342857,
    },
    {
      x: 0.013104630936003,
      y: 46.4271450714764,
    },
    {
      x: 0.015725557123204,
      y: 45.4146802092511,
    },
    {
      x: 0.018870668547844,
      y: 44.4454317688586,
    },
    {
      x: 0.022644802257413,
      y: 43.5166905716666,
    },
    {
      x: 0.027173762708896,
      y: 42.6259692503193,
    },
    {
      x: 0.032608515250675,
      y: 41.770980003348,
    },
    {
      x: 0.03913021830081,
      y: 40.9496149743194,
    },
    {
      x: 0.046956261960972,
      y: 40.1599289012346,
    },
    {
      x: 0.056347514353167,
      y: 39.4001237355141,
    },
    {
      x: 0.0676170172238,
      y: 38.6685349745685,
    },
    {
      x: 0.08114042066856,
      y: 37.9636194892867,
    },
    {
      x: 0.097368504802272,
      y: 37.2839446590955,
    },
    {
      x: 0.116842205762726,
      y: 36.628178653601,
    },
    {
      x: 0.140210646915272,
      y: 35.9950817220889,
    },
    {
      x: 0.168252776298326,
      y: 35.383498371011,
    },
    {
      x: 0.201903331557991,
      y: 34.7923503256113,
    },
    {
      x: 0.242283997869589,
      y: 34.2206301854944,
    },
    {
      x: 0.290740797443507,
      y: 33.6673956956044,
    },
  ],
  [
    {
      x: 0,
      y: 570,
    },
    {
      x: 1.06993205379072e-05,
      y: 533.471187549636,
    },
    {
      x: 1.28391846454886e-05,
      y: 455.655364100202,
    },
    {
      x: 1.54070215745864e-05,
      y: 397.651191381631,
    },
    {
      x: 1.84884258895036e-05,
      y: 352.747073511075,
    },
    {
      x: 2.21861110674044e-05,
      y: 316.955397676927,
    },
    {
      x: 2.66233332808852e-05,
      y: 287.757885545502,
    },
    {
      x: 3.19479999370623e-05,
      y: 263.485911755712,
    },
    {
      x: 3.83375999244747e-05,
      y: 242.990042681715,
    },
    {
      x: 4.60051199093697e-05,
      y: 225.452677831976,
    },
    {
      x: 5.52061438912436e-05,
      y: 210.276363351463,
    },
    {
      x: 6.62473726694923e-05,
      y: 197.014369493732,
    },
    {
      x: 7.94968472033908e-05,
      y: 185.325980124092,
    },
    {
      x: 9.53962166440689e-05,
      y: 174.946806279923,
    },
    {
      x: 0.000114475459973,
      y: 165.668546208367,
    },
    {
      x: 0.000137370551967,
      y: 157.324861563059,
    },
    {
      x: 0.000164844662361,
      y: 149.78131692077,
    },
    {
      x: 0.000197813594833,
      y: 142.928081199152,
    },
    {
      x: 0.0002373763138,
      y: 136.674545084853,
    },
    {
      x: 0.00028485157656,
      y: 130.945292252061,
    },
    {
      x: 0.000341821891872,
      y: 125.677043107401,
    },
    {
      x: 0.000410186270246,
      y: 120.816307764462,
    },
    {
      x: 0.000492223524295,
      y: 116.31756338467,
    },
    {
      x: 0.000590668229154,
      y: 112.141824113177,
    },
    {
      x: 0.000708801874985,
      y: 108.25550837111,
    },
    {
      x: 0.000850562249982,
      y: 104.62953378535,
    },
    {
      x: 0.001020674699979,
      y: 101.238588113207,
    },
    {
      x: 0.001224809639974,
      y: 98.0605374885433,
    },
    {
      x: 0.001469771567969,
      y: 95.0759427325037,
    },
    {
      x: 0.001763725881563,
      y: 92.267661385332,
    },
    {
      x: 0.002116471057875,
      y: 89.6205182440158,
    },
    {
      x: 0.002539765269451,
      y: 87.1210310315204,
    },
    {
      x: 0.003047718323341,
      y: 84.7571807264366,
    },
    {
      x: 0.003657261988009,
      y: 82.5182182947316,
    },
    {
      x: 0.004388714385611,
      y: 80.3945012656067,
    },
    {
      x: 0.005266457262733,
      y: 78.3773549098139,
    },
    {
      x: 0.006319748715279,
      y: 76.4589538051627,
    },
    {
      x: 0.007583698458335,
      y: 74.6322203796268,
    },
    {
      x: 0.009100438150002,
      y: 72.8907376589392,
    },
    {
      x: 0.010920525780003,
      y: 71.2286739514286,
    },
    {
      x: 0.013104630936003,
      y: 69.6407176072146,
    },
    {
      x: 0.015725557123204,
      y: 68.1220203138766,
    },
    {
      x: 0.018870668547844,
      y: 66.6681476532879,
    },
    {
      x: 0.022644802257413,
      y: 65.2750358575,
    },
    {
      x: 0.027173762708896,
      y: 63.938953875479,
    },
    {
      x: 0.032608515250675,
      y: 62.656470005022,
    },
    {
      x: 0.03913021830081,
      y: 61.4244224614792,
    },
    {
      x: 0.046956261960972,
      y: 60.239893351852,
    },
    {
      x: 0.056347514353167,
      y: 59.1001856032712,
    },
    {
      x: 0.0676170172238,
      y: 58.0028024618527,
    },
    {
      x: 0.08114042066856,
      y: 56.9454292339301,
    },
    {
      x: 0.097368504802272,
      y: 55.9259169886432,
    },
    {
      x: 0.116842205762726,
      y: 54.9422679804015,
    },
    {
      x: 0.140210646915272,
      y: 53.9926225831334,
    },
    {
      x: 0.168252776298326,
      y: 53.0752475565165,
    },
    {
      x: 0.201903331557991,
      y: 52.1885254884169,
    },
    {
      x: 0.242283997869589,
      y: 51.3309452782416,
    },
    {
      x: 0.290740797443507,
      y: 50.5010935434066,
    },
  ],
  [
    {
      x: 0,
      y: 854,
    },
    {
      x: 1.06993205379072e-05,
      y: 800.206781324455,
    },
    {
      x: 1.28391846454886e-05,
      y: 683.483046150303,
    },
    {
      x: 1.54070215745864e-05,
      y: 596.476787072447,
    },
    {
      x: 1.84884258895036e-05,
      y: 529.120610266612,
    },
    {
      x: 2.21861110674044e-05,
      y: 475.433096515391,
    },
    {
      x: 2.66233332808852e-05,
      y: 431.636828318253,
    },
    {
      x: 3.19479999370623e-05,
      y: 395.228867633568,
    },
    {
      x: 3.83375999244747e-05,
      y: 364.485064022573,
    },
    {
      x: 4.60051199093697e-05,
      y: 338.179016747964,
    },
    {
      x: 5.52061438912436e-05,
      y: 315.414545027195,
    },
    {
      x: 6.62473726694923e-05,
      y: 295.521554240598,
    },
    {
      x: 7.94968472033908e-05,
      y: 277.988970186138,
    },
    {
      x: 9.53962166440689e-05,
      y: 262.420209419885,
    },
    {
      x: 0.000114475459973,
      y: 248.502819312551,
    },
    {
      x: 0.000137370551967,
      y: 235.987292344588,
    },
    {
      x: 0.000164844662361,
      y: 224.671975381154,
    },
    {
      x: 0.000197813594833,
      y: 214.392121798728,
    },
    {
      x: 0.0002373763138,
      y: 205.011817627279,
    },
    {
      x: 0.00028485157656,
      y: 196.417938378091,
    },
    {
      x: 0.000341821891872,
      y: 188.515564661102,
    },
    {
      x: 0.000410186270246,
      y: 181.224461646694,
    },
    {
      x: 0.000492223524295,
      y: 174.476345077005,
    },
    {
      x: 0.000590668229154,
      y: 168.212736169765,
    },
    {
      x: 0.000708801874985,
      y: 162.383262556665,
    },
    {
      x: 0.000850562249982,
      y: 156.944300678025,
    },
    {
      x: 0.001020674699979,
      y: 151.857882169811,
    },
    {
      x: 0.001224809639974,
      y: 147.090806232815,
    },
    {
      x: 0.001469771567969,
      y: 142.613914098755,
    },
    {
      x: 0.001763725881563,
      y: 138.401492077998,
    },
    {
      x: 0.002116471057875,
      y: 134.430777366024,
    },
    {
      x: 0.002539765269451,
      y: 130.681546547281,
    },
    {
      x: 0.003047718323341,
      y: 127.135771089655,
    },
    {
      x: 0.003657261988009,
      y: 123.777327442097,
    },
    {
      x: 0.004388714385611,
      y: 120.59175189841,
    },
    {
      x: 0.005266457262733,
      y: 117.566032364721,
    },
    {
      x: 0.006319748715279,
      y: 114.688430707744,
    },
    {
      x: 0.007583698458335,
      y: 111.94833056944,
    },
    {
      x: 0.009100438150002,
      y: 109.336106488409,
    },
    {
      x: 0.010920525780003,
      y: 106.843010927143,
    },
    {
      x: 0.013104630936003,
      y: 104.461076410822,
    },
    {
      x: 0.015725557123204,
      y: 102.183030470815,
    },
    {
      x: 0.018870668547844,
      y: 100.002221479932,
    },
    {
      x: 0.022644802257413,
      y: 97.9125537862499,
    },
    {
      x: 0.027173762708896,
      y: 95.9084308132185,
    },
    {
      x: 0.032608515250675,
      y: 93.984705007533,
    },
    {
      x: 0.03913021830081,
      y: 92.1366336922188,
    },
    {
      x: 0.046956261960972,
      y: 90.3598400277779,
    },
    {
      x: 0.056347514353167,
      y: 88.6502784049068,
    },
    {
      x: 0.0676170172238,
      y: 87.004203692779,
    },
    {
      x: 0.08114042066856,
      y: 85.4181438508951,
    },
    {
      x: 0.097368504802272,
      y: 83.8888754829648,
    },
    {
      x: 0.116842205762726,
      y: 82.4134019706023,
    },
    {
      x: 0.140210646915272,
      y: 80.9889338747,
    },
    {
      x: 0.168252776298326,
      y: 79.6128713347747,
    },
    {
      x: 0.201903331557991,
      y: 78.2827882326254,
    },
    {
      x: 0.242283997869589,
      y: 76.9964179173623,
    },
    {
      x: 0.290740797443507,
      y: 75.75164031511,
    },
  ],
];

export default CAHauling;
