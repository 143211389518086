import WFHazAPI from '.';

/**
 * @typedef Comment
 * @type {object}
 * @property {string} id
 * @property {string} user_id
 * @property {string} pin_id
 * @property {string} body
 * @property {string} created_at
 */

export default class WFHazComments extends WFHazAPI {
  constructor() {
    super();
    this.endpoint = 'comments';
    this.parentEndpoint = 'pins';
  }

  /**
   * Fetch a single or a list of comments for a given pin
   * @param {Object} params
   * @param {string} params.pin_id
   * @param {string} params.id - (optional) the id of the comment
   * @returns {Promise<Comment> | Promise<Array<Comment>>}
   *
   * @example
   * // Get all comments corresponding to a pin
   * const pin_id = 21
   * const comments = await Api.Comments.get({ pin_id })
   * {
   *    id: "15",
   *    user_id: "7",
   *    project_id: "1",
   *    geom: "0101000020110F00007A7F4B10A48169C175EAE652069E5141",
   *    created_at: "2022-12-03T00:49:25.786Z",
   *    x: -13372704.509216059,
   *    y: 4618265.295343985
   * }
   *
   * // Get a specific comment
   * const pin_id = 21
   * const id = 6
   * const comments = await Api.Comments.get({ pin_id, id })
   * {
   *    id: "15",
   *    user_id: "7",
   *    project_id: "1",
   *    geom: "0101000020110F00007A7F4B10A48169C175EAE652069E5141",
   *    created_at: "2022-12-03T00:49:25.786Z",
   *    x: -13372704.509216059,
   *    y: 4618265.295343985
   * }
   */
  async get(params = {}) {
    if (!params.pin_id) throw Error('Missing required param: pin_id');

    if (params.id) {
      return super.get(
        `${this.parentEndpoint}/${params.pin_id}/${this.endpoint}/${params.id}`,
      );
    }

    return super.get(`${this.parentEndpoint}/${params.pin_id}/${this.endpoint}`);
  }

  /**
   * Create a comment
   * @param {object} params
   * @param {string} params.pin_id - the pin id to associate with this comment
   * @param {string} params.body - the text body of the comment
   * @returns {Promise<Comment>}
   */
  async post(params = {}) {
    const { body } = params;
    if (!params.pin_id) throw new Error('Missing required param: pin_id (string)');
    if (body === undefined) throw new Error('Missing required param: body (string)');

    return super.post(`${this.parentEndpoint}/${params.pin_id}/${this.endpoint}/`, { body });
  }

  /**
   * Update a comment
   * @param {object} params
   * @param {string} params.id
   * @param {string} params.pin_id
   * @param {string} params.body
   * @returns {Promise<Comment>}
   */
  async put(params = {}) {
    const { id, pin_id: pinId, body } = params;
    if (!id) throw new Error('Missing required param: id (string)');
    if (!pinId) throw new Error('Missing required param: pin_id (string)');
    if (!body) throw new Error('Missing required param body (nothing to update)');

    return super.put(`${this.parentEndpoint}/${pinId}/${this.endpoint}/${id}`, { body });
  }

  /**
   * delete a comment
   * @param {object} params
   * @param {string} params.id
   * @param {string} params.pin_id
   * @returns {Promise<Comment>}
   */
  async delete(params = {}) {
    const { id, pin_id: pinId } = params;
    if (!id) throw new Error('Missing required param: id (string)');
    if (!pinId) throw new Error('Missing required param: pin_id (string)');

    return super.delete(`${this.parentEndpoint}/${pinId}/${this.endpoint}/${params.id}`);
  }
}
