import { UnknownString } from '../constants';

export const formatEvt = (val, code) => {
  if (code === false || code === undefined) return UnknownString;
  if (val === false || val === undefined) return UnknownString;
  if (val && code >= 0 && code <= 10000) {
    return `${code} - ${val}`;
  }

  return UnknownString;
};

export default {
  formatEvt,
};
