import React from 'react';
// import OpacityIcon from '@material-ui/icons/Opacity';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import Switch from '@material-ui/core/Switch';
// import Slider from '@material-ui/core/Slider';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import themes from '../../../styles/themes';
import Legend from '../../legend';

const ListItemNoHover = styled.li`
  list-style-type: none;
  width: 300px;
  // padding-left: 40px;
  display: inline-block;
  // padding-top: 6px;
  // padding-bottom: 6px;
`;

const ListItemHover = styled.li`
  list-style-type: none;
  width: 300px;
  // padding-left: 40px;
  display: inline-block;
  // padding-top: 6px;
  // padding-bottom: 6px;
  &:hover {
    background-color: ${themes.hoverMainDark};
  }
`;

const buttonStyles = {
  paddingRight: '0px',
  maxWidth: '100%',
  minWidth: '100%',
  justifyContent: 'left',
  color: themes.white,
  fontFamily: themes.fontFamily,
};

const ListItemContainer = styled.div`
`;

export default function ListItem(props) {
  const {
    layer,
    // mapLayer,
    attribute,
    selectedPixel,
    handleToolsVisible,
    // handleSwitch,
    isBase,
  } = props;

  // const handleSlider = (_e, v) => {
  //   mapLayer.setOpacity(v / 100);
  // };

  // console.log('props', props);

  return (
    <ListItemContainer>
      <ListItemHover>
        <Button
          onClick={() => {
            handleToolsVisible(isBase, attribute);
            // Remove this to stop showing layers by clicking headers
            // handleSwitch(isBase, attribute);
          }}
          style={{
            ...buttonStyles,
            color: attribute.selected ? themes.greenish : themes.white,
            paddingLeft: '40px',
          }}
        >
          {layer.name}
        </Button>
      </ListItemHover>
      {
          attribute.toolsVisible && !isBase && (
            <div style={{ paddingLeft: '60px' }}>
              {/* <ListItemNoHover>
                <OpacityIcon style={{ color: themes.white }} />
                <Slider
                  disabled={!attribute.selected}
                  defaultValue={mapLayer.getOpacity() * 100}
                  style={{ marginLeft: '15px', width: '40%', color: themes.white }}
                  onChange={(e, v) => { handleSlider(e, v); }}
                />
              </ListItemNoHover> */}
              {/* Re add this to allow multiple hazard layers */}
              {/* <ListItemNoHover>
                <VisibilityIcon
                  style={{ verticalAlign: 'middle', color: themes.white }}
                />
                <div
                  style={{ paddingLeft: '0px', display: 'inherit' }}
                >
                  <Switch
                    style={{ color: themes.white }}
                    checked={!attribute.selected}
                    onChange={() => { handleSwitch(isBase, attribute); }}
                  />
                </div>
              </ListItemNoHover> */}
              { layer.legendType && (
                <ListItemNoHover>
                  <Legend
                    selectedPixel={selectedPixel}
                    mapLayer={layer}
                    type={layer.legendType}
                  />
                </ListItemNoHover>
              )}
              {layer.hasHauling && (
                <ListItemNoHover>
                  <Legend
                    selectedPixel={selectedPixel}
                    mapLayer={layer}
                    type="Hauling"
                  />
                </ListItemNoHover>
              )}
            </div>
          )
        }
    </ListItemContainer>
  );
}
