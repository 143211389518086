// import React, {
//   useState, useEffect, useRef, useContext,
// } from 'react';
import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Styled from 'styled-components';
import AppCtx from '../../context/AppContext';

import Navbar from '../navbar';
import themes from '../../styles/themes';
import Home from '../home';
import Menu from '../menu';
import Map from '../map';
import Modal from './modal';
import ButtonBar from './buttonBar';
import { getLink, parseLink } from './helpers';
import toQueryString from '../../helpers/toQueryString';
import Banner from './Banner';
import LoginModal from './loginModal';

const LightContainer = Styled.div`
  background-color: ${themes.white};
  width: 100%;
  height: 100%;
`;

const DarkContainer = Styled.div`
  // background-color: ${themes.mainAndAhalfDark};
    background-color: ${themes.secondaryDark};
    position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  overflow: auto;
  @media (max-width: 768px) {
    background-color: ${themes.secondaryDark};
  };
`;

export default function Base() {
  const {
    state,
    setShowSidePanel,
    pinLoader,
    attributes,
    setAttributes,
    mapRef,
    bannerData,
    // setBannerData,
  } = useContext(AppCtx.Context);
  const { showSidePanel } = state;

  // This should be used in someone to decide what layers get rendered
  const states = [
    { abbreviation: 'ca', name: 'California' },
    { abbreviation: 'mt', name: 'Montana' },
    { abbreviation: 'co', name: 'Colorado' },
    { abbreviation: 'calfire', name: 'CalFire' },
  ];

  // const [attributes, setAttributes] = useState(false);
  const [baseAttributes, setBaseAttributes] = useState(false);
  const [referenceAttributes, setReferenceAttributes] = useState(false);

  const [selectedState, setSelectedState] = useState(false);
  const [selectedPixel, setSelectedPixel] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const [baseLayerIsShowing, setBaseLayerIsShowing] = useState(false);
  const [layersAreShowing, setLayersAreShowing] = useState(true);
  const [referenceLayersShowing, setReferenceLayersShowing] = useState(false);

  const [clearMarkIsShowing, setClearMarkIsShowing] = useState(false);

  const [linkObj, setLinkObj] = useState({});

  // const [banner, setBanner] = useState(false);

  // const mapRef = useRef();

  const handleDirectPageAccess = () => {
    // This might require some additional thought
    // console.log('handleDirectPageAccess');
    const { pathname } = window.location;
    const stateproj = states.find((s) => {
      const regex = new RegExp(`^/${s.abbreviation}$`);
      return regex.test(pathname);
    });
    if (stateproj) {
      // console.log('direct page access to state');
      const query = window.location.search;
      if (query) {
        const obj = parseLink(query);
        if (obj) {
          setLinkObj(obj);
        }
      }
      setSelectedState(stateproj);
    }
  };

  const handleClearMark = () => {
    mapRef.current.removePinLayer();
    mapRef.current.removePopup(mapRef.popup);
    mapRef.current.resetCoords();
    setSelectedPixel(false);
    setClearMarkIsShowing(false);
  };

  const handleGetLink = () => {
    const obj = getLink(mapRef, attributes, baseAttributes, referenceAttributes);
    const string = toQueryString(obj);
    return string;
  };

  useEffect(() => {
    if (!selectedState) {
      handleDirectPageAccess();
    }
  }, []);

  return (
    <Router>
      {showModal && <Modal showModal={showModal} setShowModal={setShowModal} />}
      {showLogin && <LoginModal setShowLogin={setShowLogin} />}
      {bannerData && <Banner text={bannerData.text} />}
      <Navbar
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        // menuIsShowing={menuIsShowing}
        menuIsShowing={showSidePanel}
        setShowModal={setShowModal}
        setShowLogin={setShowLogin}
        // setMenuIsShowing={setMenuIsShowing}
        setMenuIsShowing={(v) => setShowSidePanel(v)}
        setSelectedPixel={setSelectedPixel}
        setClearMarkIsShowing={setClearMarkIsShowing}
        setLinkObj={setLinkObj}
      />
      <Switch>
        <Route path="/ca">
          {selectedState && (
            <LightContainer>
              <Menu
                // menuIsShowing={menuIsShowing}
                menuIsShowing={showSidePanel}
                // setMenuIsShowing={(v) => setMenuIsShowing(v)}
                setMenuIsShowing={(v) => setShowSidePanel(v)}
                attributes={attributes}
                setAttributes={setAttributes}
                baseAttributes={baseAttributes}
                setBaseAttributes={setBaseAttributes}
                selectedPixel={selectedPixel}
                map={mapRef.current}
                baseLayerIsShowing={baseLayerIsShowing}
                setBaseLayerIsShowing={setBaseLayerIsShowing}
                layersAreShowing={layersAreShowing}
                setLayersAreShowing={setLayersAreShowing}
                referenceLayersShowing={referenceLayersShowing}
                setReferenceLayersShowing={setReferenceLayersShowing}
                referenceAttributes={referenceAttributes}
                setReferenceAttributes={setReferenceAttributes}
              />
              <Map
                attributes={attributes}
                setAttributes={setAttributes}
                setBaseAttributes={setBaseAttributes}
                setSelectedPixel={setSelectedPixel}
                ref={mapRef}
                selectedState={selectedState}
                referenceAttributes={referenceAttributes}
                setReferenceAttributes={setReferenceAttributes}
                setClearMarkIsShowing={setClearMarkIsShowing}
                linkObj={linkObj}
                baseAttributes={baseAttributes}
                pinLoader={pinLoader}
              />

              <ButtonBar
                handleClearMark={handleClearMark}
                clearMarkIsShowing={clearMarkIsShowing}
                handleGetLink={handleGetLink}
                setShowModal={setShowModal}
                setBaseAttributes={setBaseAttributes}
                baseAttributes={baseAttributes}
                map={mapRef.current}
              />
            </LightContainer>
          )}
        </Route>
        <Route path="/mt">
          {selectedState && (
            <LightContainer>
              <Menu
                // menuIsShowing={menuIsShowing}
                menuIsShowing={showSidePanel}
                // setMenuIsShowing={(v) => setMenuIsShowing(v)}
                setMenuIsShowing={(v) => setShowSidePanel(v)}
                attributes={attributes}
                setAttributes={setAttributes}
                baseAttributes={baseAttributes}
                setBaseAttributes={setBaseAttributes}
                selectedPixel={selectedPixel}
                map={mapRef.current}
                baseLayerIsShowing={baseLayerIsShowing}
                setBaseLayerIsShowing={setBaseLayerIsShowing}
                layersAreShowing={layersAreShowing}
                setLayersAreShowing={setLayersAreShowing}
                // layerProperties={layerProperties}
                // setLayerProperties={setLayerProperties}
                referenceLayersShowing={referenceLayersShowing}
                setReferenceLayersShowing={setReferenceLayersShowing}
                referenceAttributes={referenceAttributes}
                setReferenceAttributes={setReferenceAttributes}
              />
              <Map
                attributes={attributes}
                setAttributes={setAttributes}
                setBaseAttributes={setBaseAttributes}
                setSelectedPixel={setSelectedPixel}
                ref={mapRef}
                selectedState={selectedState}
                setReferenceAttributes={setReferenceAttributes}
                setClearMarkIsShowing={setClearMarkIsShowing}
                linkObj={linkObj}
                baseAttributes={baseAttributes}
                pinLoader={pinLoader}
              />
              <ButtonBar
                handleClearMark={handleClearMark}
                clearMarkIsShowing={clearMarkIsShowing}
                handleGetLink={handleGetLink}
                setShowModal={setShowModal}
                setBaseAttributes={setBaseAttributes}
                baseAttributes={baseAttributes}
                map={mapRef.current}
              />
            </LightContainer>
          )}
        </Route>
        <Route path="/co">
          {selectedState && (
            <LightContainer>
              <Menu
                // menuIsShowing={menuIsShowing}
                menuIsShowing={showSidePanel}
                // setMenuIsShowing={(v) => setMenuIsShowing(v)}
                setMenuIsShowing={(v) => setShowSidePanel(v)}
                attributes={attributes}
                setAttributes={setAttributes}
                baseAttributes={baseAttributes}
                setBaseAttributes={setBaseAttributes}
                selectedPixel={selectedPixel}
                map={mapRef.current}
                baseLayerIsShowing={baseLayerIsShowing}
                setBaseLayerIsShowing={setBaseLayerIsShowing}
                layersAreShowing={layersAreShowing}
                setLayersAreShowing={setLayersAreShowing}
                referenceLayersShowing={referenceLayersShowing}
                setReferenceLayersShowing={setReferenceLayersShowing}
                referenceAttributes={referenceAttributes}
                setReferenceAttributes={setReferenceAttributes}
              />
              <Map
                attributes={attributes}
                setAttributes={setAttributes}
                setBaseAttributes={setBaseAttributes}
                setSelectedPixel={setSelectedPixel}
                ref={mapRef}
                selectedState={selectedState}
                referenceAttributes={referenceAttributes}
                setReferenceAttributes={setReferenceAttributes}
                setClearMarkIsShowing={setClearMarkIsShowing}
                linkObj={linkObj}
                baseAttributes={baseAttributes}
                pinLoader={pinLoader}
              />
              <ButtonBar
                handleClearMark={handleClearMark}
                clearMarkIsShowing={clearMarkIsShowing}
                handleGetLink={handleGetLink}
                setShowModal={setShowModal}
                setBaseAttributes={setBaseAttributes}
                baseAttributes={baseAttributes}
                map={mapRef.current}
              />
            </LightContainer>
          )}
        </Route>
        <Route path="/calfire">
          {selectedState && (
            <LightContainer>
              <Menu
                // menuIsShowing={menuIsShowing}
                menuIsShowing={showSidePanel}
                // setMenuIsShowing={(v) => setMenuIsShowing(v)}
                setMenuIsShowing={(v) => setShowSidePanel(v)}
                attributes={attributes}
                setAttributes={setAttributes}
                baseAttributes={baseAttributes}
                setBaseAttributes={setBaseAttributes}
                selectedPixel={selectedPixel}
                map={mapRef.current}
                baseLayerIsShowing={baseLayerIsShowing}
                setBaseLayerIsShowing={setBaseLayerIsShowing}
                layersAreShowing={layersAreShowing}
                setLayersAreShowing={setLayersAreShowing}
                referenceLayersShowing={referenceLayersShowing}
                setReferenceLayersShowing={setReferenceLayersShowing}
                referenceAttributes={referenceAttributes}
                setReferenceAttributes={setReferenceAttributes}
              />
              <Map
                attributes={attributes}
                setAttributes={setAttributes}
                setBaseAttributes={setBaseAttributes}
                setSelectedPixel={setSelectedPixel}
                ref={mapRef}
                selectedState={selectedState}
                referenceAttributes={referenceAttributes}
                setReferenceAttributes={setReferenceAttributes}
                setClearMarkIsShowing={setClearMarkIsShowing}
                linkObj={linkObj}
                baseAttributes={baseAttributes}
                pinLoader={pinLoader}
                usePins
                useDialog
              />
              <ButtonBar
                handleClearMark={handleClearMark}
                clearMarkIsShowing={clearMarkIsShowing}
                handleGetLink={handleGetLink}
                setShowModal={setShowModal}
                setBaseAttributes={setBaseAttributes}
                baseAttributes={baseAttributes}
                map={mapRef.current}
              />
            </LightContainer>
          )}
        </Route>
        <Route path="/*">
          <DarkContainer>
            <Home states={states} setSelectedState={setSelectedState} setShowModal={setShowModal} />
          </DarkContainer>
        </Route>
      </Switch>
    </Router>
  );
}
