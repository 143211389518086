const mtRPSPercentiles = [
  {
    x: 7,
    y: 0,
  },
  {
    x: 8,
    y: 0.00133045330178,
  },
  {
    x: 9,
    y: 0.00253347819671,
  },
  {
    x: 10,
    y: 0.003169281361625,
  },
  {
    x: 11,
    y: 0.00406,
  },
  {
    x: 12,
    y: 0.00496,
  },
  {
    x: 13,
    y: 0.00578,
  },
  {
    x: 14,
    y: 0.00666,
  },
  {
    x: 15,
    y: 0.00753,
  },
  {
    x: 16,
    y: 0.00835,
  },
  {
    x: 17,
    y: 0.00919,
  },
  {
    x: 18,
    y: 0.01,
  },
  {
    x: 19,
    y: 0.010846228338778,
  },
  {
    x: 20,
    y: 0.011678260751069,
  },
  {
    x: 21,
    y: 0.01251724043861,
  },
  {
    x: 22,
    y: 0.01335371285677,
  },
  {
    x: 23,
    y: 0.014202886261046,
  },
  {
    x: 24,
    y: 0.015064495615661,
  },
  {
    x: 25,
    y: 0.015939317643642,
  },
  {
    x: 26,
    y: 0.016830697655678,
  },
  {
    x: 27,
    y: 0.017740100622177,
  },
  {
    x: 28,
    y: 0.018665079846978,
  },
  {
    x: 29,
    y: 0.019612591713667,
  },
  {
    x: 30,
    y: 0.02057970315218,
  },
  {
    x: 31,
    y: 0.021570537239313,
  },
  {
    x: 32,
    y: 0.022592840716243,
  },
  {
    x: 33,
    y: 0.023653171956539,
  },
  {
    x: 34,
    y: 0.024758793413639,
  },
  {
    x: 35,
    y: 0.025911536067724,
  },
  {
    x: 36,
    y: 0.027100903913379,
  },
  {
    x: 37,
    y: 0.028333662077785,
  },
  {
    x: 38,
    y: 0.029615568444133,
  },
  {
    x: 39,
    y: 0.030945971608162,
  },
  {
    x: 40,
    y: 0.032327465713024,
  },
  {
    x: 41,
    y: 0.033768658265472,
  },
  {
    x: 42,
    y: 0.035266902297735,
  },
  {
    x: 43,
    y: 0.03683291003108,
  },
  {
    x: 44,
    y: 0.038459938019514,
  },
  {
    x: 45,
    y: 0.040151737630367,
  },
  {
    x: 46,
    y: 0.041895244270563,
  },
  {
    x: 47,
    y: 0.043709699064493,
  },
  {
    x: 48,
    y: 0.045592080801725,
  },
  {
    x: 49,
    y: 0.047554362565279,
  },
  {
    x: 50,
    y: 0.049593754112721,
  },
  {
    x: 51,
    y: 0.051720630377531,
  },
  {
    x: 52,
    y: 0.053948327898979,
  },
  {
    x: 53,
    y: 0.05626817792654,
  },
  {
    x: 54,
    y: 0.058709673583508,
  },
  {
    x: 55,
    y: 0.061275955289602,
  },
  {
    x: 56,
    y: 0.063953146338463,
  },
  {
    x: 57,
    y: 0.06677620112896,
  },
  {
    x: 58,
    y: 0.06975793838501,
  },
  {
    x: 59,
    y: 0.072905427366495,
  },
  {
    x: 60,
    y: 0.076225109398365,
  },
  {
    x: 61,
    y: 0.079716429114342,
  },
  {
    x: 62,
    y: 0.083397828042507,
  },
  {
    x: 63,
    y: 0.087278157472611,
  },
  {
    x: 64,
    y: 0.091362863779068,
  },
  {
    x: 65,
    y: 0.095664687454701,
  },
  {
    x: 66,
    y: 0.100219701826572,
  },
  {
    x: 67,
    y: 0.105010626167059,
  },
  {
    x: 68,
    y: 0.110048107802868,
  },
  {
    x: 69,
    y: 0.115304656326771,
  },
  {
    x: 70,
    y: 0.120825034379959,
  },
  {
    x: 71,
    y: 0.126619547605515,
  },
  {
    x: 72,
    y: 0.132704519629478,
  },
  {
    x: 73,
    y: 0.13912607729435,
  },
  {
    x: 74,
    y: 0.145848274230957,
  },
  {
    x: 75,
    y: 0.152886986732483,
  },
  {
    x: 76,
    y: 0.160332083702087,
  },
  {
    x: 77,
    y: 0.168242529034615,
  },
  {
    x: 78,
    y: 0.176609203219414,
  },
  {
    x: 79,
    y: 0.185478925704956,
  },
  {
    x: 80,
    y: 0.194977879524231,
  },
  {
    x: 81,
    y: 0.205248981714249,
  },
  {
    x: 82,
    y: 0.216358363628387,
  },
  {
    x: 83,
    y: 0.228102520108223,
  },
  {
    x: 84,
    y: 0.240513384342194,
  },
  {
    x: 85,
    y: 0.253805726766586,
  },
  {
    x: 86,
    y: 0.268165946006775,
  },
  {
    x: 87,
    y: 0.283895403146744,
  },
  {
    x: 88,
    y: 0.301667869091034,
  },
  {
    x: 89,
    y: 0.322085678577423,
  },
  {
    x: 90,
    y: 0.346031099557877,
  },
  {
    x: 91,
    y: 0.374754935503006,
  },
  {
    x: 92,
    y: 0.409081101417542,
  },
  {
    x: 93,
    y: 0.449972003698349,
  },
  {
    x: 94,
    y: 0.500164985656738,
  },
  {
    x: 95,
    y: 0.563458323478699,
  },
  {
    x: 96,
    y: 0.646449387073517,
  },
  {
    x: 97,
    y: 0.760839641094208,
  },
  {
    x: 98,
    y: 0.93649160861969,
  },
  {
    x: 99,
    y: 1.28419769048691,
  },
  {
    x: 100,
    y: 6.67315149307251,
  },
];

export default mtRPSPercentiles;
