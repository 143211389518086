import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import themes from '../../../styles/themes';
import PDF from './PDF';

const ModalBox = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 60%;
  height: 45%;
  transform: translate(-50%, -50%);
  background-color: ${themes.white};
  z-index: 1006;
  box-shadow: ${themes.boxShadow};
  border-radius: ${themes.borderRadius};

`;

const DarkContainer = styled.div`
  height: 48px;
  padding: 1rem;
  display: flex;
  background-color: ${themes.mainDark};
  border-top-right-radius: ${themes.borderRadius};
  border-top-left-radius: ${themes.borderRadius};

`;

const HeaderContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-left: 1rem;
  justify-content: center;
  display: inline-flex;
  align-items: center;
`;

const Header = styled.span`
  font-family: ${themes.fontFamily};
  font-size: 1.5rem;
  color: ${themes.white};
  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
`;

const ModalBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${themes.white};
  position: absolute;
  z-index: 1005;
  opacity: 0.5;
`;

const TextContainer = styled.div`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  height: calc(100% - 80px - 84px - 96px);
  overflow-y: auto;
  font-family: ${themes.fontFamily}
`;

const ButtonContainer = styled.div`
  padding: 1.5rem;
  justify-content: center;
  display: flex;
  align-items: center;
`;

export default function Modal(props) {
  const {
    showModal,
    setShowModal,
  } = props;

  const {
    header,
    subHeader,
    text,
    isPDF,
  } = showModal;

  if (isPDF) {
    return (
      <>
        <ModalBackground />
        <ModalBox style={{ width: '80%', height: '80%' }}>
          <DarkContainer>
            <HeaderContainer>
              <Header>
                {header}
              </Header>
            </HeaderContainer>
          </DarkContainer>
          {/* <TextContainer> */}
          {/* Insert PDF Object */}
          <PDF information={showModal} setShowModal={setShowModal} />
          {/* </TextContainer> */}
          {/* <ButtonContainer style={{ paddingTop: '0px', backgroundColor: themes.mainDark }}>
            <Button
              style={{
                backgroundColor: themes.secondaryDark,
                color: themes.white,
                fontFamily: themes.fontFamily,
                // paddingTop: '0px',
              }}
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </ButtonContainer> */}
        </ModalBox>
      </>
    );
  }

  if (!header || !subHeader || !text) return null;

  return (
    <>
      <ModalBackground />
      <ModalBox>
        <DarkContainer>
          <HeaderContainer>
            <Header>
              {header}
            </Header>
          </HeaderContainer>
        </DarkContainer>
        <TextContainer>
          {text}
        </TextContainer>
        <ButtonContainer>
          <Button
            style={{
              backgroundColor: themes.secondaryDark,
              color: themes.white,
              fontFamily: themes.fontFamily,
            }}
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </ButtonContainer>
      </ModalBox>
    </>
  );
}
