import React, { useEffect, useState, useContext } from 'react';

import styled from 'styled-components';
import ClearIcon from '@material-ui/icons/Clear';
import themes from '../../styles/themes';
import Api from '../../api';
import AppCtx from '../../context/AppContext';
import decodeFDIST from '../../helpers/fdist';
import { filterValue, formatPercent, outOfWithLocale, round } from '../../helpers/number';
import { CopyText } from '../global';
import { CollapseMenu } from '../global/CollapseMenu';
import KV from '../global/KV';
import { decodeFvcCode } from '../../helpers/fvc';
import { decodeFvhCode } from '../../helpers/fvh';
import { decodeAttrPair } from '../../helpers/decoders';

const Dialog = styled.div`
  z-index: 9999;
`;

const InfoSection = styled.div`
  width: 30rem;
  height: 25.5rem;
  background-color: white;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  /* border-radius: 10px; */
  border-radius: ${themes.borderRadius};
  font-size: 10pt;
  padding: 0 0.5rem;
`;

const InfoInner = styled.div`
  height: 20rem;
  overflow-y: scroll;
`;

const Label = styled.span`
  font-size: 10pt;
  padding: 2px;
  font-weight: 600;
  letter-spacing: 0.5px;
`;
const Value = styled.span`
  font-size: 10pt;
  padding: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-basis: 50%;
`;

const StatHead = styled.div`
  opacity: 0.7;
  font-style: italic;
  margin-left: 0.5rem;
`;

/**
 * @typedef {import('../../api/WFHazAPI/pins').Pin} Pin
 * @typedef {import('../../api/WFHazAPI/comments').Comment} Comment
 */

export default function MapDialog(props) {
  const { _ref, coords, sp, onClose } = props;

  const { attributes } = useContext(AppCtx.Context);

  /** @type {Comment[]} */
  const [pinData, setPinData] = useState();
  const [title, setTitle] = useState('Point');

  const getAllData = async () => {
    const d = {
      evt: { code: '', display: '' },
      bps: { code: '', display: '' },
      fdist: { code: '', display: '' },
      fbsum: {},
    };
    const allResps = await Promise.all([
      Api.BiophyiscalSettings.get({ id: sp.bps }).catch(() => ({})),
      Api.Evts.get({ id: sp.fvt }).catch(() => ({})),
      Api.FbSums.get({ id: sp.rule_id }).catch(() => ({})),
    ]);
    if (allResps[0] && allResps[0].name) {
      d.bps = { code: allResps[0].code, display: allResps[0].name };
    }

    if (allResps[1] && allResps[1].name) {
      d.evt = { code: allResps[1].code, display: allResps[1].name };
    }

    if (allResps[2] && allResps[2].id) {
      d.fbsum = { ...allResps[2] };
    }

    /**
     * Get FDIST string representation of code
     * https://landfire.gov/DataDictionary/LF200/LF2016Remap_FDist.pdf
     *
     * Can be zero
     *
     */
    if (sp.fdist !== undefined) {
      const fdistStr = decodeFDIST(sp.fdist);
      d.fdist.code = sp.fdist;
      d.fdist.display = fdistStr;
      // console.log(`FDIST: ${fdistStr}`);
    }

    setPinData(d);
  };

  const buildTitle = () => {
    let t = 'Point';
    Object.keys(attributes).forEach((k) => {
      const attr = attributes[k];
      if (attr.selected === true) {
        // let val = sp?.[attr.iName] || '';

        let val = decodeAttrPair(attr.iName, sp?.[attr.iName]) || 'Unknown';

        // HACK (this isn't necessary if we fix number.numeric)
        if (attr.iName === 'fvt') {
          val = pinData?.evt?.code || 'Unknown';
        }

        if (attr.iName === 'bps') {
          val = pinData?.bps?.code || 'Unknown';
        }

        t = `${attr.name}: ${val}`;
      }
    });
    setTitle(t);
  };

  useEffect(() => {
    if (sp) {
      getAllData();
    }
  }, [coords]);

  useEffect(() => {
    // Whenever attributes and or coords change we build the
    // dialog's title
    if (attributes) {
      buildTitle();
    }
  }, [attributes, coords]);

  return (
    <Dialog ref={_ref}>
      <InfoSection style={{ fontFamily: themes.fontFamily }}>
        <div style={{ fontSize: '9pt', position: 'relative', paddingTop: '10px' }}>
          <div
            title={title}
            style={{
              fontSize: '12pt',
              fontFamily: themes.fontFamilyBold,
              marginLeft: '0.25rem',
              letterSpacing: '1px',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              width: '28rem',
              cursor: 'pointer',
              marginBottom: '0.5rem',
            }}
          >
            {title}
          </div>
          <ClearIcon
            style={{
              fontSize: '15px',
              cursor: 'pointer',
              top: 4,
              right: -4,
              position: 'absolute',
            }}
            onClick={() => {
              onClose?.();
            }}
          />
        </div>

        <div
          style={{
            height: '2px',
            width: '100%',
            backgroundColor: '#ebeced',
            borderRadius: '50px',
          }}
        />

        <InfoInner>
          {sp && (
            <div style={{ marginTop: '0.5rem' }}>
              <CollapseMenu hlabel="Canopy/Surface Summary">
                <KV.List
                  style={{ paddingLeft: '0.5rem', paddingTop: '0.2rem' }}
                  data={[
                    { key: 'Existing Veg. Type', value: decodeAttrPair('evt', pinData?.evt?.display, sp.fvt) },
                    { key: 'BPS', value: decodeAttrPair('bps', pinData?.bps?.display, sp.bps) },
                    { key: 'FM40', value: decodeAttrPair('fm40', sp.fm40) },
                    { key: 'Canopy Base Height', value: `${decodeAttrPair('cbh', sp.cbh)}` },
                    { key: 'Canopy Cover', value: decodeAttrPair('cc', sp.cc) },
                    { key: 'Canopy Bulk Density', value: decodeAttrPair('cbd', sp.cbd) },
                    { key: 'Canopy Height', value: decodeAttrPair('ch', sp.ch) },
                    { key: 'Fuel Disturbance', value: decodeAttrPair('fdist', sp.fdist) },
                    { key: 'EVC', value: decodeAttrPair('evc', sp.evc) },
                    { key: 'EVH', value: decodeAttrPair('evh', sp.evh) },
                    { key: 'Slope', value: decodeAttrPair('slp', sp.slp) },
                    { key: 'Aspect', value: decodeAttrPair('asp', sp.asp) },
                  ]}
                />
              </CollapseMenu>
              <CollapseMenu hlabel="Rule Summary" hstyle={{ marginTop: '5px' }} startCollapsed>
                <KV.List
                  style={{ paddingLeft: '0.5rem', paddingTop: '0.2rem' }}
                  data={[
                    { key: 'Canopy Guide', value: `${decodeAttrPair('cg', pinData?.fbsum?.canopy_guide)}` },
                    { key: 'Cover Range', value: `${decodeFvcCode(pinData?.fbsum.cover_low, pinData?.fbsum.cover_high)}` },
                    { key: 'Height Range', value: `${decodeFvhCode(pinData?.fbsum.height_low, pinData?.fbsum.height_high)}` },
                    { key: '% of total EVT', value: `${formatPercent(pinData?.fbsum?.pixels, pinData?.fbsum?.evt_pixels)}` },
                    { key: 'Pixels/EVT Pixels', value: `${outOfWithLocale(pinData?.fbsum?.pixels, pinData?.fbsum?.evt_pixels)}` },
                    { key: 'Rule ID', value: `${pinData?.fbsum?.rule_id || 'Unknown'}` },
                  ]}
                />
              </CollapseMenu>
              <CollapseMenu hlabel="WildWest Burn Probability" hstyle={{ marginTop: '5px' }} startCollapsed>
                <KV.List
                  style={{ paddingLeft: '0.5rem', paddingTop: '0.2rem' }}
                  data={[{ key: 'Burn Probability', value: `${decodeAttrPair('bp', sp.bp, 1)}` }]}
                />
              </CollapseMenu>
              <CollapseMenu hlabel="Fire Behavior Statistics" hstyle={{ marginTop: '5px' }} startCollapsed>
                <>
                  <div style={{ display: 'flex' }}>
                    <Label style={{ flexBasis: '35%' }} />
                    <Label style={{ flexBasis: '20%' }}>15 mi/hr</Label>
                    <Label style={{ flexBasis: '20%' }}>WildEST</Label>
                    <Label style={{ flexBasis: '20%' }}>Rule Mean</Label>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StatHead style={{ flexBasis: '35%' }}>FlameLength (ft.)</StatHead>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, sp.fl, round)}</Value>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, sp.wfl, round)}</Value>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, pinData?.fbsum?.mean_fl, round)}</Value>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StatHead style={{ flexBasis: '35%' }}>Rate of Spread (ch/hr)</StatHead>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, sp.ros, round)}</Value>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, sp.wros, round)}</Value>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1000, pinData?.fbsum?.mean_ros, round)}</Value>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StatHead style={{ flexBasis: '35%' }}>Torching Index (mi/hr)</StatHead>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 200, sp.ti, round)}</Value>
                    <Value style={{ flexBasis: '20%' }}>-</Value>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 200, pinData?.fbsum?.mean_ti, round)}</Value>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StatHead style={{ flexBasis: '35%' }}>Crowning Index (mi/hr)</StatHead>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 200, sp.ci, round)}</Value>
                    <Value style={{ flexBasis: '20%' }}>-</Value>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 200, pinData?.fbsum?.mean_ci, round)}</Value>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StatHead style={{ flexBasis: '35%' }}>Wind Adjustment Factor</StatHead>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, sp.waf, round)}</Value>
                    <Value style={{ flexBasis: '20%' }}>-</Value>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, pinData?.fbsum?.mean_waf, round)}</Value>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StatHead style={{ flexBasis: '35%' }}>FTP - Active</StatHead>
                    <Value style={{ flexBasis: '20%' }}>-</Value>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, sp.ftp_active, round)}</Value>
                    <Value style={{ flexBasis: '20%' }}>-</Value>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StatHead style={{ flexBasis: '35%' }}>FTP - Passive</StatHead>
                    <Value style={{ flexBasis: '20%' }}>-</Value>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, sp.ftp_passive, round)}</Value>
                    <Value style={{ flexBasis: '20%' }}>-</Value>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StatHead style={{ flexBasis: '35%' }}>FTP - Underburn</StatHead>
                    <Value style={{ flexBasis: '20%' }}>-</Value>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, sp.ftp_underburn, round)}</Value>
                    <Value style={{ flexBasis: '20%' }}>-</Value>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <StatHead style={{ flexBasis: '35%' }}>FTP - Surface</StatHead>
                    <Value style={{ flexBasis: '20%' }}>-</Value>
                    <Value style={{ flexBasis: '20%' }}>{filterValue(0, 1, sp.ftp_surface, round)}</Value>
                    <Value style={{ flexBasis: '20%' }}>-</Value>
                  </div>
                </>
              </CollapseMenu>
            </div>
          )}
        </InfoInner>
        <div
          style={{
            height: '2px',
            width: '100%',
            backgroundColor: '#ebeced',
            borderRadius: '50px',
          }}
        />
        <div style={{ display: 'flex', marginTop: '0.5rem', justifyContent: 'flex-end' }}>
          <CopyText text={`${round(coords[0])}, ${round(coords[1])}`} />
          <CopyText style={{ marginLeft: '0.3rem' }} text="EPSG:3857" />
        </div>
      </InfoSection>
    </Dialog>
  );
}
