import { UnknownString } from '../constants';

export const formatCg = (val) => {
  if (val === false || val === undefined) return UnknownString;
  if (val >= 0 && val <= 3) {
    return `${val}`;
  }

  return UnknownString;
};

export default {
  formatCg,
};
