import { UnknownString } from '../constants';
import { round } from './number';

export const degreesToPercent = (val) => Math.tan(((val * Math.PI) / 180)) * 100;
export const formatSlp = (val) => {
  if (val === false || val === undefined) return UnknownString;
  if (val >= 0 && val <= 87) {
    return `${round(val, 0)}\u00B0 (${round(degreesToPercent(val), 0)}%)`;
  }

  return UnknownString;
};

export default {
  formatSlp,
};
