import React, { createContext, useEffect, useReducer, useState, useRef } from 'react';
import Api from '../api';

const ACTIONS = {
  SET_USER: 'SET_USER',
  SET_SHOW_SIDEPANEL: 'SET_SHOW_SIDEPANEL',
  SET_PINS: 'SET_PINS',
  SET_PROJECTID: 'SET_PROJECTID',
  SET_ACTIVE_LAYER: 'SET_ACTIVE_LAYER',
};

const initState = {
  user: null,
  showSidePanel: true,
  pins: [],
  projectId: 1,
  activeLayer: null,
};

const Context = createContext(initState);

const reducer = (state, action) => {
  if (action.type === ACTIONS.SET_USER) {
    return { ...state, user: action.data };
  }

  if (action.type === ACTIONS.SET_SHOW_SIDEPANEL) {
    return { ...state, showSidePanel: action.data };
  }

  if (action.type === ACTIONS.SET_PINS) {
    return { ...state, pins: action.data };
  }

  if (action.type === ACTIONS.SET_PROJECTID) {
    return { ...state, projectId: action.data };
  }

  if (action.type === ACTIONS.SET_ACTIVE_LAYER) {
    return { ...state, activeLayer: action.data };
  }

  return state;
};

export function Provder(props) {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initState);

  const [attributes, setAttributes] = useState();
  const [bannerData, setBannerData] = useState();

  const { user, projectId } = state;

  const mapRef = useRef();

  const getShowSidePanel = () => {
    const raw = localStorage.getItem('showSidePanel');
    if (!raw) return true;

    const val = JSON.parse(raw);
    // console.assert((val === true || val === false));
    return val;
  };

  const setShowSidePanel = (val) => {
    // console.assert((val === true || val === false));
    localStorage.setItem('showSidePanel', JSON.stringify(val));

    dispatch({ type: ACTIONS.SET_SHOW_SIDEPANEL, data: val });
  };

  const pinLoader = async (refetch = true) => {
    if (!refetch) return state.pins;
    const pins = await Api.Pins.get({ project_id: projectId });

    if (pins && pins.error) {
      // console.error(pins.error);
      return [];
    }

    dispatch({ type: ACTIONS.SET_PINS, data: pins });
    return pins;
  };

  const loadUser = async () => {
    try {
      // Loading side panel visibility
      dispatch({ type: ACTIONS.SET_SHOW_SIDEPANEL, data: getShowSidePanel() });

      // Attempting to autologin user
      const autoUser = await Api.Auth.autologin();
      if (autoUser && autoUser.error) return;

      dispatch({ type: ACTIONS.SET_USER, data: autoUser });

      // Loading user's pins
      pinLoader();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error loading user: ', error);
    }
  };

  const clearUserState = () => {
    dispatch({ type: ACTIONS.SET_USER, data: null });
    dispatch({ type: ACTIONS.SET_PINS, data: [] });
  };

  const setActiveLayer = (data) => {
    dispatch({ type: ACTIONS.SET_ACTIVE_LAYER, data });
  };

  const showBanner = (data) => {
    setBannerData(data);
    setTimeout(() => {
      setBannerData(false);
    }, 2000);
  };

  const value = {
    state,
    dispatch,
    setShowSidePanel,
    clearUserState,
    pinLoader,
    setActiveLayer,
    attributes,
    setAttributes,
    bannerData,
    showBanner,
    mapRef,
  };

  useEffect(() => {
    // Load user when provider mounts.
    if (!user) loadUser();
  }, []);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export default {
  Context,
  reducer,
  Provder,
  ACTIONS,
};
