import React from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
// import { Warning } from '@material-ui/icons';
import { CheckCircle } from '@material-ui/icons';
import themes from '../../../styles/themes';

const BannerContainer = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 64px;
  /* background-color: ${themes.bluesteelish}; */
  /* opacity: .95; */
`;

const BannerOutter = styled.div`
  width: 35%;
  height: 76%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
  border-top-right-radius: ${themes.borderRadius};
  border-top-left-radius: ${themes.borderRadius};
  border-bottom-left-radius: ${themes.borderRadius};
  border-bottom-right-radius: ${themes.borderRadius};
  border-left: .5rem solid ${themes.greenish};
`;

const BannerInner = styled.div`
  background-color: ${themes.greenishOpactityHalf};
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

`;

const IconContainer = styled.div`
  flex-basis: 15%;
  display: flex;
  justify-content: center;
  align-items: center; 
`;

const BannerText = styled.span`
  flex-basis: 90%;
  font-family: ${themes.fontFamily};
`;

export default function Banner(props) {
  const { text } = props;

  if (!text) return null;

  return (
    <BannerContainer key={uuidv4}>
      <BannerOutter>
        <BannerInner>
          <IconContainer>
            <CheckCircle />
          </IconContainer>
          <BannerText key={uuidv4}>
            {text}
          </BannerText>
        </BannerInner>
      </BannerOutter>
    </BannerContainer>
  );
}
