const COAL2021BPPercentiles = [
  {
    x: 4,
    y: 9.99999974737875e-06,
  },
  {
    x: 5,
    y: 9.99999974737875e-06,
  },
  {
    x: 6,
    y: 1.36537552862137e-05,
  },
  {
    x: 7,
    y: 3.12946176563855e-05,
  },
  {
    x: 8,
    y: 5.02535018313211e-05,
  },
  {
    x: 9,
    y: 7.14382025762461e-05,
  },
  {
    x: 10,
    y: 9.57175398070831e-05,
  },
  {
    x: 11,
    y: 0.000122808109153993,
  },
  {
    x: 12,
    y: 0.000152129156049341,
  },
  {
    x: 13,
    y: 0.000182804549694993,
  },
  {
    x: 14,
    y: 0.000214309198781848,
  },
  {
    x: 15,
    y: 0.000246642011916265,
  },
  {
    x: 16,
    y: 0.000279523257631809,
  },
  {
    x: 17,
    y: 0.000312860269332305,
  },
  {
    x: 18,
    y: 0.000346287444699556,
  },
  {
    x: 19,
    y: 0.000380146301176865,
  },
  {
    x: 20,
    y: 0.00041476046317257,
  },
  {
    x: 21,
    y: 0.000450491963420063,
  },
  {
    x: 22,
    y: 0.000487054177210666,
  },
  {
    x: 23,
    y: 0.000523999740835279,
  },
  {
    x: 24,
    y: 0.000561250373721123,
  },
  {
    x: 25,
    y: 0.000598136859480292,
  },
  {
    x: 26,
    y: 0.000634455820545554,
  },
  {
    x: 27,
    y: 0.000669765926431865,
  },
  {
    x: 28,
    y: 0.000703794416040182,
  },
  {
    x: 29,
    y: 0.00073666573734954,
  },
  {
    x: 30,
    y: 0.000768830912420526,
  },
  {
    x: 31,
    y: 0.000800566805992275,
  },
  {
    x: 32,
    y: 0.000832148129120469,
  },
  {
    x: 33,
    y: 0.000863501220010221,
  },
  {
    x: 34,
    y: 0.000894845288712531,
  },
  {
    x: 35,
    y: 0.000926065491512418,
  },
  {
    x: 36,
    y: 0.00095736252842471,
  },
  {
    x: 37,
    y: 0.000988187035545707,
  },
  {
    x: 38,
    y: 0.00101892766542733,
  },
  {
    x: 39,
    y: 0.00104987318627536,
  },
  {
    x: 40,
    y: 0.00108082313090563,
  },
  {
    x: 41,
    y: 0.0011116253444925,
  },
  {
    x: 42,
    y: 0.00114256772212684,
  },
  {
    x: 43,
    y: 0.00117407971993089,
  },
  {
    x: 44,
    y: 0.00120601244270802,
  },
  {
    x: 45,
    y: 0.00123841885942966,
  },
  {
    x: 46,
    y: 0.00127122201956809,
  },
  {
    x: 47,
    y: 0.00130435696337372,
  },
  {
    x: 48,
    y: 0.00133763032499701,
  },
  {
    x: 49,
    y: 0.00137136084958911,
  },
  {
    x: 50,
    y: 0.00140540429856628,
  },
  {
    x: 51,
    y: 0.0014399130595848,
  },
  {
    x: 52,
    y: 0.00147518783342093,
  },
  {
    x: 53,
    y: 0.00151165423449129,
  },
  {
    x: 54,
    y: 0.00154879922047257,
  },
  {
    x: 55,
    y: 0.00158719951286912,
  },
  {
    x: 56,
    y: 0.00162679119966924,
  },
  {
    x: 57,
    y: 0.00166723155416548,
  },
  {
    x: 58,
    y: 0.00170878344215453,
  },
  {
    x: 59,
    y: 0.00175160146318376,
  },
  {
    x: 60,
    y: 0.00179598911199719,
  },
  {
    x: 61,
    y: 0.00184195122565143,
  },
  {
    x: 62,
    y: 0.00188950274605304,
  },
  {
    x: 63,
    y: 0.00193897285498679,
  },
  {
    x: 64,
    y: 0.00199026195332408,
  },
  {
    x: 65,
    y: 0.00204315013252199,
  },
  {
    x: 66,
    y: 0.00209819967858493,
  },
  {
    x: 67,
    y: 0.00215526833198965,
  },
  {
    x: 68,
    y: 0.00221452838741243,
  },
  {
    x: 69,
    y: 0.0022766194306314,
  },
  {
    x: 70,
    y: 0.00234193541109562,
  },
  {
    x: 71,
    y: 0.00241025771247223,
  },
  {
    x: 72,
    y: 0.00248243939131498,
  },
  {
    x: 73,
    y: 0.00255788280628622,
  },
  {
    x: 74,
    y: 0.00263663963414729,
  },
  {
    x: 75,
    y: 0.00271957879886031,
  },
  {
    x: 76,
    y: 0.00280793244019151,
  },
  {
    x: 77,
    y: 0.00290062348358333,
  },
  {
    x: 78,
    y: 0.00299647590145469,
  },
  {
    x: 79,
    y: 0.00309812906198204,
  },
  {
    x: 80,
    y: 0.00320461997762322,
  },
  {
    x: 81,
    y: 0.00331608857959509,
  },
  {
    x: 82,
    y: 0.00343459285795689,
  },
  {
    x: 83,
    y: 0.00356146623380482,
  },
  {
    x: 84,
    y: 0.00369756179861724,
  },
  {
    x: 85,
    y: 0.00384279410354793,
  },
  {
    x: 86,
    y: 0.00399673637002707,
  },
  {
    x: 87,
    y: 0.00416194368153811,
  },
  {
    x: 88,
    y: 0.00434029288589954,
  },
  {
    x: 89,
    y: 0.00453560799360275,
  },
  {
    x: 90,
    y: 0.00475285714492202,
  },
  {
    x: 91,
    y: 0.00499074440449476,
  },
  {
    x: 92,
    y: 0.00525327771902084,
  },
  {
    x: 93,
    y: 0.00555535645689817,
  },
  {
    x: 94,
    y: 0.00591279380023479,
  },
  {
    x: 95,
    y: 0.00631557265296578,
  },
  {
    x: 96,
    y: 0.00677411491051316,
  },
  {
    x: 97,
    y: 0.0072998331626877,
  },
  {
    x: 98,
    y: 0.00793522214516995,
  },
  {
    x: 99,
    y: 0.00886983186937867,
  },
  {
    x: 100,
    y: 0.0161240734159946,
  },
];

export default COAL2021BPPercentiles;
