import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Styled from 'styled-components';
import themes from '../../styles/themes';
import ModalInformation from './ModelInformation';

const IconContainer = Styled.div`
  cursor: pointer;
  padding-left: 1rem;
`;

export default function Information(props) {
  const { setShowModal, modalIdentifier } = props;

  const handleClick = () => {
    if (ModalInformation[modalIdentifier]) {
      setShowModal(ModalInformation[modalIdentifier]);
    }
  };

  return (
    <IconContainer>
      <InfoIcon
        style={{ fill: themes.white }}
        onClick={() => handleClick()}
      />
    </IconContainer>
  );
}
