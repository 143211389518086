import { UnknownString } from '../constants';
import twoDigitEvLut from './twoDigitEvLut';

export const decodeEvhCode = (code) => {
  if (code === false || code === undefined) return UnknownString;
  if (twoDigitEvLut[code]) {
    return twoDigitEvLut[code];
  }

  if (code >= 101 && code <= 142) {
    return `Tree Height = ${code - 100} m`;
  }

  if (code >= 201 && code <= 230) {
    return `Shrub Height = ${code - 200} m`;
  }

  if (code >= 301 && code <= 310) {
    return `Herb Height = ${code - 300} m`;
  }

  return 'Unknown';
};

export default { decodeEvhCode };
