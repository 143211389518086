/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import BP from './BP';
import Hauling from './Hauling';
import Paletted from './paletted';
import Gradient from './gradient';

export default function Legend(props) {
  const { mapLayer, type } = props;

  if (!mapLayer || !type) return null;

  switch (type) {
    case 'BP':
      return (
        <BP
          {...props}
        />
      );
    case 'Hauling':
      return (
        <Hauling
          {...props}
        />
      );
    case 'Paletted':
      return (
        <Paletted
          {...props}
        />
      );
    case 'Gradient':
      return (
        <Gradient
          {...props}
        />
      );
    default:
      return null;
  }
}
