import { UnknownString } from '../constants';

const codeMap = {
  0: 'No Disturbance',
  111: 'Fire, low-severity, one year since disturbance',
  112: 'Fire, low-severity, disturbed in the last five years',
  113: 'Fire, low-severity, 6-10 years since disturbance',
  121: 'Fire, moderate-severity, one year since disturbance',
  122: 'Fire, moderate-severity, disturbed in the last five years',
  123: 'Fire, moderate-severity, 6-10 years since disturbance',
  131: 'Fire, high-severity, one year since disturbance',
  132: 'Fire, high-severity, disturbed in the last five years',
  133: 'Fire, high-severity, 6-10 years since disturbance',
  211: 'Mechanical Add, low-severity, one year since disturbance',
  212: 'Mechanical Add, low-severity, disturbed in the last five years',
  213: 'Mechanical Add, low-severity, 6-10 years since disturbance',
  221: 'Mechanical Add, moderate-severity, one year since disturbance',
  222: 'Mechanical Add, moderate-severity, disturbed in the last five years',
  223: 'Mechanical Add, moderate-severity, 6-10 years since disturbance',
  231: 'Mechanical Add, high-severity, one year since disturbance',
  232: 'Mechanical Add, high-severity, disturbed in the last five years',
  233: 'Mechanical Add, high-severity, 6-10 years since disturbance',
  311: 'Mechanical Remove, low-severity, one year since disturbance',
  312: 'Mechanical Remove, low-severity, disturbed in the last five years',
  313: 'Mechanical Remove, low-severity, 6-10 years since disturbance',
  321: 'Mechanical Remove, moderate-severity, one year since disturbance',
  322: 'Mechanical Remove, moderate-severity, disturbed in the last five years',
  323: 'Mechanical Remove, moderate-severity, 6-10 years since disturbance',
  331: 'Mechanical Remove, high-severity, one year since disturbance',
  332: 'Mechanical Remove, high-severity, disturbed in the last five years',
  333: 'Mechanical Remove, high-severity, 6-10 years since disturbance',
  411: 'Windthrow, low-severity, one year since disturbance',
  412: 'Windthrow, low-severity, disturbed in the last five years',
  413: 'Windthrow, low-severity, 6-10 years since disturbance',
  421: 'Windthrow, moderate-severity, one year since disturbance',
  422: 'Windthrow, moderate-severity, disturbed in the last five years',
  423: 'Windthrow, moderate-severity, 6-10 years since disturbance',
  431: 'Windthrow, high-severity, one year since disturbance',
  432: 'Windthrow, high-severity, disturbed in the last five years',
  433: 'Windthrow, high-severity, 6-10 years since disturbance',
  511: 'Insects-Disease, low-severity, one year since disturbance',
  512: 'Insects-Disease, low-severity, disturbed in the last five years',
  513: 'Insects-Disease, low-severity, 6-10 years since disturbance',
  521: 'Insects-Disease, moderate-severity, one year since disturbance',
  522: 'Insects-Disease, moderate-severity, disturbed in the last five years',
  523: 'Insects-Disease, moderate-severity, 6-10 years since disturbance',
  531: 'Insects-Disease, high-severity, one year since disturbance',
  532: 'Insects-Disease, high-severity, disturbed in the last five years',
  533: 'Insects-Disease, high-severity, 6-10 years since disturbance',
  611: 'Unknown, low-severity, one year since disturbance',
  612: 'Unknown, low-severity, disturbed in the last five years',
  613: 'Unknown, low-severity, 6-10 years since disturbance',
  621: 'Unknown, moderate-severity, one year since disturbance',
  622: 'Unknown, moderate-severity, disturbed in the last five years',
  623: 'Unknown, moderate-severity, 6-10 years since disturbance',
  631: 'Unknown, high-severity, one year since disturbance',
  632: 'Unknown, high-severity, disturbed in the last five years',
  633: 'Unknown, high-severity, 6-10 years since disturbance',
};

const decodeFDIST = (code) => {
  if (code === false || code === undefined) return UnknownString;
  const str = codeMap[code];
  if (str) {
    return `${code} - ${str} `;
  }

  return UnknownString;
};

export default decodeFDIST;
