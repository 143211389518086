import React from 'react';

const getModalInformation = (text) => {
  const modalInformation = {
    header: 'Link',
    subHeader: 'subHeader',
    text: (
      <a
        href={text}
        target="_blank"
        rel="noreferrer"
      >
        {text}
      </a>
    ),
  };
  return modalInformation;
};

const fallbackCopyTextToClipboard = (text, modalFunction) => {
  const textArea = document.createElement('textarea');

  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  textArea.focus();
  textArea.select();

  try {
    const success = document.execCommand('copy');
    if (success) {
      // console.log('fallback success');
    } else {
      // console.log('failure in fallback, falling back on modal');
    }
  } catch {
    // console.error('Failed to copy to clipboard');

    modalFunction(getModalInformation(text));
  }
  document.body.removeChild(textArea);
};

const copyTextToClipboard = (text, modalFunction) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text, modalFunction);
    return;
  }

  navigator.clipboard.writeText(text).then(() => {
    // console.log('navigator successfully copied');
  }, () => {
    // console.erroe('Navigator Errored, falling back on modal', err);
    modalFunction(getModalInformation(text));
  });
};

const findSelectedLayers = (obj) => {
  // console.log('obj', obj);
  const selectedLayers = Object.keys(obj)
    .filter((l) => obj[l].selected)
    .map((l) => obj[l]);
  // console.log('selectedLayers', selectedLayers);
  if (selectedLayers.length) {
    return selectedLayers;
  }
  return false;
};

const getLink = (mapRef, attributes, baseAttributes, referenceAttributes) => {
  // console.log('mapRef', mapRef);
  if (!mapRef) return null;

  const selectedHazardLayers = findSelectedLayers(attributes);
  const selectedBaseLayers = findSelectedLayers(baseAttributes);
  const selectedReferenceLayers = findSelectedLayers(referenceAttributes);

  const { map } = mapRef.current;
  const view = map.getView();

  const zoomLevel = view.getZoom();
  const center = view.getCenter();

  let sPC;

  const pinLayer = mapRef.current.findLayerByIName('pin');

  if (pinLayer) {
    const features = pinLayer.getSource().getFeatures();
    if (features[0]) {
      const geom = features[0].getGeometry();
      const coords = geom.getCoordinates();
      sPC = coords;
    }
    // console.log('features', features);
  }

  const obj = {
    zoomLevel,
    center,
  };

  if (sPC) {
    obj.sPC = sPC;
  }

  if (selectedBaseLayers) {
    obj.bL = selectedBaseLayers[0].iName;
  }

  if (selectedHazardLayers) {
    const {
      iName,
      toolsVisible,
    } = selectedHazardLayers[0];
    obj.hL = iName;
    obj.hO = mapRef.current.findLayerByIName(iName).getOpacity();
    obj.hTV = toolsVisible;
  }

  if (selectedReferenceLayers) {
    const firstRL = selectedReferenceLayers[0];
    obj.rLS = selectedReferenceLayers.map((l) => l.iName);
    obj.rLO = mapRef.current.findLayerByIName(firstRL.iName).getOpacity();
  }

  return obj;
};

const validateLinkObj = (obj) => {
  const {
    zoomLevel,
    center,
    sPC,
    bL,
    hL,
    hO,
    hTV,
    rLO,
    rLS,
  } = obj;

  if (!zoomLevel || parseFloat(zoomLevel) < 0) {
    return false;
  }

  if (!center || !Array.isArray(center) || !parseFloat(center[0]) || !parseFloat(center[1])) {
    return false;
  }

  const newObject = {
    zoomLevel: parseFloat(zoomLevel),
    center: center.map((v) => parseFloat(v)),
    rLO: 0,
    hO: 0,
  };

  if (sPC && Array.isArray(sPC) && !parseFloat(sPC[0] && !parseFloat(sPC[1]))) {
    newObject.sPC = sPC.map((v) => parseFloat(v));
  }

  if (bL) {
    newObject.bL = bL;
  }

  if (hL) {
    newObject.hL = hL;
  }

  if (hO && parseFloat(hO)) {
    newObject.hO = parseFloat(hO);
  }

  if (hTV) {
    newObject.hTV = (hTV === 'true');
  }

  if (rLO && parseFloat(rLO)) {
    newObject.rLO = parseFloat(rLO);
  }

  if (rLS) {
    newObject.rLS = Array.isArray(rLS) ? [...rLS] : [rLS];
  }

  return newObject;
};

const parseLink = (uri) => {
  const decoded = decodeURIComponent(uri);

  const pairs = decoded.substring(1)
    .split('&');

  const kVPairs = pairs.reduce((h, p) => {
    const pair = p.split('=');
    const p2 = pair[1].split(',');
    const key = pair[0];
    const value = p2.length === 1 ? p2[0] : p2;

    return {
      ...h,
      [key]: value,
    };
  }, {});

  const validatedObj = validateLinkObj(kVPairs);

  return validatedObj;
};

export { getLink, parseLink, copyTextToClipboard };
