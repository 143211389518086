import React, { useState, useEffect } from 'react';
import Styled from 'styled-components';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PauseIcon from '@material-ui/icons/Pause';
import Information from '../../information';
import ChartScatter from './ChartScatter';
import Chart from './Chart';
import themes from '../../../styles/themes';

const StyledImage = Styled.img`
  width: 100%;
`;

const TitleContainer = Styled.div`
  border-bottom: 1px solid ${themes.white};
  // margin-bottom: 20px;
  font-family: ${themes.fontFamily};
  // padding-bottom: .5rem;
  // display: inline-block;
  text-align: center;
  // height: 10%;
  // background-color: blue;
  width: 100%;
  margin: 1rem;
  padding-bottom: .5rem;
`;

const Title = Styled.span`
  color: ${themes.white};
  padding-right: 1rem;
`;

const Series = Styled.div`
  // width: 65%;


  // height: calc(100vh - 6.5rem - 10% - 38px - 56px);
  // height: 80%;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  // @media (max-width: 768px) {
  //   width: 85%;
  // };
  // background-color: ${themes.mainDark};
`;

const SliderContainer = Styled.div`
  height: 10%;
  width: 70%;
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: .25rem;
  display: flex;
`;

const ChartContainer = Styled.div`
  height: calc(100%);
  // Yikes 
  min-height: calc(150px);
  width: calc(40%);
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: row;
    max-height: 200px;
  }
`;
const ChartSubContainer = Styled.div`
  display: block;
  height: 50%;
  width: 100%;
  @media (max-width: 768px) {
    // width: 100%;
    height: 100%;
  }
  // padding: 1rem;
`;

const ImageContainer = Styled.div`
  height: 100%;
  width: 60%;
  @media (max-width: 768px) {
    width: 100%;
  }
  // background-color: teal;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexContainer = Styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  align-items: center;
  height: 80%;
  width: 80%;
  @media (max-width: 768px) {
    width: 100%
  }
`;

const InformationContainer = Styled.div`
  padding-top: .5rem;
  display: inline-block;
  position: relative;
`;
const InformationAlign = Styled.div`
  padding-right: 1rem;
  display: inline-block;
  position: absolute;
  top:-10px;
`;

const StyledSlider = withStyles({
  root: {
    color: themes.white,
  },
  valueLabel: {
    fontFamily: themes.fontFamily,
    color: themes.white,
    '& *': {
      backgroundColor: 'unset',

    },
  },
  thumb: {
    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgb(67 67 67 / 18%)',
    },
  },
  markLabel: {
    fontFamily: themes.fontFamily,
    color: themes.white,
    '@media (max-width: 800px)': {
      color: themes.secondaryDark,
    },
  },
})(Slider);

const StyledButton = withStyles({
  root: {
    padding: '0px',
    minWidth: '0px',
    width: '28px',
    '&:hover': {
      backgroundColor: themes.hoverMainDark,
    },
  },
})(Button);

export default function ImageSeries(props) {
  const { setShowModal } = props;

  const [year, setYear] = useState(1990);
  const [delay] = useState(500);
  const [play, setPlay] = useState(false);
  const [count, setCount] = useState(0);

  // const marks = [
  //   {
  //     value: 1990,
  //     label: '1990',
  //   },
  //   {
  //     value: 1991,
  //   },
  //   {
  //     value: 2000,
  //     label: '2000',
  //   },
  //   {
  //     value: 2010,
  //     label: '2010',
  //   },
  //   {
  //     value: 2020,
  //     label: '2020',
  //   },
  //   {
  //     value: 2030,
  //     label: '2030',
  //   },
  // ];

  const buildMarks = () => {
    const minYear = 1990;
    const marks = [];
    for (let i = 0; i <= 40; i += 1) {
      const currYear = minYear + i;
      if (currYear % 10 === 0) {
        marks.push({
          value: currYear,
          label: `${currYear}`,
        });
      } else {
        marks.push({
          value: currYear,
        });
      }
    }
    return marks;
  };

  const handleSlide = (e, v) => {
    setYear(v);
  };

  const handlePlay = () => {
    if (!play) {
      setCount(count + 1);
      setPlay(true);
    }
  };

  const handlePause = () => {
    if (play) setPlay(false);
  };

  const handleMovement = (dir) => {
    if (play) {
      setPlay(false);
    }
    if (dir === 'back') {
      if (year > 1990 && year <= 2030) {
        setYear(year - 1);
      } else {
        setYear(2030);
      }
    } else if (dir === 'forward') {
      if (year >= 1990 && year < 2030) {
        setYear(year + 1);
      } else {
        setYear(1990);
      }
    }
  };

  const getImageURL = () => `https://data.wildfirehazard.com/BP_${year}_scaled_alpha.png`;

  useEffect(() => {
    if (play) {
      let newYear;
      if (year >= 1990 && year < 2030) {
        newYear = year + 1;
      } else {
        newYear = 1990;
      }
      setYear(newYear);
      const intervalHandler = setInterval(() => setCount(count + 1), delay);
      return () => clearInterval(intervalHandler);
    }
    return undefined;
  }, [count]);

  return (
    <Series>
      <TitleContainer>
        <Title>
          {year}
          {' '}
          ANNUAL BURN PROBABILITY
        </Title>
        <InformationContainer>
          <InformationAlign>
            <Information
              setShowModal={setShowModal}
              modalIdentifier="bPSplash"
            />
          </InformationAlign>
        </InformationContainer>
      </TitleContainer>
      <FlexContainer>
        <ImageContainer>
          <StyledImage src={getImageURL()} alt={year} />
        </ImageContainer>
        <ChartContainer>
          <ChartSubContainer>
            <Chart
              year={year}
            />
          </ChartSubContainer>
          <ChartSubContainer>
            <ChartScatter
              year={year}
            />
          </ChartSubContainer>
        </ChartContainer>
      </FlexContainer>
      <SliderContainer>
        <div style={{ marginRight: '2rem' }}>
          <StyledButton onClick={() => handleMovement('back')}>
            <ArrowBackIosIcon
              style={{ color: themes.white }}
            />
          </StyledButton>
        </div>
        <StyledSlider
          aria-labelledby="Year slider"
          valueLabelDisplay="auto"
          step={1}
          min={1990}
          max={2030}
          value={year}
          onChange={(e, v) => { handleSlide(e, v); setPlay(false); }}
          marks={buildMarks()}
        />
        <div style={{ marginLeft: '2rem' }}>
          <StyledButton onClick={() => handleMovement('forward')}>
            <ArrowForwardIosIcon
              style={{ color: themes.white }}
            />
          </StyledButton>
        </div>
        <div style={{ marginLeft: '1rem' }}>
          <StyledButton onClick={() => handlePlay()}>
            <PlayArrowIcon style={{ color: themes.white }} />
          </StyledButton>
        </div>
        <div style={{ marginLeft: '1rem' }}>
          <StyledButton onClick={() => handlePause()}>
            <PauseIcon style={{ color: themes.white }} />
          </StyledButton>
        </div>
      </SliderContainer>
    </Series>
  );
}
