/* eslint-disable max-len */
/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';

const disclaimerText = (
  <span>
    Pyrologix provides the information at this site (www.wildfirehazard.com) (the “Site”) as a <b>PUBLIC SERVICE</b> for <b>NONCOMMERCIAL USES</b> only. The information is provided as-is, as-available, and without warranty of any kind, whether express, implied, or statutory, concerning the accuracy, completeness, reliability, or suitability of the information. Further, we do not warrant that this site will (i) be uninterrupted or error-free; or (ii) result in any desired outcome. We are not responsible for content of other services, data or public information that may be used by or linked to this site. By accessing this website and/or the data contained within, you assume the entire responsibility and liability related to the use of the information it contains, and you release Pyrologix and all data providers from liability. You may use the Site only for lawful purposes
    Users also should note that property boundaries included on any map do not represent an on-the-ground survey suitable for legal, engineering, or surveying purposes. They represent only the approximate relative locations.
    We reserve the right to withdraw or amend this Site, and any service or material we provide on the Site, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Site is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Site, or the entire Site to users or visitors to the site.
    The Site and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) are owned by Pyrologix, its licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. This site is operated by Pyrologix LLC, Missoula, MT.
  </span>
);

export default disclaimerText;
