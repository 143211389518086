import { UnknownString } from '../constants';
import fuelVegLut from './twoDigitFvLut';

// const fuelVegLut = {
//   11: 'Open Water',
//   12: 'Snow/Ice',
//   13: 'Developed-Upland Deciduous Forest',
//   14: 'Developed-Upland Evergreen Forest',
//   15: 'Developed-Upland Mixed Forest',
//   16: 'Developed-Upland Herbaceous',
//   17: 'Developed-Upland Shrubland',
//   18: 'Developed-Herbaceous Wetland Vegetation',
//   19: 'Developed-Woody Wetland Vegetation',
//   20: 'Developed-General',
//   21: 'Developed-Open Space',
//   22: 'Developed-Low Intensity',
//   23: 'Developed-Medium Intensity',
//   24: 'Developed-High Intensity',
//   25: 'Developed-Roads',
//   31: 'Barren',
//   32: 'Quarries-Strip Mines-Gravel Pits-Well and Wind Pads',
//   60: 'NASS-Orchards',
//   61: 'NASS-Vineyard',
//   62: 'NASS-Bush fruit and berries',
//   63: 'NASS-Row Crop-Close Grown Crop',
//   64: 'NASS-Row Crop',
//   65: 'NASS-Close Grown Crop',
//   66: 'NASS-Fallow/Idle Cropland',
//   67: 'NASS-Pasture and Hayland',
//   68: 'NASS-Wheat',
//   69: 'NASS-Aquaculture',
//   75: 'Herbaceous Semi-dry',
//   76: 'Herbaceous Semi-wet',
//   78: 'Recently Disturbed Forest',
//   80: 'Agriculture',
//   81: 'Pasture/Hay',
//   82: 'Cultivated Crops',
//   83: 'Small Grains',
//   84: 'Fallow',
//   85: 'Urban-Recreational Grasses',
//   95: 'Herbaceous Wetlands',
// };

const lhsHerbMap = {
  425: '0',
  475: '0.5',
  499: '1.0+',
};

const rhsHerbMap = {
  425: '0.5',
  475: '1.0',
  499: '1.0+',
};

const lhsShrubMap = {
  502: '0',
  507: '0.5',
  520: '1.0',
  530: '3.0+',
};

const rhsShrubMap = {
  502: '0.5',
  507: '1.0',
  520: '3.0',
  530: '3.0+',
};

const lhsForestMap = {
  603: '1.8',
  607: '5',
  611: '9',
  615: '13',
  619: '17',
  623: '21',
  627: '25',
  631: '29',
  635: '33',
  639: '37',
  643: '41',
  647: '45',
  651: '50+',
};

const rhsForestMap = {
  603: '5',
  607: '9',
  611: '13',
  615: '17',
  619: '21',
  623: '25',
  627: '29',
  631: '33',
  635: '37',
  639: '41',
  643: '45',
  647: '49',
  651: '50+',

};

export const decodeFvhCode = (lowCode, highCode) => {
  if (lowCode === false || lowCode === undefined) return UnknownString;
  if (fuelVegLut[lowCode]) {
    return fuelVegLut[lowCode];
  }

  if (lowCode === 100) {
    return 'Sparse Vegetation Height';
  }

  if (lowCode === 499) {
    return `Herb Height ${lhsHerbMap[lowCode]} m`;
  }

  if (lhsHerbMap[lowCode] && rhsHerbMap[highCode]) {
    if (lowCode > highCode) {
      return `Herb Height >${lhsHerbMap[lowCode]} m`;
    }

    return `Herb Height ${lhsHerbMap[lowCode]} - ${rhsHerbMap[highCode]} m`;
  }

  if (lowCode === 530) {
    return `Shrub Height ${lhsShrubMap[lowCode]} m`;
  }

  if (lhsShrubMap[lowCode] && rhsShrubMap[highCode]) {
    if (lowCode > highCode) {
      return `Shrub Height >${lhsShrubMap[lowCode]} m`;
    }

    return `Shrub Height ${lhsShrubMap[lowCode]} - ${rhsShrubMap[highCode]} m`;
  }

  if (lowCode === 651) {
    return `Forest Height ${lhsForestMap[lowCode]} m`;
  }

  if (lhsForestMap[lowCode] && rhsForestMap[highCode]) {
    if (lowCode > highCode) {
      return `Forest Height >${lhsForestMap[lowCode]} m`;
    }

    return `Forest Height ${lhsForestMap[lowCode]} - ${rhsForestMap[highCode]} m`;
  }

  return 'Unknown';
};

export default {
  decodeFvhCode,
};

// for (let i = 425; i <= 499; i += 1) {
//   for (let j = 425; j <= 499; j += 1) {
//     const resp = decodeFvhCode(i, j);
//     if (resp === 'Unknown') { continue; }
//     console.log(i, j, decodeFvhCode(i, j));
//   }
// }

// for (let i = 502; i <= 530; i += 1) {
//   for (let j = 502; j <= 530; j += 1) {
//     const resp = decodeFvhCode(i, j);
//     if (resp === 'Unknown') { continue; }
//     console.log(i, j, decodeFvhCode(i, j));
//   }
// }
// for (let i = 603; i <= 651; i += 1) {
//   for (let j = 603; j <= 651; j += 1) {
//     const resp = decodeFvhCode(i, j);
//     if (resp === 'Unknown') { continue; }
//     console.log(i, j, decodeFvhCode(i, j));
//   }
// }
