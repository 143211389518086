import styled from 'styled-components';
import ArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { MailOutline } from '@material-ui/icons';

const DownArrow = styled(ArrowDown)`
    font-size: 10pt;
    cursor: pointer;
    :hover {
        opacity: 0.5;
    }
`;

const RightArrow = styled(ArrowRight)`
    font-size: 10pt;
    cursor: pointer;
    :hover {
        opacity: 0.5;
    }
`;

const Messages = styled(MailOutline)`
    cursor: pointer;
    :hover {
        opacity: 0.5;
    }
`;

export default {
  'down-arrow': DownArrow,
  'right-arrow': RightArrow,
  messages: Messages,
};
