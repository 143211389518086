/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import styled from 'styled-components';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import Button from '@material-ui/core/Button';
import List from './list';
import themes from '../../styles/themes';

const MenuContainer = styled.div`
  height: 100%;
  width: 320px;
  z-index: 1000;
  position: fixed;
  top: 64px;
  background-color: ${themes.secondaryDark};
`;

const ListContainer = styled.div`
  padding-top: .5rem;
`;

const CarrotContainer = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: ${(props) => (props.menuIsShowing ? '320px' : '0px')};
  cursor: pointer;
  background-color: ${themes.mainDark};
  width: 30px;
  &:hover {
    background-color: ${themes.hoverMainDark};
  }
  border-radius: 0% ${themes.borderRadius} ${themes.borderRadius} 0%;
  box-shadow: ${themes.boxShadow};
`;

export default function newMenu(props) {
  const {
    setMenuIsShowing,
    menuIsShowing,
  } = props;

  return (
    <>
      { menuIsShowing && (
        <MenuContainer>
          <ListContainer>
            <List
              {...props}
            />
          </ListContainer>
        </MenuContainer>
      )}
      <CarrotContainer
        menuIsShowing={menuIsShowing}
        onClick={() => setMenuIsShowing(!menuIsShowing)}
      >
        <Button
          style={{
            color: themes.white, fontFamily: themes.fontFamily, width: '30px', minWidth: '0px', boxShadow: themes.boxShadow,
          }}
        >
          {
          menuIsShowing
            ? (
              <ArrowLeftIcon style={{ color: `${themes.white}`, fontSize: '2.5rem' }} />
            ) : (
              <ArrowRightIcon style={{ color: `${themes.white}`, fontSize: '2.5rem' }} />
            )
        }
        </Button>
      </CarrotContainer>
    </>
  );
}
