const caDPPercentiles = [
  {
    x: 5,
    y: 8.85e-05,
  },
  {
    x: 6,
    y: 0.005975078297779,
  },
  {
    x: 7,
    y: 0.049672418944538,
  },
  {
    x: 8,
    y: 0.230636368393898,
  },
  {
    x: 9,
    y: 0.918422271013259,
  },
  {
    x: 10,
    y: 11.0452392578125,
  },
  {
    x: 11,
    y: 13.0549081707001,
  },
  {
    x: 12,
    y: 13.6017122268677,
  },
  {
    x: 13,
    y: 13.6903365802765,
  },
  {
    x: 14,
    y: 13.9765729904175,
  },
  {
    x: 15,
    y: 14.3846486568451,
  },
  {
    x: 16,
    y: 14.7865943908691,
  },
  {
    x: 17,
    y: 15.0818036365509,
  },
  {
    x: 18,
    y: 15.4061794281006,
  },
  {
    x: 19,
    y: 15.7357177734375,
  },
  {
    x: 20,
    y: 16.0581741333008,
  },
  {
    x: 21,
    y: 16.3694343566895,
  },
  {
    x: 22,
    y: 16.6659297943115,
  },
  {
    x: 23,
    y: 16.9393272399902,
  },
  {
    x: 24,
    y: 17.1691627502441,
  },
  {
    x: 25,
    y: 17.3576774597168,
  },
  {
    x: 26,
    y: 17.5280113220215,
  },
  {
    x: 27,
    y: 17.6982574462891,
  },
  {
    x: 28,
    y: 17.8657245635986,
  },
  {
    x: 29,
    y: 18.0555538368225,
  },
  {
    x: 30,
    y: 18.2866706848145,
  },
  {
    x: 31,
    y: 18.5773811340332,
  },
  {
    x: 32,
    y: 18.9130001068115,
  },
  {
    x: 33,
    y: 19.2837162017822,
  },
  {
    x: 34,
    y: 19.6831798553467,
  },
  {
    x: 35,
    y: 20.1053886413574,
  },
  {
    x: 36,
    y: 20.5499458312988,
  },
  {
    x: 37,
    y: 21.0115509033203,
  },
  {
    x: 38,
    y: 21.4990921020508,
  },
  {
    x: 39,
    y: 22.0107116699219,
  },
  {
    x: 40,
    y: 22.5337791442871,
  },
  {
    x: 41,
    y: 23.0600490570068,
  },
  {
    x: 42,
    y: 23.5785713195801,
  },
  {
    x: 43,
    y: 24.078296661377,
  },
  {
    x: 44,
    y: 24.5706825256348,
  },
  {
    x: 45,
    y: 25.0616264343262,
  },
  {
    x: 46,
    y: 25.5558681488037,
  },
  {
    x: 47,
    y: 26.0456161499023,
  },
  {
    x: 48,
    y: 26.5205173492432,
  },
  {
    x: 49,
    y: 26.9619407653809,
  },
  {
    x: 50,
    y: 27.3881340026855,
  },
  {
    x: 51,
    y: 27.7954540252686,
  },
  {
    x: 52,
    y: 28.1828384399414,
  },
  {
    x: 53,
    y: 28.5744247436523,
  },
  {
    x: 54,
    y: 28.994384765625,
  },
  {
    x: 55,
    y: 29.4203433990479,
  },
  {
    x: 56,
    y: 29.8529930114746,
  },
  {
    x: 57,
    y: 30.2877922058105,
  },
  {
    x: 58,
    y: 30.7160167694092,
  },
  {
    x: 59,
    y: 31.1390151977539,
  },
  {
    x: 60,
    y: 31.5617492675781,
  },
  {
    x: 61,
    y: 31.9835815429688,
  },
  {
    x: 62,
    y: 32.4032211303711,
  },
  {
    x: 63,
    y: 32.8297500610352,
  },
  {
    x: 64,
    y: 33.2527770996094,
  },
  {
    x: 65,
    y: 33.6691207885742,
  },
  {
    x: 66,
    y: 34.0918960571289,
  },
  {
    x: 67,
    y: 34.5311241149902,
  },
  {
    x: 68,
    y: 34.9930813598635,
  },
  {
    x: 69,
    y: 35.4776000976562,
  },
  {
    x: 70,
    y: 35.9753456115723,
  },
  {
    x: 71,
    y: 36.4771995544434,
  },
  {
    x: 72,
    y: 36.9864273071289,
  },
  {
    x: 73,
    y: 37.5013885498047,
  },
  {
    x: 74,
    y: 38.015266418457,
  },
  {
    x: 75,
    y: 38.5328893661499,
  },
  {
    x: 76,
    y: 39.0752792358398,
  },
  {
    x: 77,
    y: 39.6725997924805,
  },
  {
    x: 78,
    y: 40.324089050293,
  },
  {
    x: 79,
    y: 41.0604400634766,
  },
  {
    x: 80,
    y: 41.8852844238281,
  },
  {
    x: 81,
    y: 42.8028030395508,
  },
  {
    x: 82,
    y: 43.8243827819824,
  },
  {
    x: 83,
    y: 44.9518051147461,
  },
  {
    x: 84,
    y: 46.1806259155273,
  },
  {
    x: 85,
    y: 47.5292892456055,
  },
  {
    x: 86,
    y: 49.0196838378906,
  },
  {
    x: 87,
    y: 50.6242127609253,
  },
  {
    x: 88,
    y: 52.3491363525391,
  },
  {
    x: 89,
    y: 54.1919937133789,
  },
  {
    x: 90,
    y: 56.1707805633546,
  },
  {
    x: 91,
    y: 58.2814254760742,
  },
  {
    x: 92,
    y: 60.5111923217773,
  },
  {
    x: 93,
    y: 62.8349990844727,
  },
  {
    x: 94,
    y: 65.2710800170898,
  },
  {
    x: 95,
    y: 67.8592681884766,
  },
  {
    x: 96,
    y: 70.685546875,
  },
  {
    x: 97,
    y: 73.8531188964844,
  },
  {
    x: 98,
    y: 77.5923919677734,
  },
  {
    x: 99,
    y: 82.5052032470703,
  },
  {
    x: 100,
    y: 101.716720581055,
  },
];

export default caDPPercentiles;
