const fuelVegLut = {
  11: 'Open Water',
  12: 'Snow/Ice',
  13: 'Developed-Upland Deciduous Forest',
  14: 'Developed-Upland Evergreen Forest',
  15: 'Developed-Upland Mixed Forest',
  16: 'Developed-Upland Herbaceous',
  17: 'Developed-Upland Shrubland',
  18: 'Developed-Herbaceous Wetland Vegetation',
  19: 'Developed-Woody Wetland Vegetation',
  20: 'Developed-General',
  21: 'Developed-Open Space',
  22: 'Developed-Low Intensity',
  23: 'Developed-Medium Intensity',
  24: 'Developed-High Intensity',
  25: 'Developed-Roads',
  31: 'Barren',
  32: 'Quarries-Strip Mines-Gravel Pits-Well and Wind Pads',
  60: 'NASS-Orchards',
  61: 'NASS-Vineyard',
  62: 'NASS-Bush fruit and berries',
  63: 'NASS-Row Crop-Close Grown Crop',
  64: 'NASS-Row Crop',
  65: 'NASS-Close Grown Crop',
  66: 'NASS-Fallow/Idle Cropland',
  67: 'NASS-Pasture and Hayland',
  68: 'NASS-Wheat',
  69: 'NASS-Aquaculture',
  75: 'Herbaceous Semi-dry',
  76: 'Herbaceous Semi-wet',
  78: 'Recently Disturbed Forest',
  80: 'Agriculture',
  81: 'Pasture/Hay',
  82: 'Cultivated Crops',
  83: 'Small Grains',
  84: 'Fallow',
  85: 'Urban-Recreational Grasses',
  95: 'Herbaceous Wetlands',
};

export default fuelVegLut;
