const baseURL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
  ? 'http://192.168.0.151:8001/'
  : 'https://lb.wildfirehazard.com/';

// const baseURL = 'https://lb.wildfirehazard.com/';
const baseURLs = [
  'https://a-lb.wildfirehazard.com/',
  'https://b-lb.wildfirehazard.com/',
  'https://c-lb.wildfirehazard.com/',
];

export { baseURL, baseURLs };
