import { decodeEvcCode } from './evc';
import { decodeEvhCode } from './evh';
import decodeFDIST from './fdist';
import { filterValue, numeric, round } from './number';
import { decodeFm40 } from './fm40';
import { formatCbh } from './cbh';
import { formatCbd } from './cbd';
import { formatCc } from './cc';
import { formatSlp } from './slp';
import { formatAsp } from './asp';
import { formatFl } from './fl';
import { formatRos } from './ros';
import { formatCh } from './ch';
import { formatBps } from './bps';
import { formatEvt } from './evt';
import { formatCg } from './cg';
import { formatBp } from './bp';
import { UnknownString } from '../constants';

/**
 * Format a number to a more easier-to-read probability
 * Will either return a string that is simply a rounded decimal number
 * or will return a string in the form of <rounded number> (percentage)
 * @param {numeric} val
 * @param {numeric} code (doesn't return % if code supplied)
 * @param {numeric} numDecimals (optional) - num decimals to round to (default: 2)
 * @returns {string}
 *
 * @example
 * ```
 * // without supplying code, shows the percentage
 * const f = formatProb(0.321321);
 * console.log(f); // '0.32 (32.21%)'
 *
 * // with supplying code, notice: no percentage
 * const l = formatProb(0.321321);
 * console.log(l); // '0.32'
 *
 * ```
 */
const formatProb = (val, code, numDecimals = 2) => {
  if (val === false || val === undefined) return UnknownString;
  if (code !== undefined) {
    if (val >= 0 && val <= 1) {
      return `${round(val, numDecimals)}`;
    }
  } else if (val >= 0 && val <= 1) {
    return `${round(val, numDecimals)} (${(val * 100).toFixed(2)}%)`;
  }

  return UnknownString;
};

export const decodeAttrPair = (key, val, code) => {
  switch (key) {
    case 'ch':
      return formatCh(val);
    case 'cbh':
      return formatCbh(val);
    case 'fm40':
      return decodeFm40(val);
    case 'evh':
      return decodeEvhCode(val);
    case 'evc':
      return decodeEvcCode(val);
    case 'fdist':
      return decodeFDIST(val);
    case 'cbd':
      return formatCbd(val);
    case 'cc':
      return formatCc(val);
    case 'slp':
      return formatSlp(val);
    case 'asp':
      return formatAsp(val);
    case 'fl':
      return formatFl(val);
    case 'wfl':
      return formatFl(val);
    case 'ros':
      return formatRos(val);
    case 'wros':
      return formatRos(val);
    case 'bps':
      return formatBps(val, code);
    case 'evt':
      return formatEvt(val, code);
    case 'cg':
      return formatCg(val);
    case 'ti':
      return filterValue(0, 1000, val, round);
    case 'ci':
      return filterValue(0, 1000, val, round);
    case 'ftp_any':
    case 'ftp_active':
    case 'ftp_passive':
    case 'ftp_underburn':
    case 'ftp_surface':
      return formatProb(val, code);
    case 'bp':
      return formatBp(val, code);
    default:
      break;
  }

  // if the key is not a known attribute then we will
  // default to checking if the value is numeric
  // and rounding accordingly
  if (numeric(val)) return round(val);

  return val;
};

export default {
  decodeAttrPair,
};
