import React from 'react';
import styled from 'styled-components';
import { Icon } from './Icon';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: #e5e5e5; */
  background-color: rgba(229, 229, 229, 0.4);
  border-radius: 5px;
  padding: 3px 5px;
  letter-spacing: 0.5px;
  font-weight: 300;
  cursor: pointer;
`;

export function CollapseHead(props) {
  const {
    label, style, onClick, collapsed,
  } = props;
  return (
    <Container style={style} onClick={() => onClick?.(label)}>
      {label}
      {collapsed && (
        <Icon icon="right-arrow" />
      )}
      {!collapsed && (
        <Icon icon="down-arrow" />
      )}
    </Container>
  );
}

export default CollapseHead;
