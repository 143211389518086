import { UnknownString } from '../constants';
import { round } from './number';

export const formatCbd = (val) => {
  if (val === false || val === undefined) return UnknownString;
  if (val >= 0 && val <= 45) {
    return `${round(val / 100, 2)} kg/m^3`;
  }

  return UnknownString;
};

export default {
  formatCbd,
};
