export const degToSubCardinal = (deg) => {
  if (deg >= 360 || deg < 0) {
    return 'N';
  }
  const dirs = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N'];
  const val = parseInt(((deg / 45) + 0.5), 10);
  return dirs[val];
};

export default {
  degToSubCardinal,
};
