const COAL2020BPPercentiles = [
  {
    x: 4,
    y: 9.99999974737875e-06,
  },
  {
    x: 5,
    y: 9.99999974737875e-06,
  },
  {
    x: 6,
    y: 1.2853437292506e-05,
  },
  {
    x: 7,
    y: 3.19691136246547e-05,
  },
  {
    x: 8,
    y: 5.36593452852685e-05,
  },
  {
    x: 9,
    y: 7.7095222659409e-05,
  },
  {
    x: 10,
    y: 0.000102921765937936,
  },
  {
    x: 11,
    y: 0.000131148161017336,
  },
  {
    x: 12,
    y: 0.000161235657287762,
  },
  {
    x: 13,
    y: 0.000192446731380187,
  },
  {
    x: 14,
    y: 0.000224483097554184,
  },
  {
    x: 15,
    y: 0.000257137842709199,
  },
  {
    x: 16,
    y: 0.000290174939436838,
  },
  {
    x: 17,
    y: 0.000323607324389741,
  },
  {
    x: 18,
    y: 0.000357199605787173,
  },
  {
    x: 19,
    y: 0.00039100696449168,
  },
  {
    x: 20,
    y: 0.000425648881355301,
  },
  {
    x: 21,
    y: 0.000461292411200702,
  },
  {
    x: 22,
    y: 0.000497607165016234,
  },
  {
    x: 23,
    y: 0.000534479056950659,
  },
  {
    x: 24,
    y: 0.000571282929740846,
  },
  {
    x: 25,
    y: 0.000607866968493909,
  },
  {
    x: 26,
    y: 0.000643990139942616,
  },
  {
    x: 27,
    y: 0.000678953889291734,
  },
  {
    x: 28,
    y: 0.000712560552638025,
  },
  {
    x: 29,
    y: 0.000745460914913565,
  },
  {
    x: 30,
    y: 0.000777462951373309,
  },
  {
    x: 31,
    y: 0.000809163795784116,
  },
  {
    x: 32,
    y: 0.000840397726278752,
  },
  {
    x: 33,
    y: 0.000871460302732885,
  },
  {
    x: 34,
    y: 0.000902474450413138,
  },
  {
    x: 35,
    y: 0.000933611008804291,
  },
  {
    x: 36,
    y: 0.000964595468249172,
  },
  {
    x: 37,
    y: 0.000995334354229271,
  },
  {
    x: 38,
    y: 0.0010261827846989,
  },
  {
    x: 39,
    y: 0.00105730583891273,
  },
  {
    x: 40,
    y: 0.00108860631007701,
  },
  {
    x: 41,
    y: 0.00111995707266033,
  },
  {
    x: 42,
    y: 0.0011515076039359,
  },
  {
    x: 43,
    y: 0.00118332973215729,
  },
  {
    x: 44,
    y: 0.0012154842261225,
  },
  {
    x: 45,
    y: 0.00124810170382261,
  },
  {
    x: 46,
    y: 0.00128116924315691,
  },
  {
    x: 47,
    y: 0.00131436577066779,
  },
  {
    x: 48,
    y: 0.0013478136388585,
  },
  {
    x: 49,
    y: 0.0013818551087752,
  },
  {
    x: 50,
    y: 0.00141609064303339,
  },
  {
    x: 51,
    y: 0.0014509002212435,
  },
  {
    x: 52,
    y: 0.00148649513721466,
  },
  {
    x: 53,
    y: 0.00152315455488861,
  },
  {
    x: 54,
    y: 0.00156117742881179,
  },
  {
    x: 55,
    y: 0.0016001098556444,
  },
  {
    x: 56,
    y: 0.00164004461839795,
  },
  {
    x: 57,
    y: 0.00168078730348498,
  },
  {
    x: 58,
    y: 0.0017227940261364,
  },
  {
    x: 59,
    y: 0.00176647026091814,
  },
  {
    x: 60,
    y: 0.00181146897375584,
  },
  {
    x: 61,
    y: 0.00185818702448159,
  },
  {
    x: 62,
    y: 0.00190645374823362,
  },
  {
    x: 63,
    y: 0.00195648241788149,
  },
  {
    x: 64,
    y: 0.00200846372172236,
  },
  {
    x: 65,
    y: 0.00206238497048616,
  },
  {
    x: 66,
    y: 0.00211846898309886,
  },
  {
    x: 67,
    y: 0.0021772317122668,
  },
  {
    x: 68,
    y: 0.00223908666521311,
  },
  {
    x: 69,
    y: 0.00230403593741357,
  },
  {
    x: 70,
    y: 0.00237125740386546,
  },
  {
    x: 71,
    y: 0.002441760385409,
  },
  {
    x: 72,
    y: 0.00251565547659993,
  },
  {
    x: 73,
    y: 0.00259332405403256,
  },
  {
    x: 74,
    y: 0.00267458194866776,
  },
  {
    x: 75,
    y: 0.00276004197075963,
  },
  {
    x: 76,
    y: 0.00285027711652219,
  },
  {
    x: 77,
    y: 0.00294452859088778,
  },
  {
    x: 78,
    y: 0.00304329441860318,
  },
  {
    x: 79,
    y: 0.00314686563797295,
  },
  {
    x: 80,
    y: 0.00325562963262201,
  },
  {
    x: 81,
    y: 0.00337003264576197,
  },
  {
    x: 82,
    y: 0.0034902305342257,
  },
  {
    x: 83,
    y: 0.00362026365473866,
  },
  {
    x: 84,
    y: 0.00375902559608221,
  },
  {
    x: 85,
    y: 0.00390551029704511,
  },
  {
    x: 86,
    y: 0.00406187726184726,
  },
  {
    x: 87,
    y: 0.00423229532316327,
  },
  {
    x: 88,
    y: 0.00441406248137355,
  },
  {
    x: 89,
    y: 0.0046153967268765,
  },
  {
    x: 90,
    y: 0.00483962846919894,
  },
  {
    x: 91,
    y: 0.00508576957508922,
  },
  {
    x: 92,
    y: 0.00535658840090036,
  },
  {
    x: 93,
    y: 0.00567636266350746,
  },
  {
    x: 94,
    y: 0.00604294007644057,
  },
  {
    x: 95,
    y: 0.00645601470023394,
  },
  {
    x: 96,
    y: 0.00691649690270424,
  },
  {
    x: 97,
    y: 0.00743816835805775,
  },
  {
    x: 98,
    y: 0.00806815456598997,
  },
  {
    x: 99,
    y: 0.00902866724878548,
  },
  {
    x: 100,
    y: 0.0160823240876198,
  },
];

export default COAL2020BPPercentiles;
