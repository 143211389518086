/* eslint-disable */

import React from 'react';
import Styled from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles, withStyles } from '@material-ui/core';
import themes from '../../../styles/themes';
// import Slider from '@material-ui/core/Slider';
// import OpacityIcon from '@material-ui/icons/Opacity';

const StyledCheckbox = withStyles({
  root: {
    color: themes.white,
    "&$checked": {
      color: themes.white,
    },
    "&$disabled": {
      color: themes.white
    },
    '&:hover, &$checked:hover': {
      backgroundColor: themes.whiteRGBA,
      color: themes.white,
    },
  },
  checked: {},
  disabled: {}
})(Checkbox);

const ListItemNoHover = Styled.li`
  list-style-type: none;
  width: 300px;
  padding-left: 30px;
  display: inline-block;
  // padding-top: 6px;
  // padding-bottom: 6px;
  font-family: ${themes.fontFamily}
`;

const ListItemContainer = Styled.div`
`;

const CheckboxLabel = Styled.span`
  color: ${themes.white};
  font-family: ${themes.fontFamily};
`

export default function ListItemCheck(props) {
  const {
    layer,
    // mapLayer,
    attribute,
    handleCheck
  } = props;

  // const handleSlider = (_e, v) => {
  //   mapLayer.setOpacity(v / 100);
  // };

  return (
    <ListItemContainer>
      <ListItemNoHover>
        <StyledCheckbox
          onClick={() => handleCheck(attribute)}
          checked={attribute.selected}
        />
        <CheckboxLabel>
          {layer.name}
        </CheckboxLabel>
      </ListItemNoHover>
      {/* { attribute.selected && (
        <div style={{ paddingLeft: '40px'}}>
        <ListItemNoHover>
          <OpacityIcon style={{ color: themes.white }} />
            <Slider
              disabled={!attribute.selected}
              defaultValue={mapLayer.getOpacity() * 100}
              style={{ marginLeft: '15px', width: '40%', color: themes.white }}
              onChange={(e, v) => { handleSlider(e, v); }}
              />
          </ListItemNoHover>
          </div>
      )}    */}
    </ListItemContainer>
  );
}
