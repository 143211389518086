import { round } from './number';
import { UnknownString } from '../constants';

export const formatCh = (val) => {
  if (val === false || val === undefined) return UnknownString;
  if (val >= 0 && val <= 510) {
    return `${round(val / 10, 1)} m`;
  }

  return UnknownString;
};

export default {
  formatCh,
};
