export default class Project {
  constructor(props) {
    this.abbreviation = props.abbreviation;
    this.title = props.title;
    this.baseLayers = props.baseLayers || [];
    this.datasets = props.datasets || [];
    this.referenceLayers = props.referenceLayers || [];
    this.center = props.center;
    this.zoom = props.zoom;
    this.defaultHazardOpacity = 0.9;
    this.maxZoom = props.maxZoom;
    this.hazardLayerHeaderName = props.hazardLayerHeaderName || 'Hazard Layers';
    this.referenceLayerheaderName = props.referenceLayerheaderName || 'Reference Layers';
  }
}
