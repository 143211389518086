export default class S3API {
  constructor() {
    this.baseURL = S3API.defineBaseURL();
  }

  // Remember to check caching on this
  async get(subPath) {
    const url = `${this.baseURL}${subPath}`;
    const resp = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
      .catch(() => {});
    return resp;
  }

  static defineBaseURL() {
    return 'https://data.wildfirehazard.com/';
    // return 'http://wildfirehazard.com-site-data.s3.amazonaws.com/';
  }
}
