import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import GeoJSON from 'ol/format/GeoJSON';
import { Fill, Stroke, Style } from 'ol/style';
import { tile as tileStrategy } from 'ol/loadingstrategy';
import { createXYZ } from 'ol/tilegrid';
import { transformExtent } from 'ol/proj';

const style = new Style({
  fill: new Fill({
    color: 'rgba(90, 121, 255, .9)',
  }),
  stroke: new Stroke({
    color: 'rgba(11, 9, 159, 1)',
    width: 1,
  }),
});

const serviceURL = 'https://services3.arcgis.com/T4QMspbfLg3qTGWY/arcgis/rest/services/Current_WildlandFire_Perimeters/FeatureServer/';

const vectorSource = new VectorSource({
  loader: async (extent) => {
    const newExtent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
    const url = `${`${`${serviceURL
    }0`
    }/query?out=geojson&`
    + 'returnGeometry=true&spatialRel=esriSpatialRelIntersects&outFields=poly_IncidentName&geometry='}${
      encodeURIComponent(
        `{"xmin":${
          newExtent[0]
        },"ymin":${
          newExtent[1]
        },"xmax":${
          newExtent[2]
        },"ymax":${
          newExtent[3]
        },"spatialReference":{"wkid":4326}}`,
      )}`
      + '&geometryType=esriGeometryEnvelope&inSR=4326&outSR=3857&where=1%3D1&f=geojson';
    await fetch(url, {
      method: 'GET',
    }).then((response) => response.json()).then((json) => {
      // This can overload API quota, not sure how to handle this right now
      // just ignoring for now.
      const features = new GeoJSON().readFeatures(json);
      if (features.length > 0) {
        vectorSource.addFeatures(features);
      }
    }).catch(() => {});
  },
  strategy: tileStrategy(
    createXYZ({
      tileSize: 512,
    }),
  ),
});

const layer = new VectorLayer({
  source: vectorSource,
  style,
  minZoom: 7,
});

export default layer;
