const data = [
  [
    {
      x: 1,
      y: 15228.3713000933,
    },
    {
      x: 2,
      y: 28123.3391967483,
    },
    {
      x: 3,
      y: 71949.9037233571,
    },
    {
      x: 4,
      y: 153793.073345886,
    },
    {
      x: 5,
      y: 259786.500135852,
    },
    {
      x: 6,
      y: 326803.948707136,
    },
    {
      x: 7,
      y: 441509.00148452,
    },
    {
      x: 8,
      y: 425705.729596305,
    },
    {
      x: 9,
      y: 386017.350126401,
    },
    {
      x: 10,
      y: 91018.9126547563,
    },
    {
      x: 11,
      y: 326.445898971707,
    },
  ],
  [
    {
      x: 1,
      y: 15015.9344202991,
    },
    {
      x: 2,
      y: 27067.2093870158,
    },
    {
      x: 3,
      y: 70152.6969812863,
    },
    {
      x: 4,
      y: 155600.745888598,
    },
    {
      x: 5,
      y: 276798.213091267,
    },
    {
      x: 6,
      y: 354426.381369027,
    },
    {
      x: 7,
      y: 465855.850294313,
    },
    {
      x: 8,
      y: 443930.93966054,
    },
    {
      x: 9,
      y: 401106.797139296,
    },
    {
      x: 10,
      y: 97736.9380173823,
    },
    {
      x: 11,
      y: 285.691466312632,
    },
  ],
  [
    {
      x: 1,
      y: 14577.9025239753,
    },
    {
      x: 2,
      y: 25916.9867585087,
    },
    {
      x: 3,
      y: 69722.5187517005,
    },
    {
      x: 4,
      y: 153968.743285546,
    },
    {
      x: 5,
      y: 283231.474257585,
    },
    {
      x: 6,
      y: 391858.996465347,
    },
    {
      x: 7,
      y: 495166.782478409,
    },
    {
      x: 8,
      y: 463994.187682951,
    },
    {
      x: 9,
      y: 416225.285338662,
    },
    {
      x: 10,
      y: 105174.273006135,
    },
    {
      x: 11,
      y: 258.602070952281,
    },
  ],
  [
    {
      x: 1,
      y: 13706.3757773079,
    },
    {
      x: 2,
      y: 25384.1416102444,
    },
    {
      x: 3,
      y: 67574.358435846,
    },
    {
      x: 4,
      y: 155285.906323025,
    },
    {
      x: 5,
      y: 292808.512221035,
    },
    {
      x: 6,
      y: 432768.144059335,
    },
    {
      x: 7,
      y: 530568.051938891,
    },
    {
      x: 8,
      y: 487442.093317227,
    },
    {
      x: 9,
      y: 431891.74614186,
    },
    {
      x: 10,
      y: 113992.201608403,
    },
    {
      x: 11,
      y: 226.698817112668,
    },
  ],
  [
    {
      x: 1,
      y: 13311.7244279961,
    },
    {
      x: 2,
      y: 22529.2411421526,
    },
    {
      x: 3,
      y: 65765.1351510086,
    },
    {
      x: 4,
      y: 157006.338230543,
    },
    {
      x: 5,
      y: 303733.513035106,
    },
    {
      x: 6,
      y: 463876.694274437,
    },
    {
      x: 7,
      y: 583369.564705563,
    },
    {
      x: 8,
      y: 514271.423086512,
    },
    {
      x: 9,
      y: 445891.309766124,
    },
    {
      x: 10,
      y: 124696.177135906,
    },
    {
      x: 11,
      y: 189.984450888933,
    },
  ],
  [
    {
      x: 1,
      y: 13223.9779377075,
    },
    {
      x: 2,
      y: 20902.9333108471,
    },
    {
      x: 3,
      y: 64507.0664627854,
    },
    {
      x: 4,
      y: 153906.287462828,
    },
    {
      x: 5,
      y: 323844.199276733,
    },
    {
      x: 6,
      y: 502312.791013605,
    },
    {
      x: 7,
      y: 654088.777706412,
    },
    {
      x: 8,
      y: 541837.551331099,
    },
    {
      x: 9,
      y: 461174.625171139,
    },
    {
      x: 10,
      y: 135002.928063041,
    },
    {
      x: 11,
      y: 209.343145094887,
    },
  ],
  [
    {
      x: 1,
      y: 12832.4122524391,
    },
    {
      x: 2,
      y: 21051.9048758541,
    },
    {
      x: 3,
      y: 60664.0892077159,
    },
    {
      x: 4,
      y: 150984.589673569,
    },
    {
      x: 5,
      y: 329027.698185522,
    },
    {
      x: 6,
      y: 547235.135171995,
    },
    {
      x: 7,
      y: 726387.771835608,
    },
    {
      x: 8,
      y: 578335.725938448,
    },
    {
      x: 9,
      y: 477281.913687435,
    },
    {
      x: 10,
      y: 145815.530664825,
    },
    {
      x: 11,
      y: 440.652041641847,
    },
  ],
  [
    {
      x: 1,
      y: 12315.5558173634,
    },
    {
      x: 2,
      y: 21217.4344699347,
    },
    {
      x: 3,
      y: 58240.7212457806,
    },
    {
      x: 4,
      y: 149740.31067703,
    },
    {
      x: 5,
      y: 326542.827510364,
    },
    {
      x: 6,
      y: 570708.05835381,
    },
    {
      x: 7,
      y: 808678.242383872,
    },
    {
      x: 8,
      y: 629065.064312421,
    },
    {
      x: 9,
      y: 494349.911293873,
    },
    {
      x: 10,
      y: 157309.59914668,
    },
    {
      x: 11,
      y: 936.643636274562,
    },
  ],
  [
    {
      x: 1,
      y: 11874.6716346585,
    },
    {
      x: 2,
      y: 20589.2097020672,
    },
    {
      x: 3,
      y: 57748.8813014134,
    },
    {
      x: 4,
      y: 145578.931106808,
    },
    {
      x: 5,
      y: 328315.413256216,
    },
    {
      x: 6,
      y: 583587.662862783,
    },
    {
      x: 7,
      y: 879977.290536975,
    },
    {
      x: 8,
      y: 697274.306667273,
    },
    {
      x: 9,
      y: 512004.305625998,
    },
    {
      x: 10,
      y: 169614.28826128,
    },
    {
      x: 11,
      y: 1586.35159179233,
    },
  ],
  [
    {
      x: 1,
      y: 12111.6687051406,
    },
    {
      x: 2,
      y: 19473.3957789961,
    },
    {
      x: 3,
      y: 55993.5515395093,
    },
    {
      x: 4,
      y: 146111.865565101,
    },
    {
      x: 5,
      y: 325195.043678327,
    },
    {
      x: 6,
      y: 591099.470135049,
    },
    {
      x: 7,
      y: 943918.25963291,
    },
    {
      x: 8,
      y: 776452.985476081,
    },
    {
      x: 9,
      y: 531776.360308268,
    },
    {
      x: 10,
      y: 182564.293811279,
    },
    {
      x: 11,
      y: 2501.36232816949,
    },
  ],
  [
    {
      x: 1,
      y: 12043.4185789661,
    },
    {
      x: 2,
      y: 19101.876109533,
    },
    {
      x: 3,
      y: 55234.3026987319,
    },
    {
      x: 4,
      y: 144544.537574233,
    },
    {
      x: 5,
      y: 324245.05619596,
    },
    {
      x: 6,
      y: 598418.075218715,
    },
    {
      x: 7,
      y: 990098.397814,
    },
    {
      x: 8,
      y: 868416.821416676,
    },
    {
      x: 9,
      y: 553368.362600342,
    },
    {
      x: 10,
      y: 196974.444304212,
    },
    {
      x: 11,
      y: 3799.90834631056,
    },
  ],
  [
    {
      x: 1,
      y: 12181.0992849437,
    },
    {
      x: 2,
      y: 18142.3730669845,
    },
    {
      x: 3,
      y: 54991.8754403431,
    },
    {
      x: 4,
      y: 140100.409913985,
    },
    {
      x: 5,
      y: 325244.001466972,
    },
    {
      x: 6,
      y: 601763.896728358,
    },
    {
      x: 7,
      y: 1023948.03537906,
    },
    {
      x: 8,
      y: 972940.732375242,
    },
    {
      x: 9,
      y: 578857.092753947,
    },
    {
      x: 10,
      y: 211873.434712664,
    },
    {
      x: 11,
      y: 5249.19218522363,
    },
  ],
  [
    {
      x: 1,
      y: 11872.8810588698,
    },
    {
      x: 2,
      y: 18175.4763541334,
    },
    {
      x: 3,
      y: 52449.0899737017,
    },
    {
      x: 4,
      y: 140332.738535728,
    },
    {
      x: 5,
      y: 321504.429417301,
    },
    {
      x: 6,
      y: 606957.350341184,
    },
    {
      x: 7,
      y: 1054242.68974074,
    },
    {
      x: 8,
      y: 1074181.20533678,
    },
    {
      x: 9,
      y: 610195.255719278,
    },
    {
      x: 10,
      y: 227862.579144368,
    },
    {
      x: 11,
      y: 6565.39190822207,
    },
  ],
  [
    {
      x: 1,
      y: 11488.2119611377,
    },
    {
      x: 2,
      y: 19030.284445512,
    },
    {
      x: 3,
      y: 51689.8963244763,
    },
    {
      x: 4,
      y: 137122.810955713,
    },
    {
      x: 5,
      y: 324388.148412563,
    },
    {
      x: 6,
      y: 605751.606717327,
    },
    {
      x: 7,
      y: 1079087.66872737,
    },
    {
      x: 8,
      y: 1174260.98676,
    },
    {
      x: 9,
      y: 648185.480462571,
    },
    {
      x: 10,
      y: 244385.953872466,
    },
    {
      x: 11,
      y: 7994.98297868118,
    },
  ],
  [
    {
      x: 1,
      y: 10880.2371755587,
    },
    {
      x: 2,
      y: 18474.8206318874,
    },
    {
      x: 3,
      y: 51553.0457612333,
    },
    {
      x: 4,
      y: 134002.586119665,
    },
    {
      x: 5,
      y: 324268.273297621,
    },
    {
      x: 6,
      y: 602545.26066964,
    },
    {
      x: 7,
      y: 1103744.93971288,
    },
    {
      x: 8,
      y: 1273434.50832862,
    },
    {
      x: 9,
      y: 691784.057047504,
    },
    {
      x: 10,
      y: 262203.534510815,
    },
    {
      x: 11,
      y: 9541.70956897072,
    },
  ],
  [
    {
      x: 1,
      y: 10176.8336325315,
    },
    {
      x: 2,
      y: 19425.7601920491,
    },
    {
      x: 3,
      y: 49119.1975925852,
    },
    {
      x: 4,
      y: 132644.969265999,
    },
    {
      x: 5,
      y: 323587.027389401,
    },
    {
      x: 6,
      y: 605069.426763996,
    },
    {
      x: 7,
      y: 1121331.59367855,
    },
    {
      x: 8,
      y: 1360514.72384128,
    },
    {
      x: 9,
      y: 746763.476561672,
    },
    {
      x: 10,
      y: 281098.750887673,
    },
    {
      x: 11,
      y: 11748.1586174585,
    },
  ],
  [
    {
      x: 1,
      y: 10454.172529194,
    },
    {
      x: 2,
      y: 18787.924356951,
    },
    {
      x: 3,
      y: 47699.0096577624,
    },
    {
      x: 4,
      y: 134716.130424003,
    },
    {
      x: 5,
      y: 321749.151732495,
    },
    {
      x: 6,
      y: 599390.041485383,
    },
    {
      x: 7,
      y: 1142285.52959671,
    },
    {
      x: 8,
      y: 1438509.22646441,
    },
    {
      x: 9,
      y: 811589.284147887,
    },
    {
      x: 10,
      y: 300492.885723442,
    },
    {
      x: 11,
      y: 14853.5071075162,
    },
  ],
  [
    {
      x: 1,
      y: 10561.6202275191,
    },
    {
      x: 2,
      y: 17261.0171286735,
    },
    {
      x: 3,
      y: 49097.3255329681,
    },
    {
      x: 4,
      y: 131988.227621625,
    },
    {
      x: 5,
      y: 321432.65511645,
    },
    {
      x: 6,
      y: 602299.807951424,
    },
    {
      x: 7,
      y: 1154163.28445418,
    },
    {
      x: 8,
      y: 1507795.36913733,
    },
    {
      x: 9,
      y: 885127.939249049,
    },
    {
      x: 10,
      y: 320945.139331619,
    },
    {
      x: 11,
      y: 18901.4196100608,
    },
  ],
  [
    {
      x: 1,
      y: 10752.0013991292,
    },
    {
      x: 2,
      y: 16449.413817018,
    },
    {
      x: 3,
      y: 48386.5321055486,
    },
    {
      x: 4,
      y: 130407.357815999,
    },
    {
      x: 5,
      y: 321624.272146611,
    },
    {
      x: 6,
      y: 603031.504311614,
    },
    {
      x: 7,
      y: 1166345.38079199,
    },
    {
      x: 8,
      y: 1568300.3422287,
    },
    {
      x: 9,
      y: 967738.89609885,
    },
    {
      x: 10,
      y: 342349.163476015,
    },
    {
      x: 11,
      y: 23235.8877446644,
    },
  ],
  [
    {
      x: 1,
      y: 10859.7598632799,
    },
    {
      x: 2,
      y: 16916.4937193441,
    },
    {
      x: 3,
      y: 47525.5170364464,
    },
    {
      x: 4,
      y: 125630.351399357,
    },
    {
      x: 5,
      y: 322917.948455999,
    },
    {
      x: 6,
      y: 601877.764121273,
    },
    {
      x: 7,
      y: 1176986.98121131,
    },
    {
      x: 8,
      y: 1622829.61266029,
    },
    {
      x: 9,
      y: 1059146.68941891,
    },
    {
      x: 10,
      y: 365073.172298009,
    },
    {
      x: 11,
      y: 27903.4058160707,
    },
  ],
  [
    {
      x: 1,
      y: 10756.5396245748,
    },
    {
      x: 2,
      y: 16075.2380510067,
    },
    {
      x: 3,
      y: 46999.6864015855,
    },
    {
      x: 4,
      y: 127286.239582426,
    },
    {
      x: 5,
      y: 319018.888620545,
    },
    {
      x: 6,
      y: 601136.426742785,
    },
    {
      x: 7,
      y: 1185486.39629136,
    },
    {
      x: 8,
      y: 1675378.68642804,
    },
    {
      x: 9,
      y: 1152906.07099983,
    },
    {
      x: 10,
      y: 388056.204097159,
    },
    {
      x: 11,
      y: 33614.2667197561,
    },
  ],
  [
    {
      x: 1,
      y: 10935.6954604151,
    },
    {
      x: 2,
      y: 16187.6566172228,
    },
    {
      x: 3,
      y: 45246.4805807097,
    },
    {
      x: 4,
      y: 128395.561196112,
    },
    {
      x: 5,
      y: 313314.041291066,
    },
    {
      x: 6,
      y: 605049.679332818,
    },
    {
      x: 7,
      y: 1193364.44363527,
    },
    {
      x: 8,
      y: 1721273.96049775,
    },
    {
      x: 9,
      y: 1248597.60548275,
    },
    {
      x: 10,
      y: 413067.431787639,
    },
    {
      x: 11,
      y: 40329.0240714362,
    },
  ],
  [
    {
      x: 1,
      y: 11063.4660482457,
    },
    {
      x: 2,
      y: 16010.1730072485,
    },
    {
      x: 3,
      y: 43999.8806594452,
    },
    {
      x: 4,
      y: 127375.127444085,
    },
    {
      x: 5,
      y: 314087.706793787,
    },
    {
      x: 6,
      y: 601597.101469613,
    },
    {
      x: 7,
      y: 1200576.22262762,
    },
    {
      x: 8,
      y: 1765553.60974396,
    },
    {
      x: 9,
      y: 1346549.54857977,
    },
    {
      x: 10,
      y: 439955.125054343,
    },
    {
      x: 11,
      y: 48040.5666115944,
    },
  ],
  [
    {
      x: 1,
      y: 10674.855197611,
    },
    {
      x: 2,
      y: 16319.4464323241,
    },
    {
      x: 3,
      y: 44440.647692654,
    },
    {
      x: 4,
      y: 122161.597104949,
    },
    {
      x: 5,
      y: 314624.847913118,
    },
    {
      x: 6,
      y: 604562.863084302,
    },
    {
      x: 7,
      y: 1201262.96094042,
    },
    {
      x: 8,
      y: 1810624.76475817,
    },
    {
      x: 9,
      y: 1443004.41530096,
    },
    {
      x: 10,
      y: 471114.577695104,
    },
    {
      x: 11,
      y: 55064.491030036,
    },
  ],
  [
    {
      x: 1,
      y: 10621.9803939078,
    },
    {
      x: 2,
      y: 15765.8173067019,
    },
    {
      x: 3,
      y: 45755.937568103,
    },
    {
      x: 4,
      y: 122525.769031735,
    },
    {
      x: 5,
      y: 309483.165382409,
    },
    {
      x: 6,
      y: 608881.227096686,
    },
    {
      x: 7,
      y: 1200975.62776237,
    },
    {
      x: 8,
      y: 1855840.82185886,
    },
    {
      x: 9,
      y: 1536620.9348732,
    },
    {
      x: 10,
      y: 504473.951207319,
    },
    {
      x: 11,
      y: 61957.1832932186,
    },
  ],
  [
    {
      x: 1,
      y: 10479.6368953539,
    },
    {
      x: 2,
      y: 15998.6327039695,
    },
    {
      x: 3,
      y: 46501.638306656,
    },
    {
      x: 4,
      y: 121129.855113648,
    },
    {
      x: 5,
      y: 303421.484450049,
    },
    {
      x: 6,
      y: 613024.444985842,
    },
    {
      x: 7,
      y: 1203297.96348807,
    },
    {
      x: 8,
      y: 1895986.22542725,
    },
    {
      x: 9,
      y: 1632103.98209239,
    },
    {
      x: 10,
      y: 540744.424856193,
    },
    {
      x: 11,
      y: 69261.0695942511,
    },
  ],
  [
    {
      x: 1,
      y: 10186.3215061309,
    },
    {
      x: 2,
      y: 16472.0397891379,
    },
    {
      x: 3,
      y: 43347.9311322653,
    },
    {
      x: 4,
      y: 119870.00894946,
    },
    {
      x: 5,
      y: 309632.281385037,
    },
    {
      x: 6,
      y: 607437.08090327,
    },
    {
      x: 7,
      y: 1204267.54400225,
    },
    {
      x: 8,
      y: 1933134.94608692,
    },
    {
      x: 9,
      y: 1729996.255072,
    },
    {
      x: 10,
      y: 579856.564004486,
    },
    {
      x: 11,
      y: 76795.3306279651,
    },
  ],
  [
    {
      x: 1,
      y: 10283.5112452093,
    },
    {
      x: 2,
      y: 16260.7407074685,
    },
    {
      x: 3,
      y: 43697.1805120046,
    },
    {
      x: 4,
      y: 118709.682307218,
    },
    {
      x: 5,
      y: 301176.701902708,
    },
    {
      x: 6,
      y: 617015.580102148,
    },
    {
      x: 7,
      y: 1201895.59517303,
    },
    {
      x: 8,
      y: 1971455.12934148,
    },
    {
      x: 9,
      y: 1822798.89833682,
    },
    {
      x: 10,
      y: 621916.786151911,
    },
    {
      x: 11,
      y: 84833.4382762053,
    },
  ],
  [
    {
      x: 1,
      y: 10284.307306576,
    },
    {
      x: 2,
      y: 16167.1346452058,
    },
    {
      x: 3,
      y: 44759.8831928508,
    },
    {
      x: 4,
      y: 115653.38085575,
    },
    {
      x: 5,
      y: 301654.191028198,
    },
    {
      x: 6,
      y: 614776.530771991,
    },
    {
      x: 7,
      y: 1203175.02417433,
    },
    {
      x: 8,
      y: 2007027.39223228,
    },
    {
      x: 9,
      y: 1913926.98248471,
    },
    {
      x: 10,
      y: 668298.489444969,
    },
    {
      x: 11,
      y: 93366.871649848,
    },
  ],
  [
    {
      x: 1,
      y: 10384.9488700116,
    },
    {
      x: 2,
      y: 16381.021827444,
    },
    {
      x: 3,
      y: 42711.7444156901,
    },
    {
      x: 4,
      y: 114782.209427599,
    },
    {
      x: 5,
      y: 302735.360894064,
    },
    {
      x: 6,
      y: 614877.2768973,
    },
    {
      x: 7,
      y: 1205337.29884286,
    },
    {
      x: 8,
      y: 2031890.47751825,
    },
    {
      x: 9,
      y: 2009169.73945451,
    },
    {
      x: 10,
      y: 716922.812129218,
    },
    {
      x: 11,
      y: 102944.240507112,
    },
  ],
  [
    {
      x: 1,
      y: 10284.0335775858,
    },
    {
      x: 2,
      y: 15813.5026540604,
    },
    {
      x: 3,
      y: 43494.644406487,
    },
    {
      x: 4,
      y: 114784.953425583,
    },
    {
      x: 5,
      y: 291794.54557379,
    },
    {
      x: 6,
      y: 627780.933876178,
    },
    {
      x: 7,
      y: 1191864.58236218,
    },
    {
      x: 8,
      y: 2069116.95749654,
    },
    {
      x: 9,
      y: 2097554.75014151,
    },
    {
      x: 10,
      y: 771135.080272033,
    },
    {
      x: 11,
      y: 113560.094034732,
    },
  ],
  [
    {
      x: 1,
      y: 10403.8782564265,
    },
    {
      x: 2,
      y: 15982.2448415108,
    },
    {
      x: 3,
      y: 43103.3404405198,
    },
    {
      x: 4,
      y: 113356.859243621,
    },
    {
      x: 5,
      y: 294878.856998612,
    },
    {
      x: 6,
      y: 623461.354024382,
    },
    {
      x: 7,
      y: 1195033.47604625,
    },
    {
      x: 8,
      y: 2097477.02797774,
    },
    {
      x: 9,
      y: 2177931.31693797,
    },
    {
      x: 10,
      y: 829217.294794336,
    },
    {
      x: 11,
      y: 125385.372240494,
    },
  ],
  [
    {
      x: 1,
      y: 10485.0891727691,
    },
    {
      x: 2,
      y: 15988.7665062262,
    },
    {
      x: 3,
      y: 43659.9796925536,
    },
    {
      x: 4,
      y: 109543.5171981,
    },
    {
      x: 5,
      y: 298382.797534268,
    },
    {
      x: 6,
      y: 623924.403970826,
    },
    {
      x: 7,
      y: 1183282.60682959,
    },
    {
      x: 8,
      y: 2131428.72655048,
    },
    {
      x: 9,
      y: 2261337.9501426,
    },
    {
      x: 10,
      y: 889040.462805829,
    },
    {
      x: 11,
      y: 138203.663181274,
    },
  ],
  [
    {
      x: 1,
      y: 10594.3695563466,
    },
    {
      x: 2,
      y: 16243.9517667966,
    },
    {
      x: 3,
      y: 40706.1436480553,
    },
    {
      x: 4,
      y: 113419.738441225,
    },
    {
      x: 5,
      y: 295540.161541564,
    },
    {
      x: 6,
      y: 626236.674289379,
    },
    {
      x: 7,
      y: 1180942.97079901,
    },
    {
      x: 8,
      y: 2155961.84112488,
    },
    {
      x: 9,
      y: 2338289.09040084,
    },
    {
      x: 10,
      y: 954762.387849872,
    },
    {
      x: 11,
      y: 151627.578668173,
    },
  ],
  [
    {
      x: 1,
      y: 10325.901048393,
    },
    {
      x: 2,
      y: 15561.0571107466,
    },
    {
      x: 3,
      y: 38906.0250060317,
    },
    {
      x: 4,
      y: 113056.534859312,
    },
    {
      x: 5,
      y: 292087.795333271,
    },
    {
      x: 6,
      y: 625398.512003726,
    },
    {
      x: 7,
      y: 1184029.32972934,
    },
    {
      x: 8,
      y: 2174409.64516086,
    },
    {
      x: 9,
      y: 2420332.54867975,
    },
    {
      x: 10,
      y: 1023079.95164674,
    },
    {
      x: 11,
      y: 166184.552978853,
    },
  ],
  [
    {
      x: 1,
      y: 10423.0484609038,
    },
    {
      x: 2,
      y: 15571.863799654,
    },
    {
      x: 3,
      y: 39179.151896628,
    },
    {
      x: 4,
      y: 112663.47187491,
    },
    {
      x: 5,
      y: 288772.625496568,
    },
    {
      x: 6,
      y: 626754.475290071,
    },
    {
      x: 7,
      y: 1180869.74395893,
    },
    {
      x: 8,
      y: 2201176.24828038,
    },
    {
      x: 9,
      y: 2486477.78886204,
    },
    {
      x: 10,
      y: 1099024.51487668,
    },
    {
      x: 11,
      y: 181505.85784858,
    },
  ],
  [
    {
      x: 1,
      y: 10524.06855273,
    },
    {
      x: 2,
      y: 15725.3631890339,
    },
    {
      x: 3,
      y: 39145.8244471427,
    },
    {
      x: 4,
      y: 111257.641205254,
    },
    {
      x: 5,
      y: 291322.398034245,
    },
    {
      x: 6,
      y: 628207.965738179,
    },
    {
      x: 7,
      y: 1170827.21569983,
    },
    {
      x: 8,
      y: 2222858.25668888,
    },
    {
      x: 9,
      y: 2556559.23439542,
    },
    {
      x: 10,
      y: 1176518.57834498,
    },
    {
      x: 11,
      y: 198519.198358396,
    },
  ],
  [
    {
      x: 1,
      y: 10194.8104190017,
    },
    {
      x: 2,
      y: 16265.4334666816,
    },
    {
      x: 3,
      y: 39407.1857021221,
    },
    {
      x: 4,
      y: 110221.618302415,
    },
    {
      x: 5,
      y: 289742.01517185,
    },
    {
      x: 6,
      y: 629742.727228242,
    },
    {
      x: 7,
      y: 1166520.02422917,
    },
    {
      x: 8,
      y: 2245988.03856523,
    },
    {
      x: 9,
      y: 2617577.11340453,
    },
    {
      x: 10,
      y: 1259165.5036602,
    },
    {
      x: 11,
      y: 215688.2135579,
    },
  ],
  [
    {
      x: 1,
      y: 10298.4512535349,
    },
    {
      x: 2,
      y: 16412.4482372382,
    },
    {
      x: 3,
      y: 38695.9677443992,
    },
    {
      x: 4,
      y: 109148.281950573,
    },
    {
      x: 5,
      y: 285772.078116674,
    },
    {
      x: 6,
      y: 637375.553990357,
    },
    {
      x: 7,
      y: 1157915.3140764,
    },
    {
      x: 8,
      y: 2270457.72988704,
    },
    {
      x: 9,
      y: 2670537.78723042,
    },
    {
      x: 10,
      y: 1348484.96158044,
    },
    {
      x: 11,
      y: 234461.051302329,
    },
  ],
  [
    {
      x: 1,
      y: 10467.4755203772,
    },
    {
      x: 2,
      y: 16121.5195574956,
    },
    {
      x: 3,
      y: 38093.3237061514,
    },
    {
      x: 4,
      y: 108176.811043318,
    },
    {
      x: 5,
      y: 280489.848702844,
    },
    {
      x: 6,
      y: 637122.055416481,
    },
    {
      x: 7,
      y: 1158660.16150889,
    },
    {
      x: 8,
      y: 2290280.88772715,
    },
    {
      x: 9,
      y: 2731993.39077508,
    },
    {
      x: 10,
      y: 1433089.81568239,
    },
    {
      x: 11,
      y: 254111.289753527,
    },
  ],
  [
    {
      x: 1,
      y: 10559.9385897401,
    },
    {
      x: 2,
      y: 16084.8609275671,
    },
    {
      x: 3,
      y: 36702.4166959452,
    },
    {
      x: 4,
      y: 107348.384782862,
    },
    {
      x: 5,
      y: 284433.294944147,
    },
    {
      x: 6,
      y: 637333.264833426,
    },
    {
      x: 7,
      y: 1157709.84938944,
    },
    {
      x: 8,
      y: 2303966.94909325,
    },
    {
      x: 9,
      y: 2781718.75455082,
    },
    {
      x: 10,
      y: 1525537.33833898,
    },
    {
      x: 11,
      y: 276258.473621291,
    },
  ],
];

const colors = [
  '#225ea8',
  '#41b6c4',
  '#a1dab4',
  '#ffffcc',
  '#ffeda0',
  '#f3d976',
  '#feb24c',
  '#fd8d3c',
  '#fc4e2a',
  '#e31a1c',
  '#b10026',
];

export { data, colors };
