import WFHazAPI from '.';

export default class WFHazBiophysicalSettings extends WFHazAPI {
  constructor() {
    super();
    this.endpoint = 'biophysical_settings';
  }

  async get(params = {}) {
    if (!params.id) throw Error('Missing required param: id');

    return super.get(
      `${this.endpoint}/${params.id}`,
    );
  }
}
