import GeoserverAPI from './GeoserverAPI';
import S3API from './S3API';
// import WFHazAPI from './WFHazAPI';
import WFHazUsers from './WFHazAPI/users';
import WFHazPins from './WFHazAPI/pins';
import WFHazAuth from './WFHazAPI/auth';
import WFHazComments from './WFHazAPI/comments';
import WFHazEvts from './WFHazAPI/evts';
import WFHazFbSums from './WFHazAPI/fb_sums';
import WFHazAlerts from './WFHazAPI/alerts';
import WFHazBiophysicalSettings from './WFHazAPI/biophysical_settings';

const Geoserver = new GeoserverAPI();
const S3 = new S3API();
// const What = new WFHazAPI();
const Users = new WFHazUsers();
const Pins = new WFHazPins();
const Auth = new WFHazAuth();
const Comments = new WFHazComments();
const Evts = new WFHazEvts();
const FbSums = new WFHazFbSums();
const Alerts = new WFHazAlerts();
const BiophyiscalSettings = new WFHazBiophysicalSettings();

export default {
  Geoserver,
  S3,
  Users,
  Pins,
  Auth,
  Comments,
  Evts,
  FbSums,
  Alerts,
  BiophyiscalSettings,
};
