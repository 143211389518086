import { UnknownString } from '../constants';
import fuelVegLut from './twoDigitFvLut';

const lifeforms = [
  'Tree',
  'Shrub',
  'Herb',
];

const canopyRhsMap = [
  '25',
  '60',
  '100',
];

const canopyLhsMap = [
  '10',
  '25',
  '60',
];

export const decodeFvcCode = (lowCode, highCode) => {
  if (lowCode === false || lowCode === undefined) return UnknownString;
  if (fuelVegLut[lowCode]) {
    return fuelVegLut[lowCode];
  }

  // Handle Sparse code
  if (lowCode === 150 || lowCode === 100) {
    return 'Sparse Vegetation Canopy';
  }

  // Handle Cover codes
  for (let i = 0; i < 3; i += 1) {
    const series = 100 + 10 * i;
    // console.log('series', series);
    if (
      (lowCode >= ((series) + 1) && (lowCode <= ((series + 9))))
        && (highCode >= ((series) + 1) && (highCode <= ((series + 9))))
    ) {
      const lowOneDigit = lowCode % 10;
      const highOneDigit = (highCode % 10) + 1;
      if (lowOneDigit >= highOneDigit) {
        return `${lifeforms[i]} Cover >= ${lowOneDigit * 10}%`;
      }
      return `${lifeforms[i]} Cover ${lowOneDigit * 10} - ${highOneDigit * 10}%`;
    }
  }

  // Handle Canopy codes
  if (lowCode >= 151 && lowCode <= 153 && highCode >= 151 && highCode <= 153) {
    const lowIdx = (lowCode - 150) - 1;
    const highIdx = (highCode - 150) - 1;

    if (lowIdx >= highIdx) {
      return `${lifeforms[0]} Canopy >= ${canopyLhsMap[lowIdx]}%`;
    }

    return `${lifeforms[0]} Canopy ${canopyLhsMap[lowIdx]} - ${canopyRhsMap[highIdx]}%`;
  }

  if (lowCode >= 161 && lowCode <= 163 && highCode >= 161 && highCode <= 163) {
    const lowIdx = (lowCode - 160) - 1;
    const highIdx = (highCode - 160) - 1;

    if (lowIdx >= highIdx) {
      return `${lifeforms[1]} Canopy >= ${canopyLhsMap[lowIdx]}%`;
    }

    return `${lifeforms[1]} Canopy ${canopyLhsMap[lowIdx]} - ${canopyRhsMap[highIdx]}%`;
  }

  if (lowCode === 171) {
    if (highCode === 171) {
      return `${lifeforms[2]} Canopy 10 - 60%`;
    }
    if (highCode === 172) {
      return `${lifeforms[2]} Canopy 10 - 100%`;
    }
  }

  if (lowCode === 172) {
    return `${lifeforms[2]} Canopy 60 - 100%`;
  }

  return 'Unknown';
};

export default {
  decodeFvcCode,
};

// Test two digit codes
// for (let i = 0; i <= 100; i += 1) {
//   console.log(i, ',', decodeFvcCode(i, -666));
// }

// Test Cover codes
// for (let i = 101; i <= 129; i += 1) {
//   for (let j = 101; j <= 129; j += 1) {
//     console.log(i, j, decodeFvcCode(i, j));
//   }
// }

// Test Cover codes
// for (let i = 151; i <= 153; i += 1) {
//   for (let j = 151; j <= 153; j += 1) {
//     console.log(i, j, decodeFvcCode(i, j));
//   }
// }

// for (let i = 161; i <= 163; i += 1) {
//   for (let j = 161; j <= 163; j += 1) {
//     console.log(i, j, decodeFvcCode(i, j));
//   }
// }

// for (let i = 171; i <= 172; i += 1) {
//   for (let j = 171; j <= 172; j += 1) {
//     console.log(i, j, decodeFvcCode(i, j));
//   }
// }
